import defaultUser from "../../../assets/images/global/default_user.png";
import {Link} from "react-router-dom";
import {IoIosArrowForward} from "react-icons/io";
import {useEffect, useState} from "react";
import {userAccountService, userSettingService} from "../../../_services";

const UserNavSection = () => {
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({
        full_name: '',
        email: '',
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const data = await userAccountService.getUserData();
                setUserDetails({
                    full_name: data.full_name,
                    email: data.email,
                });
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, []);

    return (
        <div className="user-nav-holder">
            {loading ? (
                <div className="d-flex align-items-center p-3">
                    <div className="profile-img-holder rounded-circle main-loading-effect"
                         style={{width: "60px", height: "60px"}}>
                    </div>
                    <div className="user-infos-holder ms-3">
                        <div className="main-loading-effect mb-2 rounded-2" style={{height: "18px", width: "140px"}}></div>
                        <div className="main-loading-effect rounded-2" style={{height: "15px", width: "170px"}}></div>
                    </div>
                </div>
            ) : (
                <div className="d-flex align-items-center p-3">
                    <div className="profile-img-holder rounded-circle"
                         style={{width: "60px", height: "60px"}}>
                    <img src={defaultUser} className="w-100 h-100 rounded-circle" alt=""/>
                    </div>
                    <div className="user-infos-holder ms-3">
                        <div className="user-name-holder fw-bold-600 one-line-limit black-text-color">
                            {userDetails.full_name}
                        </div>
                        <div className="user-phone-holder text-real-small gray-text-color one-line-limit">
                            {userDetails.email}
                        </div>
                    </div>
                    <div className="ms-auto">
                        <Link to="/my-profile"
                              className="bg-transparent fw-bold-600 border-0 p-0 me-3 main-text-dark-color">
                            <IoIosArrowForward size="22"/>
                        </Link>
                    </div>
                </div>
            )}
            <div className="horizontal-line mx-auto"/>
        </div>
    );
};

export default UserNavSection;