import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {publicProductService} from "../../_services";
import {Footer, Header, GetReadyToPay} from "./Public";
import {Link, useParams} from "react-router-dom";
import defaultImage from "../../assets/images/global/default_image.jpg";
import PaymentAcceptances from "../../components/global/PaymentAcceptances";
import {CgClose} from "react-icons/cg";
import bookshelfBg from "../../assets/images/global/bookshelf_bg.jpg";

const Book = () => {
    useEffect(() => {
        document.title = "Holylane - E-book";
    }, []);

    let {bid} = useParams();
    const {t, i18n} = useTranslation();
    const [productData, setProductData] = useState('');
    const [loading, setLoading] = useState(true);
    const [readyToPay, setReadyToPay] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true);
                const data = await publicProductService.getProductById(bid);
                setProductData(data);
            } catch (error) {
                console.error("Error fetching product data:", error);
            } finally {
                setLoading(false);
            }
        };
        localStorage.removeItem('HOLYLANE_ORDER_ID');
        fetchProducts();
    }, []);

    const getColumnValue = (product, column) => {
        return i18n.language.startsWith('fr') ? product[`${column}_fr`] : product[`${column}_en`];
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="main-book-page home-container">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                {loading ? (
                    <div className="book-content-holder bottom-padding p-3">
                        <div className="main-loading-effect mb-3 rounded-2" style={{height: "175px"}}/>
                        <div className="main-loading-effect rounded-2 mb-3" style={{height: "18px", width: "150px"}}/>
                        <div className="d-flex w-75 align-items-center justify-content-between mb-3">
                            <div className="main-loading-effect rounded-2" style={{height: "18px", width: "75px"}}/>
                            <div className="main-loading-effect rounded-2" style={{height: "18px", width: "75px"}}/>
                        </div>
                        <div className="main-loading-effect mb-1 rounded-2" style={{height: "15px"}}/>
                        <div className="main-loading-effect mb-1 rounded-2" style={{height: "15px"}}/>
                        <div className="main-loading-effect mb-1 rounded-2" style={{height: "15px"}}/>
                        <div className="main-loading-effect mb-1 rounded-2" style={{height: "15px"}}/>
                        <div className="main-loading-effect mb-5 rounded-2" style={{height: "15px"}}/>
                        <div className="main-loading-effect rounded-3 mx-auto w-75" style={{height: "50px"}}/>
                    </div>
                ) : (
                    productData && (
                        readyToPay ? (
                            <GetReadyToPay
                                product_id={bid}
                                product_name={productData.title_en}
                                price={productData.price}
                                type="product"
                            />
                        ) : (
                            <div className="book-content-holder bottom-padding text-center">
                                <div className="background-image-center pt-2 pe-2 rounded-top-4 text-end"
                                     style={{backgroundImage: `url(${bookshelfBg})`, paddingBottom: "200px"}}>
                                    <Link to="/bookshelf">
                                        <CgClose size="25" className="text-white-50"/>
                                    </Link>
                                </div>
                                <div className="image-holder" style={{marginTop: "-180px"}}>
                                    {productData.image ? (
                                        <img
                                            src={`${baseURL}thedss/images/products/${productData.image}`}
                                            className="rounded-2 mx-auto h-100"
                                            width="250"
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={defaultImage}
                                            className="rounded-2 mx-auto h-100"
                                            width="250"
                                            alt=""
                                        />
                                    )}
                                </div>
                                <div className="p-3">
                                    <div className="product-title mt-2 text-black-50">
                                        {getColumnValue(productData, 'name')}
                                    </div>
                                    <div className="product-title fw-bold-600 mt-1 fs-5">
                                        {getColumnValue(productData, 'title')}
                                    </div>
                                    <hr className="w-75 mx-auto border-light-subtle"/>
                                    <div className="fw-bold text-start px-3 mb-3">
                                        About Book
                                    </div>
                                    <div
                                        className="info-holder d-flex bg-light mx-4 p-3 justify-content-between rounded-3">
                                        <div className="text-center">
                                            <div
                                                className="fw-bold-600 black-text-color">${parseFloat(productData.price).toFixed(2)}</div>
                                            <div className="gray-text-color small">Price</div>
                                        </div>
                                        <div className="text-center">
                                            <div
                                                className="fw-bold-600 black-text-color">{productData.pages_number}</div>
                                            <div className="gray-text-color small">Pages</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="fw-bold-600 black-text-color">PDF</div>
                                            <div className="gray-text-color small">Book</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="fw-bold-600 black-text-color">{productData.language}</div>
                                            <div className="gray-text-color small">Language</div>
                                        </div>
                                    </div>
                                    <div className="fw-bold text-start px-3 mb-3 mt-4">
                                        Description
                                    </div>
                                    <div className="text-black-50 small lh-lg px-3">
                                        {getColumnValue(productData, 'description')}
                                    </div>
                                    <div className="mt-4 pb-2 text-center">
                                        <button onClick={() => setReadyToPay(true)}
                                                className="main-button-color py-3 w-75 border-0 fw-bold-500 small rounded-3 mx-auto">
                                            {t('buyNow')}
                                        </button>
                                    </div>
                                    <PaymentAcceptances/>
                                </div>
                            </div>
                        )
                    )
                )}
                <Footer/>
            </div>
        </div>
    );
};

export default Book;
