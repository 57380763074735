import {useEffect} from "react";
import mainLogo from "../../../assets/images/global/primeband_logo.png";
import {Link, useNavigate} from "react-router-dom";
import {GoSignOut} from "react-icons/go";
import {userAccountService} from "../../../_services";

const Header = (props) => {
    let navigate = useNavigate();
    useEffect(() => {
        document.title = `Primeband - ${props.title}`;
    }, [props.title]);

    const logout = () => {
        userAccountService.logout();
        navigate("/auth/login")
    }

    return (
        <div className="the-main-header px-3 bg-white sticky-top d-flex align-items-center justify-content-between">
            <div className="d-none d-lg-block page-title fw-bold main-dark-color">{props.title}</div>
            <div className="d-lg-none header-logo-holder">
                <Link to="/admin/messages"
                      className="logo-holder d-flex align-items-center justify-content-center h-100">
                    <img src={mainLogo} alt="Primeband" className="logo-img"/>
                </Link>
            </div>
            <div className="d-lg-none">
                <button type="button" onClick={logout}
                        className="d-flex logout-button align-items-center border-0 bg-transparent">
                    <div className="icon d-flex align-items-center justify-content-center">
                        <GoSignOut size="25"/>
                    </div>
                    <div>Logout</div>
                </button>
            </div>
        </div>
    );
}
export default Header;