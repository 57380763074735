import React, {useEffect, useState} from "react";
import {adminAdService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";

const AdsForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        image: "",
        title: "",
        link: "",
        type: "Landscape",
    });

    const [image, setImage] = useState(null);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title: props.ad.title,
                link: props.ad.link,
                type: props.ad.type,
                image: props.ad.image
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                image: "",
                title: "",
                link: "",
                type: "Landscape",
            });
        }
    }, [props.editMode, props.ad]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("title", params.title);
            formData.append("link", params.link);
            formData.append("type", params.type);
            formData.append("image", image);

            if (isCreatingMode) {
                await adminAdService.createAd(formData);
            } else {
                formData.append("id", props.ad.ad_id);
                await adminAdService.updateAd(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                image: "",
                title: "",
                link: "",
                type: "Landscape",
            });
            setImage(null);
        } catch (error) {
            console.error("Error Creating/Updating Ad:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            image: "",
            title: "",
            link: "",
            type: "Landscape",
        });
        props.sendHiddenToParent(!props.hidden);
        setImage(null);
    }

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="main-form-holder add-ad-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Add / Update a Ad</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-group text-center">
                                <label className="mb-1 text-black-50 small" htmlFor="selectedImage">
                                    Selected Image:
                                    <span className="text-black-50 text-real-small"> ({params.type} Images)</span>
                                </label>
                                <div className="selected-image-holder border rounded-2 rounded-bottom-0">
                                    <div className="selected-image mx-auto rounded-2 rounded-bottom-0">
                                        {image ? (
                                            <img
                                                height="200"
                                                
                                                src={URL.createObjectURL(image)}
                                                alt="Selected Image For Ad"
                                                className="w-100 rounded-2 rounded-bottom-0"
                                            />
                                        ) : params.image ? (
                                            <img
                                                height="200"
                                                
                                                src={`${baseURL}thedss/images/ads/${params.image}`}
                                                alt="Current Image For Ad"
                                                className="w-100 rounded-2 rounded-bottom-0"
                                            />
                                        ) : (
                                            <img
                                                height="200"
                                                
                                                src={defaultImage}
                                                alt="Default Image For Ad"
                                                className="w-100 rounded-2 rounded-bottom-0"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="upload-btn-holder">
                                    <label className="mb-1 w-100" htmlFor="image">
                                        <div
                                            className="link-as-button px-3 py-2 rounded-top-0 rounded-2 mb-1 w-100 small">Upload
                                            Image
                                        </div>
                                    </label>
                                    <div className="image-holder d-flex align-items-center">
                                        <div className="image-input flex-grow-1">
                                            <input
                                                type="file"
                                                className="form-control py-2 d-none"
                                                onChange={onImageChange}
                                                name="image"
                                                id="image"
                                                accept="image/*"
                                                required={isCreatingMode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="title">
                                    Title:
                                </label>
                                <div className="ad-en-holder d-flex align-items-center">
                                    <div className="ad-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Ad Title"
                                            value={params.title}
                                            onChange={onChange}
                                            name="title"
                                            id="title"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="link">
                                    Link:
                                </label>
                                <div className="ad-en-holder d-flex align-items-center">
                                    <div className="ad-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Ad Link"
                                            value={params.link}
                                            onChange={onChange}
                                            name="link"
                                            id="link"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="type">
                                    Ad Type:
                                </label>
                                <div className="is-premium-holder d-flex align-items-center">
                                    <div className="is-premium-input flex-grow-1">
                                        <select
                                            className="form-select form-select-sm py-2"
                                            value={params.type}
                                            onChange={onChange}
                                            name="type"
                                            id="type"
                                            required
                                        >
                                            <option value="Landscape">Landscape</option>
                                            <option value="Square">Square</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AdsForm;