import Axios from "../ServiceCaller";

let getPricingPlans = async () => {
    const {data} = await Axios.get('/public-pricing-plans');
    return data.PricingPlans;
};

let getPricingPlanById = async (id) => {
    const {data} = await Axios.get(`/public-pricing-plans/${id}`);
    return data.PricingPlan;
};

let getPricingPlansFeatures = async () => {
    const {data} = await Axios.get("/public-pricing-plans/features");
    return data.PricingPlansFeatures;
};

let getFeaturedPricingPlanData = async () => {
    const {data} = await Axios.get("/public-pricing-plans/featured-pricing-plan");
    return data.PricingPlanData;
};

export const publicPricingPlanService = {
    getPricingPlans,
    getPricingPlanById,
    getPricingPlansFeatures,
    getFeaturedPricingPlanData,
};