import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {userAccountService, userQuizService} from "../../../_services";
import congratsBg from "../../../assets/images/global/congrats_bg.png";
import defaultUserImg from "../../../assets/images/global/default_user.png";

const FinishedQuizByUser = () => {
    const {t, i18n} = useTranslation();
    const [userData, setUserData] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const data = await userQuizService.getUserRankData();
                setUserData(data);
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, []);

    function getRankSuffix(rank) {
        const remainder = rank % 10;
        const tens = Math.floor((rank % 100) / 10);

        if (tens === 1) return `${rank}th`;

        switch (remainder) {
            case 1:
                return `${rank}st`;
            case 2:
                return `${rank}nd`;
            case 3:
                return `${rank}rd`;
            default:
                return `${rank}th`;
        }
    }

    return (
        <>
            {loading ? (
                <div></div>
            ) : (
                <div className="background-image-center rounded-3 light-shadow px-3 py-5"
                     style={{
                         backgroundImage: `url(${congratsBg})`
                     }}>
                    <div className="img-holder text-center">
                        <img src={defaultUserImg} width="80" height="80"
                             alt=""/>
                    </div>
                    <div className="text-center mt-2 fw-bold main-text-dark-color fs-5">
                        {userData.full_name}
                    </div>
                    <div
                        className="d-flex justify-content-between mt-3 text-center black-text-color px-2">
                        <div>
                            {userData.total_points > 0 ? (
                                <>
                                    <span className="fw-bold-600">{getRankSuffix(userData.rank)}</span> Rank
                                </>
                            ) : (
                                'Not ranked'
                            )}
                        </div>
                        <div>
                            <span className="fw-bold-600">{userData.total_points}</span> Points
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FinishedQuizByUser;