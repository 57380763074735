import {Footer, GetReadyToPay, Header, PricingPlansFeatures, PricingPlansItems} from "./Public";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const PricingPlans = () => {
    const {t, i18n} = useTranslation();
    const [readyToPay, setReadyToPay] = useState(false);
    const [pricingPlan, setPricingPlan] = useState(0);
    const [pricingPlanTitle, setPricingPlanTitle] = useState("");
    const [pricingPlanPrice, setPricingPlanPrice] = useState("");

    useEffect(() => {
        localStorage.removeItem('HOLYLANE_SUBSCRIPTION_ID');
        localStorage.removeItem('HOLYLANE_REDIRECTION_PATH');
    }, []);

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="main-pricing-plans-page home-container">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                {readyToPay ? (
                    <GetReadyToPay
                        product_id={pricingPlan}
                        product_name={pricingPlanTitle}
                        price={pricingPlanPrice}
                        type="subscription"
                    />
                ) : (
                    <div className="pricing-title-holder px-3 py-3">
                        <div className="title fw-bold-600 main-text-dark-color fs-2 mb-3">
                            Subscription
                        </div>
                        <PricingPlansFeatures/>
                        <PricingPlansItems
                            setPricingPlan={setPricingPlan}
                            setPricingPlanTitle={setPricingPlanTitle}
                            setPricingPlanPrice={setPricingPlanPrice}
                        />
                        <div className="pricing-pay-holder bottom-padding mb-3">
                            <div className="pay-btn-holder text-center">
                                <button disabled={!pricingPlan} onClick={() => setReadyToPay(true)}
                                        className="border-0 text-white fw-bold-600 px-5 py-3 rounded-2 main-hover-background-color">
                                    Get Started
                                </button>
                            </div>
                            <div className="payment-notice small mt-3 text-center gray-text-color">
                                You will charged a total of <span className="fw-bold-500">${parseFloat(pricingPlanPrice).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                )}
                <Footer/>
            </div>
        </div>
    );
};

export default PricingPlans;