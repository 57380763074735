import {
    Header,
    AdSettings,
    AdsFiltered,
} from "./Private";
import {useState} from "react";

const Ads = () => {
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <Header title="Ads"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="main-ads-page bg-white mb-3 p-2 p-lg-3 rounded-3 row m-0">
                    <div className="ad-setting mb-3">
                        <AdSettings/>
                    </div>
                    <div className="mb-3 rounded-3 row m-0">
                        <div className="col-6 col-xl-4 col-xxl-3">
                            <div className="form-group">
                                <label className="mb-1 text-real-small fw-bold-500" htmlFor="search">
                                    Search
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-select-sm text-real-small"
                                    value={searchQuery}
                                    id="search"
                                    onChange={handleSearchInputChange}
                                    placeholder="title, types..."
                                />
                            </div>
                        </div>
                    </div>
                    <AdsFiltered
                        searchQuery={searchQuery}
                    />
                </div>
                {/*End Page Content*/}
            </div>
        </>
    );
};

export default Ads;