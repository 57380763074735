import React from "react";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import PaypalPaymentButton from "./PaypalPaymentButton";
import CreditCardForm from "./CreditCardForm";
import StripePayment from "./StripePayment";
import {useTranslation} from "react-i18next";
import PaymentAcceptances from "../global/PaymentAcceptances";

const PaymentMethods = ({client_id, product_id, product_name, price, full_name, email, type}) => {
    const {t, i18n} = useTranslation();
    const initialOptions = {
        clientId: `${client_id}`,
        currency: "USD",
        intent: "capture"
    };

    return (
        <div className="mt-4 px-3">
            <div className="text-center mt-4 fw-bold fs-5">
                {t('paymentMethods')}
            </div>
            <div className="mt-4 payment-methods-custom">
                <StripePayment
                    product_id={product_id}
                    product_name={product_name}
                    price={price}
                    full_name={full_name}
                    email={email}
                    type={type}
                />
                <CreditCardForm
                    product_id={product_id}
                    product_name={product_name}
                    price={price}
                    full_name={full_name}
                    email={email}
                    type={type}
                />
                <PayPalScriptProvider options={initialOptions}>
                    <PaypalPaymentButton
                        product_id={product_id}
                        product_name={product_name}
                        price={price}
                        full_name={full_name}
                        email={email}
                        type={type}
                    />
                </PayPalScriptProvider>
            </div>
            <PaymentAcceptances/>
        </div>
    );
};

export default PaymentMethods;