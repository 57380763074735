import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {publicQuizService, userAccountService, userQuizService} from "../../../_services";

const QuizQuestionsByQuiz = ({quiz, setScoreString, setQuizFinished}) => {
    const {t, i18n} = useTranslation();
    const [quizQuestionsData, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [answerFeedback, setAnswerFeedback] = useState(null);
    const [shuffledAnswers, setShuffledAnswers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicQuizService.getQuizQuestions(quiz);
                setData(data);
            } catch (error) {
                console.error("Error fetching quiz questions data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [quiz]);

    const getColumnValue = (product, column) => {
        return i18n.language.startsWith('fr') ? product[`${column}_fr`] : product[`${column}_en`];
    };

    const shuffleAnswers = () => {
        const currentQuiz = quizQuestionsData[currentQuestion];
        const answers = [
            {text: getColumnValue(currentQuiz, 'correct_answer'), isCorrect: true},
            {text: getColumnValue(currentQuiz, 'wrong_answer_1'), isCorrect: false},
            {text: getColumnValue(currentQuiz, 'wrong_answer_2'), isCorrect: false},
            {text: getColumnValue(currentQuiz, 'wrong_answer_3'), isCorrect: false}
        ];
        for (let i = answers.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [answers[i], answers[j]] = [answers[j], answers[i]];
        }
        setShuffledAnswers(answers);
    };

    useEffect(() => {
        if (quizQuestionsData.length > 0) {
            shuffleAnswers();
        }
    }, [currentQuestion, quizQuestionsData]);

    const handleAnswerSelect = (answer) => {
        setSelectedAnswer(answer.text);

        if (answer.isCorrect) {
            setScore(score + 1);
            setAnswerFeedback({correct: true});
        } else {
            const correctAnswer = shuffledAnswers.find(ans => ans.isCorrect).text;
            setAnswerFeedback({correct: false, correctAnswer});
        }

        if (currentQuestion === quizQuestionsData.length - 1) {
            finishTheQuizForUser(answer);
        }
    };

    const nextQuestion = () => {
        setSelectedAnswer(null);
        setAnswerFeedback(null);
        if (currentQuestion < quizQuestionsData.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        }
    };

    const finishTheQuizForUser = (answer) => {
        let finalScore = score + (answer.isCorrect ? 1 : 0)
        handleAnswer(finalScore)
        setQuizFinished();
        userQuizService.completeUserQuiz(finalScore).then((res) => {
            if (res.data.status === 200) {

            }
        })
            .catch((error) => console.log(error));
    }

    const handleAnswer = (finalScore) => {
        let scoreString = `${finalScore} / ${quizQuestionsData.length}`
        setScoreString(scoreString);
        // setScore(prevState => ({
        //     ...prevState,
        //     scoreString
        // }));
    };

    if (loading) {
        return <div className="loading"></div>;
    }

    const currentQuiz = quizQuestionsData[currentQuestion];

    return (
        <div className="main-quiz-container mt-2">
            <div className="small fw-bold-600 mb-2 ps-1 main-text-dark-color">
                {`${currentQuestion + 1} / ${quizQuestionsData.length}`}
            </div>
            <div className="progress mb-1" style={{height: "14px"}}>
                <div
                    className="progress-bar main-hover-background-color"
                    style={{width: `${((currentQuestion + 1) / quizQuestionsData.length) * 100}%`, height: "14px"}}
                >
                </div>
            </div>
            <div className="quiz-question mt-4 mb-3">
                <div className="question-text fw-bold-600 main-text-dark-color text-center mb-3">
                    {getColumnValue(currentQuiz, 'question_text')}
                </div>
                <div className="answers border border-light-subtle rounded-4 py-3 px-2">
                    {shuffledAnswers.map((answer, index) => {
                        let answerClass = "";
                        let circleClass = "circle";
                        if (selectedAnswer) {
                            if (answer.text === selectedAnswer) {
                                answerClass = answerFeedback.correct ? "text-correct-answer" : "text-wrong-answer";
                                circleClass = answerFeedback.correct ? "circle correct-answer" : "circle wrong-answer";
                            } else if (answer.isCorrect) {
                                circleClass = "circle correct-answer";
                            } else {
                                circleClass = "circle wrong-answer";
                            }
                        }

                        return (
                            <button
                                key={index}
                                className={`answer-option d-flex align-items-center ${answerClass} mx-1`}
                                disabled={!!selectedAnswer}
                                onClick={() => handleAnswerSelect(answer)}
                            >
                                <span className={`${circleClass} me-2`}><span className="inner-answer"></span></span>
                                <span className="d-inline-block fw-bold-500">
                                            {answer.text}
                                        </span>
                            </button>
                        );
                    })}
                </div>

                {selectedAnswer && (
                    <div className="feedback mt-3 text-center fw-bold-500">
                        {answerFeedback.correct ? (
                            <span className="text-correct-answer">Correct!</span>
                        ) : (
                            <span className="text-wrong-answer">
                                        Incorrect. The correct answer is:<br/>{answerFeedback.correctAnswer}
                                    </span>
                        )}
                    </div>
                )}

                {selectedAnswer && currentQuestion < quizQuestionsData.length - 1 && (
                    <div className="text-end pe-3 mt-3">
                        <button className="border-0 py-2 px-4 small rounded-3 main-hover-background-color text-white"
                                onClick={nextQuestion}>
                            Next Question
                        </button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default QuizQuestionsByQuiz;