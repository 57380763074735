import React, {useEffect, useState} from "react";
import {adminQuizService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";

const QuizCategoriesForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        image: "",
        name_en: "",
        name_fr: "",
        color: "#000000",
    });

    const [image, setImage] = useState(null);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                image: props.quiz_categories.image,
                name_en: props.quiz_categories.name_en,
                name_fr: props.quiz_categories.name_fr,
                color: props.quiz_categories.color,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                image: "",
                name_en: "",
                name_fr: "",
                color: "#000000",
            });
        }
    }, [props.editMode, props.quiz_categories]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("name_en", params.name_en);
            formData.append("name_fr", params.name_fr);
            formData.append("color", params.color);
            formData.append("image", image);

            if (isCreatingMode) {
                await adminQuizService.createQuizCategory(formData);
            } else {
                formData.append("id", props.quiz_categories.quiz_category_id);
                await adminQuizService.updateQuizCategory(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                image: "",
                name_en: "",
                name_fr: "",
                color: "#000000",
            });
            setImage(null);
        } catch (error) {
            console.error("Error Creating/Updating Quiz Category:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            image: "",
            name_en: "",
            name_fr: "",
            color: "#000000",
        });
        props.sendHiddenToParent(!props.hidden);
        setImage(null);
    }

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="main-form-holder add-quiz_categories-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Add / Update a quiz category</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row m-0">
                        <div className="mb-3">
                            <div className="form-group text-center">
                                <label className="mb-1 text-black-50 small" htmlFor="selectedImage">
                                    Selected Image:
                                    <span className="text-black-50 text-real-small"> ({params.type} Images)</span>
                                </label>
                                <div className="selected-image-holder border mx-auto rounded-2 rounded-bottom-0" style={{width: "fit-content"}}>
                                    <div className="selected-image mx-auto rounded-2 rounded-bottom-0" style={{width: "fit-content"}}>
                                        {image ? (
                                            <img
                                                height="200"
                                                width="200"
                                                
                                                src={URL.createObjectURL(image)}
                                                alt="Selected Image For Quiz Category"
                                                className="rounded-2 rounded-bottom-0"
                                            />
                                        ) : params.image ? (
                                            <img
                                                height="200"
                                                width="200"
                                                
                                                src={`${baseURL}thedss/images/quiz-categories/${params.image}`}
                                                alt="Current Image For Quiz Category"
                                                className="rounded-2 rounded-bottom-0"
                                            />
                                        ) : (
                                            <img
                                                height="200"
                                                width="200"
                                                
                                                src={defaultImage}
                                                alt="Default Image For Quiz Category"
                                                className="rounded-2 rounded-bottom-0"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="upload-btn-holder">
                                    <label className="mb-1" htmlFor="image">
                                        <div style={{width: "200px"}}
                                            className="link-as-button px-3 py-2 rounded-top-0 rounded-2 mb-1 small">Upload
                                            Image
                                        </div>
                                    </label>
                                    <div className="image-holder d-flex align-items-center">
                                        <div className="image-input flex-grow-1">
                                            <input
                                                type="file"
                                                className="form-control py-2 d-none"
                                                onChange={onImageChange}
                                                name="image"
                                                id="image"
                                                accept="image/*"
                                                required={isCreatingMode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-5 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="name_en">
                                    Name (English):
                                </label>
                                <div className="name-en-holder d-flex align-items-center">
                                    <div className="name-en-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            type="text"
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Name of the Category (English)"
                                            value={params.name_en}
                                            onChange={onChange}
                                            name="name_en"
                                            id="name_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-5 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="name_fr">
                                    Name (French):
                                </label>
                                <div className="name-fr-holder d-flex align-items-center">
                                    <div className="name-fr-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            type="text"
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Name of the Category (French)"
                                            value={params.name_fr}
                                            onChange={onChange}
                                            name="name_fr"
                                            id="name_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-2">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="color">
                                    Color:
                                </label>
                                <div className="color-holder d-flex align-items-center">
                                    <div className="color-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            type="color"
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Name of the Category (English)"
                                            value={params.color}
                                            onChange={onChange}
                                            name="color"
                                            id="color"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default QuizCategoriesForm;