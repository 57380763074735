import React, {useEffect, useState} from "react";
import {adminProductService} from "../../../_services";

const ProductCategoriesForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        name_en: "",
        name_fr: "",
    });


    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                name_en: props.productCategories.name_en,
                name_fr: props.productCategories.name_fr,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                name_en: "",
                name_fr: "",
            });
        }
    }, [props.editMode, props.productCategories]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("name_en", params.name_en);
            formData.append("name_fr", params.name_fr);

            if (isCreatingMode) {
                await adminProductService.createProductCategory(formData);
            } else {
                formData.append("id", props.productCategories.productCategory_id);
                await adminProductService.updateProductCategory(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                name_en: "",
                name_fr: "",
            });
        } catch (error) {
            console.error("Error Creating/Updating Product Category:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            name_en: "",
            name_fr: "",
        });
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-productCategories-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Add / Update a Product Category</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row m-0">
                        <div className="col-sm-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="name_en">
                                    Name (English):
                                </label>
                                <div className="name-en-holder d-flex align-items-center">
                                    <div className="name-en-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            type="text"
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Name of the Category (English)"
                                            value={params.name_en}
                                            onChange={onChange}
                                            name="name_en"
                                            id="name_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="name_fr">
                                    Name (French):
                                </label>
                                <div className="name-fr-holder d-flex align-items-center">
                                    <div className="name-fr-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            type="text"
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Name of the Category (French)"
                                            value={params.name_fr}
                                            onChange={onChange}
                                            name="name_fr"
                                            id="name_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProductCategoriesForm;