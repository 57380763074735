import Axios from "../ServiceCaller";

let getOrders = async () => {
    const { data } = await Axios.get("/admin-orders");
    return data.Orders;
};

let getOrderById = async (id) => {
    const { data } = await Axios.get(`/admin-orders/${id}`);
    return data.Order;
};

export const adminOrderService = {
    getOrders,
    getOrderById,
};