import React, {useState} from "react";
import {BiKey, BiMailSend, BiUser} from "react-icons/bi";
import {userAccountService} from "../../_services";
import {useTranslation} from "react-i18next";

const CreateAccount = ({userDetails, setUserDetails, setRefresh}) => {
    const {t, i18n} = useTranslation();
    const [step, setStep] = useState(1);
    const [isNewUser, setIsNewUser] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");

    const onChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (step === 1) {
            // Initial email submission to check if user exists
            userAccountService.checkOrSignup({ email: userDetails.email })
                .then((res) => {
                    if (res.data.status === 200) {
                        setIsNewUser(res.data.isNewUser);
                        setStep(2); // Move to verification step
                    }
                })
                .catch((error) => console.log(error));
        } else if (step === 2) {
            // Verify code
            userAccountService.verifyCode({
                email: userDetails.email,
                code: verificationCode,
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        if (isNewUser) {
                            setStep(3); // New user, prompt for full name
                        } else {
                            // Existing user, login complete
                            userAccountService.saveToken(res.data.jwt);
                            setRefresh((prev) => !prev);
                        }
                    }
                })
                .catch((error) => console.log(error));
        } else if (step === 3 && isNewUser) {
            // Complete signup for new user by submitting full name
            userAccountService.completeSignup({
                email: userDetails.email,
                full_name: userDetails.full_name,
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        userAccountService.saveToken(res.data.jwt);
                        setRefresh((prev) => !prev);
                    }
                })
                .catch((error) => console.log(error));
        }
    };

    return (
        <div className="mt-4 px-2 authentication-page">
            <div className="text-center my-4 fw-bold fs-5">
                Enter your email to proceed
            </div>
            <div className="user-info-step text-start authentication-content">
                <form onSubmit={onSubmit} encType="multipart/form-data">
                    {step === 1 && (
                        <div className="form-group mb-4 position-relative">
                            <div className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                <BiMailSend size="25" color="#b7b7b7"/>
                            </div>
                            <input
                                type="email"
                                className="form-control"
                                placeholder={t("yourEmail")}
                                name="email"
                                id="email"
                                required
                                value={userDetails.email}
                                onChange={onChange}
                            />
                        </div>
                    )}
                    {step === 2 && (
                        <div className="form-group mb-4 position-relative">
                            <div className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                <BiKey size="25" color="#b7b7b7"/>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("enterVerificationCode")}
                                name="code"
                                id="code"
                                required
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                            />
                        </div>
                    )}
                    {step === 3 && isNewUser && (
                        <div className="form-group mb-4 position-relative">
                            <div className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                <BiUser size="25" color="#b7b7b7"/>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("yourFullName")}
                                name="full_name"
                                id="full_name"
                                required
                                value={userDetails.full_name}
                                onChange={onChange}
                            />
                        </div>
                    )}
                    <div className="text-center">
                        <button
                            type="submit"
                            className="btn btn-block main-button-color py-3 w-100 fw-bold"
                        >
                            {step === 1
                                ? t("sendVerificationCode")
                                : step === 2
                                    ? t("verifyAndLogin")
                                    : t("completeSignup")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateAccount;