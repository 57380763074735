import React, {useEffect, useState} from "react";
import {adminProductService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";

const ProductsForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [categoriesData, setCategoriesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        image: "",
        title_en: "",
        title_fr: "",
        description_en: "",
        description_fr: "",
        category_id: "",
        is_special: 0,
        pages_number: 1,
        price: "",
        language: "English",
    });

    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminProductService.getProductCategories();
                setCategoriesData(data);
            } catch (error) {
                console.error("Error fetching categories data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title_en: props.product.title_en,
                title_fr: props.product.title_fr,
                description_en: props.product.description_en,
                description_fr: props.product.description_fr,
                is_special: props.product.is_special,
                pages_number: props.product.pages_number,
                category_id: props.product.category_id,
                price: props.product.price,
                language: props.product.language,
                image: props.product.image
            });
            setFile(null);
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                image: "",
                title_en: "",
                title_fr: "",
                description_en: "",
                description_fr: "",
                category_id: "",
                is_special: 0,
                pages_number: 1,
                price: "",
                language: "English",
            });
            setImage(null);
            setFile(null);
        }
    }, [props.editMode, props.product]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("title_en", params.title_en);
            formData.append("title_fr", params.title_fr);
            formData.append("description_en", params.description_en);
            formData.append("description_fr", params.description_fr);
            formData.append("is_special", params.is_special);
            formData.append("pages_number", params.pages_number);
            formData.append("category_id", params.category_id);
            formData.append("price", params.price);
            formData.append("language", params.language);
            formData.append("image", image);
            formData.append("pdf_file", file);

            if (isCreatingMode) {
                await adminProductService.createProduct(formData);
            } else {
                formData.append("id", props.product.product_id);
                await adminProductService.updateProduct(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                image: "",
                title_en: "",
                title_fr: "",
                description_en: "",
                description_fr: "",
                category_id: "",
                is_special: 0,
                pages_number: 1,
                price: "",
                language: "English",
            });
            setImage(null);
            setFile(null);
        } catch (error) {
            console.error("Error Creating/Updating Product:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            image: "",
            title_en: "",
            title_fr: "",
            description_en: "",
            description_fr: "",
            category_id: "",
            pages_number: 1,
            is_special: 0,
            price: "",
            language: "English",
        });
        props.sendHiddenToParent(!props.hidden);
        setImage(null);
        setFile(null);
    }

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="main-form-holder add-product-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Add / Update a Product</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-group text-center mb-2">
                                <label className="mb-1 text-black-50 small" htmlFor="selectedImage">
                                    Selected Image:
                                    <span className="text-black-50 text-real-small"> (Square Images)</span>
                                </label>
                                <div className="selected-image-holder mx-auto border rounded-2 rounded-bottom-0"
                                     style={{width: "fit-content"}}>
                                    <div className="selected-image mx-auto rounded-2 rounded-bottom-0"
                                         style={{width: "fit-content"}}>
                                        {image ? (
                                            <img
                                                height="200"
                                                width="200"
                                                
                                                src={URL.createObjectURL(image)}
                                                alt="Selected Image For Product"
                                                className="rounded-2 rounded-bottom-0"
                                            />
                                        ) : params.image ? (
                                            <img
                                                height="200"
                                                width="200"
                                                
                                                src={`${baseURL}thedss/images/products/${params.image}`}
                                                alt="Current Image For Product"
                                                className="rounded-2 rounded-bottom-0"
                                            />
                                        ) : (
                                            <img
                                                height="200"
                                                width="200"
                                                
                                                src={defaultImage}
                                                alt="Default Image For Product"
                                                className="rounded-2 rounded-bottom-0"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="upload-btn-holder">
                                    <label className="mb-1" htmlFor="image">
                                        <div style={{width: "200px"}}
                                             className="link-as-button px-3 py-2 rounded-top-0 rounded-2 mb-1 small">
                                            Upload Image
                                        </div>
                                    </label>
                                    <div className="image-holder d-flex align-items-center">
                                        <div className="image-input flex-grow-1">
                                            <input
                                                type="file"
                                                className="form-control py-2 d-none"
                                                onChange={onImageChange}
                                                name="image"
                                                id="image"
                                                accept="image/*"
                                                required={isCreatingMode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="pdf_file">
                                    Upload File:
                                </label>
                                <div className="file-holder d-flex align-items-center">
                                    <div className="file-input flex-grow-1">
                                        <input
                                            type="file"
                                            className="form-control form-control-sm"
                                            onChange={onFileChange}
                                            name="pdf_file"
                                            id="pdf_file"
                                            required={isCreatingMode}
                                            accept=".pdf"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="category_id">
                                    Category:
                                </label>
                                <div className="category-id-holder d-flex align-items-center">
                                    <div className="category-id-input flex-grow-1">
                                        <select
                                            className="form-select form-select-sm py-2"
                                            value={params.category_id}
                                            onChange={onChange}
                                            name="category_id"
                                            id="category_id"
                                            required
                                        >
                                            <option value="0">Please Select a Category</option>
                                            {categoriesData.length > 0 && (
                                                categoriesData.map((item, index) => (
                                                    <option value={item.id} key={index}>{item.name_en}</option>
                                                ))
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="is_special">
                                    Special Product:
                                </label>
                                <div className="is-special-holder d-flex align-items-center">
                                    <div className="is-special-input flex-grow-1">
                                        <select
                                            className="form-select form-select-sm py-2"
                                            value={params.is_special}
                                            onChange={onChange}
                                            name="is_special"
                                            id="is_special"
                                            required
                                        >
                                            <option value="0">Not Special Product</option>
                                            <option value="1">Special Product</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="pages_number">
                                    Pages Number
                                </label>
                                <div className="days-num-en-holder d-flex align-items-center">
                                    <div className="days-num-en-input flex-grow-1">
                                        <input
                                            type="number"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Number Of Days"
                                            value={params.pages_number}
                                            onChange={onChange}
                                            name="pages_number"
                                            id="pages_number"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="price">
                                    Price
                                </label>
                                <div className="days-num-en-holder d-flex align-items-center">
                                    <div className="days-num-en-input flex-grow-1">
                                        <input
                                            type="number"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Product Price"
                                            value={params.price}
                                            onChange={onChange}
                                            name="price"
                                            id="price"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="language">
                                    E-Book Language:
                                </label>
                                <div className="language-holder d-flex align-items-center">
                                    <div className="language-input flex-grow-1">
                                        <select
                                            className="form-select form-select-sm py-2"
                                            value={params.language}
                                            onChange={onChange}
                                            name="language"
                                            id="language"
                                            required
                                        >
                                            <option value="English">English</option>
                                            <option value="French">French</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="title_en">
                                    Title (English):
                                </label>
                                <div className="product-en-holder d-flex align-items-center">
                                    <div className="product-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Product Title in English"
                                            value={params.title_en}
                                            onChange={onChange}
                                            name="title_en"
                                            id="title_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="title_fr">
                                    Title (French):
                                </label>
                                <div className="product-en-holder d-flex align-items-center">
                                    <div className="product-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="textarea form-control form-control-sm py-2"
                                            placeholder="Product Title in French"
                                            value={params.title_fr}
                                            onChange={onChange}
                                            name="title_fr"
                                            id="title_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="description_en">
                                    Description (English):
                                </label>
                                <div className="description-en-holder d-flex align-items-center">
                                    <div className="description-en-input flex-grow-1">
                                        <textarea
                                            className="form-control form-control-sm py-2"
                                            placeholder="Description text in English"
                                            value={params.description_en}
                                            onChange={onChange}
                                            name="description_en"
                                            id="description_en"
                                            required
                                            rows={3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="description_fr">
                                    Description (French):
                                </label>
                                <div className="description-en-holder d-flex align-items-center">
                                    <div className="description-en-input flex-grow-1">
                                        <textarea
                                            className="form-control form-control-sm py-2"
                                            placeholder="Description text in French"
                                            value={params.description_fr}
                                            onChange={onChange}
                                            name="description_fr"
                                            id="description_fr"
                                            required
                                            rows={3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProductsForm;