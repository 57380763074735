import {Footer, Header, PublicBibleStoriesFiltered} from "./Public";
import {useEffect, useState} from "react";
import {publicBibleStoryService} from "../../_services";
import {useTranslation} from "react-i18next";

const AllBibleStories = () => {
    useEffect(() => {
        document.title = "Holylane - Bible Stories";
    }, []);

    const {t, i18n} = useTranslation();
    const [bibleStoryCategoriesData, setData] = useState([]);
    const [category, setCategory] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicBibleStoryService.getBibleStoryCategories();
                setData(data);
            } catch (error) {
                console.error("Error fetching Bible Story Categories data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const changeCategory = (category) => {
        setCategory(category);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const getCategoryName = (category) => {
        return i18n.language.startsWith('fr') ? category.name_fr : category.name_en;
    };

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="all-bible-stories-page">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                {loading ? (
                    <div className="w-100 p-3 all-bible-stories-holder row m-0">
                        <div className="col-6 ps-0">
                            <div style={{height: "18px"}}
                                 className="rounded-2 mb-1 main-loading-effect w-50"/>
                            <div style={{height: "30px"}}
                                 className="rounded-2 main-loading-effect"/>
                        </div>
                        <div className="col-6 pe-0">
                            <div style={{height: "18px"}}
                                 className="rounded-2 mb-1 main-loading-effect w-50"/>
                            <div style={{height: "30px"}}
                                 className="rounded-2 main-loading-effect"/>
                        </div>
                    </div>
                ) : (
                    <div className="filter-bible-stories-holder row m-0 p-3 pt-2">
                        <div className="col-6 ps-0">
                            <div className="form-group">
                                <label className="mb-1 text-real-small fw-bold-500" htmlFor="search">
                                    {t('search')}
                                </label>
                                <input
                                    type="text"
                                    className="form-control form-select-sm text-real-small"
                                    value={searchQuery}
                                    id="search"
                                    onChange={handleSearchInputChange}
                                    placeholder={`${t('searchInBibleStories')}`}
                                />
                            </div>
                        </div>
                        <div className="col-6 pe-0">
                            <div className="form-group">
                                <label className="mb-1 text-real-small fw-bold-500" htmlFor="categories">
                                    {t('category')}
                                </label>
                                <select
                                    className="form-select form-select-sm text-real-small"
                                    onChange={(e) => changeCategory(e.target.value)}
                                    id="categories"
                                >
                                    <option value="0">{t('all')}</option>
                                    {
                                        bibleStoryCategoriesData.map((item) => (
                                            <option value={item.id} key={item.id}>{getCategoryName(item)}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                )}
                <div className="horizontal-line mx-auto"/>
                <PublicBibleStoriesFiltered
                    category={category}
                    searchQuery={searchQuery}
                />
                <Footer/>
            </div>
        </div>
    );
};

export default AllBibleStories;