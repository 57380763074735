import React, {useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {userAccountService, userOrderService, userSettingService, userSubscriptionService} from "../../_services";
import stripeLogo from "../../assets/images/global/stripe_logo.png";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const StripePaymentForm = ({full_name, email, product_id, price, product_name, type}) => {
    const {t, i18n} = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    let navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    let location = useLocation();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: full_name,
                email: email,
            },
        });

        if (error) {
            setErrorMessage(error.message);
            return;
        }

        const response = await fetch("https://api.holylane.io/payments/stripe/create-payment-intent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-token': userAccountService.getToken(),
            },
            body: JSON.stringify({
                amount: parseInt(price * 100),
                currency: 'usd',
                description: product_name,
            }),
        });

        const {client_secret} = await response.json();

        const {error: confirmError, paymentIntent} = await stripe.confirmCardPayment(client_secret, {
            payment_method: paymentMethod.id,
        });

        if (confirmError) {
            setErrorMessage(confirmError.message);
            return;
        }

        if (paymentIntent.status === "succeeded") {
            if (type === "subscription") {
                const subscriptionData = {
                    subscription_id: paymentIntent.id,
                    plan_id: product_id,
                    total: price,
                    payment_method: "stripe",
                    status: "paid",
                };
                await userSubscriptionService.createSubscription(subscriptionData)
                    .then((res) => {
                        if (res.data.status === 200) {
                            userAccountService.saveToken(res.data.jwt);
                            localStorage.setItem('HOLYLANE_SUBSCRIPTION_ID', paymentIntent.id);
                            const redirectTo = location.state?.from || "/my-profile";
                            localStorage.setItem('HOLYLANE_REDIRECTION_PATH', redirectTo);
                            navigate('/thank-you-s', {replace: true});
                        }
                    })
            } else {
                const orderData = {
                    order_id: paymentIntent.id,
                    product_id: product_id,
                    total: price,
                    payment_method: "stripe",
                    status: "paid",
                };
                await userOrderService.createOrder(orderData);
                localStorage.setItem('HOLYLANE_ORDER_ID', paymentIntent.id);
                navigate('/thank-you', {replace: true});
            }
        }
    };

    const [showForm, setShowForm] = useState(false);
    return (
        <>
            {showForm ? (
                <form onSubmit={handleSubmit}>
                    {errorMessage && <div className="text-center small text-danger">{errorMessage}</div>}
                    <CardElement className="form-control py-3"/>
                    <div className="pay-button text-center">
                        <button className="border-0 pay-btn py-2 h-auto small px-5 mt-2" type="submit"
                                disabled={!stripe}>Pay
                        </button>
                    </div>
                </form>
            ) : (
                <div className="pay-button">
                    <button type="button"
                            onClick={() => setShowForm(true)}
                            className="pay-btn w-100">
                        <span className="fw-light d-inline-block small fw-bold-500">
                            {t('payCreditCard')}
                        </span>
                        <img src={stripeLogo} className="stripe-logo" alt=""/>
                    </button>
                </div>
            )}
        </>
    );
};

const StripePayment = (props) => {
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        const fetchStripePromiseKey = async () => {
            try {
                const data = await userSettingService.getStripePromiseKey();
                setStripePromise(loadStripe(data.promise_key));
            } catch (error) {
                console.error("Error fetching Stripe Promise Key:", error);
            }
        };

        fetchStripePromiseKey();
    }, []);

    return stripePromise ? (
        <Elements stripe={stripePromise}>
            <StripePaymentForm {...props} />
        </Elements>
    ) : (
        <div>Loading payment gateway...</div>
    );
};

export default StripePayment;
