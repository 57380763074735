import Axios from "../ServiceCaller";

let getGoogleAnalyticsKeysSettings = async () => {
    const {data} = await Axios.get("admin-settings/google-analytics");
    return data.GoogleAnalyticsKeysSettings;
};
let updateGoogleAnalyticsKeysSettings = async (GoogleAnalyticsKeysSettingsData) => {
    const {data} = await Axios.put("admin-settings/google-analytics", GoogleAnalyticsKeysSettingsData);
    return data;
};

let getFacebookPixelKeysSettings = async () => {
    const {data} = await Axios.get("admin-settings/facebook-pixel");
    return data.FacebookPixelKeysSettings;
};
let updateFacebookPixelKeysSettings = async (FacebookPixelKeysSettingsData) => {
    const {data} = await Axios.put("admin-settings/facebook-pixel", FacebookPixelKeysSettingsData);
    return data;
};

let getPaypalKeysSettings = async () => {
    const {data} = await Axios.get("/admin-settings/paypal");
    return data.PaypalKeysSettings;
};
let updatePaypalKeysSettings = async (paypalKeysSettingsData) => {
    const {data} = await Axios.put("/admin-settings/paypal", paypalKeysSettingsData);
    return data;
};

let getTranzakKeysSettings = async () => {
    const {data} = await Axios.get("admin-settings/tranzak");
    return data.TranzakKeysSettings;
};
let updateTranzakKeysSettings = async (TranzakKeysSettingsData) => {
    const {data} = await Axios.put("admin-settings/tranzak", TranzakKeysSettingsData);
    return data;
};

let getStripeKeysSettings = async () => {
    const {data} = await Axios.get("admin-settings/stripe");
    return data.StripeKeysSettings;
};
let updateStripeKeysSettings = async (StripeKeysSettingsData) => {
    const {data} = await Axios.put("admin-settings/stripe", StripeKeysSettingsData);
    return data;
};

export const adminSettingService = {
    getGoogleAnalyticsKeysSettings,
    updateGoogleAnalyticsKeysSettings,
    getFacebookPixelKeysSettings,
    updateFacebookPixelKeysSettings,
    getPaypalKeysSettings,
    updatePaypalKeysSettings,
    getTranzakKeysSettings,
    updateTranzakKeysSettings,
    getStripeKeysSettings,
    updateStripeKeysSettings,
};