import defaultImage from "../../../assets/images/global/default_image.jpg";
import {ProgressBar} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {publicChallengeService} from "../../../_services";
import ChallengeDayDetails from "./ChallengeDayDetails";
import {MdWorkspacePremium} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import UpgradeModal from "../global/UpgradeModal";

const ChallengeDetails = ({challenge_id, userChallengeDetails, setRefresh}) => {
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [challengeData, setData] = useState([]);
    const [lastCompletedDay, setLastCompletedDay] = useState(0);
    const [currentDay, setCurrentDay] = useState(1);
    const [startedChallenge, setStartedChallenge] = useState(false);
    const [updatedDate, setUpdatedDate] = useState('');
    const [challengeCompleted, setChallengeCompleted] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        if (userChallengeDetails && 'day_number' in userChallengeDetails) {
            let max_reached_day;
            if (userChallengeDetails.status === 'finished') {
                setChallengeCompleted(true)
                max_reached_day = userChallengeDetails.day_number;
            } else {
                max_reached_day = userChallengeDetails.day_number + 1;
            }
            setLastCompletedDay(userChallengeDetails.day_number)
            setUpdatedDate(userChallengeDetails.updated_date)
            setStartedChallenge(true)
            setCurrentDay(max_reached_day)
        }
    }, [userChallengeDetails]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicChallengeService.getChallengeById(challenge_id);
                setData(data);
            } catch (error) {
                console.error("Error fetching Challenge data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getTitle = (challenge) => {
        return i18n.language.startsWith('fr') ? challenge.title_fr : challenge.title_en;
    };

    let baseURL = 'https://api.holylane.io/';

    const calculateProgress = () => {
        if (!challengeData.days_num || challengeData.days_num === 0) return 0;
        return Math.min(100, (lastCompletedDay / challengeData.days_num) * 100);
    };

    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);

    const dateToCheck = new Date(updatedDate);
    dateToCheck.setUTCHours(0, 0, 0, 0);

    const isBeforeToday = dateToCheck < today;

    const startChallengeForUser = () => {
        publicChallengeService.startChallengeForUser(challenge_id)
            .then(res => {
                if (res.data.status === 200) {
                    setRefresh(prev => !prev);
                }
            })
            .catch(error => console.log(error))
    };

    const getDescription = (challenge) => {
        return i18n.language.startsWith('fr') ? challenge.description_fr : challenge.description_en;
    };

    useEffect(() => {
        if (challengeData && challengeData.days_num) {
            const currentDayId = `day-${currentDay}`;
            const element = document.getElementById(currentDayId);

            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
            }
        }
    }, [currentDay, challengeData]);

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);

    return (
        <>
            {loading ? (
                <div className="p-3">
                    <div style={{height: "40px"}}
                         className="rounded-3 main-loading-effect mb-3"/>
                    <div style={{height: "150px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                    <div className="horizontal-line mx-auto mb-3"/>
                    <div style={{height: "17px", width: "260px"}}
                         className="rounded-2 main-loading-effect mb-3"/>
                </div>
            ) : (
                challengeData && (
                    <div className="challenge-details-holder">
                        <div className="challenge-header-holder p-3">
                            <div className="d-flex align-items-center">
                                <div className="w-100 overflow-x-scroll text-nowrap gray-bg-color challenge-days-holder"
                                     style={{scrollbarWidth: "none"}}>
                                    {Array.from({length: challengeData.days_num}, (_, i) => (
                                        <div
                                            key={i}
                                            id={`day-${i + 1}`}
                                            className={`text-center small d-inline-block text-nowrap py-2 w-25 fw-bold-500 ${currentDay === (i + 1) ? 'current' : ''}`}
                                            onClick={() => {
                                                setCurrentDay(i + 1);
                                            }}
                                        >
                                            {t('day')} {i + 1}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="challenge-image-holder background-image-center mt-3 rounded-2 light-shadow"
                                 style={{
                                     height: "150px",
                                     backgroundImage: `url(${challengeData.image ? `${baseURL}thedss/images/challenges/${challengeData.image}` : defaultImage})`
                                 }}>
                                <div
                                    className="content position-relative d-flex flex-column justify-content-end h-100">
                                    <div className="challenge-progress py-2 px-2 rounded-2"
                                         style={{backgroundImage: 'linear-gradient(25deg, #fff, transparent)'}}>
                                        {lastCompletedDay === 0 ? (
                                            <div className="text-center small fw-bold-600">
                                                {t('noDayCompleted')}
                                            </div>
                                        ) : lastCompletedDay >= challengeData.days_num ? (
                                            <div className="text-center small fw-bold-600">
                                                {t('allDaysCompleted')}
                                            </div>
                                        ) : (
                                            <>
                                                <div style={{
                                                    marginLeft: `${calculateProgress().toFixed() - 5}%`,
                                                    width: "fit-content"
                                                }}
                                                     className="bg-light progress-value text-real-small p-1 rounded-3 mb-1">
                                                    {calculateProgress().toFixed(0)}%
                                                </div>
                                                <ProgressBar now={calculateProgress()}/>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="horizontal-line mx-auto"/>
                        <div className="d-flex justify-content-between p-3">
                            <div className="black-text-color fw-bold-600 small">
                                {getTitle(challengeData)}
                            </div>
                            {challengeData.is_premium === 1 && (
                                <div
                                    className="d-flex align-items-center text-white light-shadow ps-1 pe-2 py-1 rounded-3 main-background-premium-color">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <MdWorkspacePremium size="25"/>
                                    </div>
                                    <div className="ms-1 small fw-bold-600">
                                        Premium
                                    </div>
                                </div>
                            )}
                        </div>
                        {startedChallenge ? (
                            currentDay <= lastCompletedDay ? (
                                <ChallengeDayDetails
                                    challenge_id={challenge_id}
                                    day_number={currentDay}
                                    lastCompletedDay={lastCompletedDay}
                                    startedChallenge={startedChallenge}
                                    status='completed'
                                    setRefresh={setRefresh}
                                    completed={challengeCompleted}
                                />
                            ) : currentDay === lastCompletedDay + 1 ? (
                                isBeforeToday ? (
                                    <ChallengeDayDetails
                                        challenge_id={challenge_id}
                                        day_number={currentDay}
                                        lastCompletedDay={lastCompletedDay}
                                        startedChallenge={startedChallenge}
                                        status='ready'
                                        setRefresh={setRefresh}
                                    />
                                ) : (
                                    <div className="main-text-dark-color small pt-1 p-3 text-center">
                                        {t('seeYouTomorrow')}
                                    </div>
                                )
                            ) : currentDay > lastCompletedDay + 1 ? (
                                <div className="main-text-dark-color small pt-1 p-3 text-center">
                                    {t('stayOnTrack')}
                                </div>
                            ) : null
                        ) : (
                            <div className="challenge-info-holder px-3">
                                <div className="duration-holder black-text-color fw-bold-600 small mb-3">
                                    {t('challengeDuration')}: <span className="fw-normal">
                                {`${challengeData.days_num} ${t('day')}${challengeData.days_num > 1 ? 's' : ''}`}</span>
                                </div>
                                <div className="fw-bold-600 small black-text-color mb-2">
                                    {t('challengeDescription')}
                                </div>
                                <div className="gray-text-color small">
                                    {getDescription(challengeData)}
                                </div>
                                <button
                                    className="border-0 main-hover-background-color text-white small fw-bold-500 py-2 px-5 rounded-2 mt-4"
                                    onClick={() => {
                                        if (challengeData.accessible) {
                                            startChallengeForUser(challengeData);
                                        } else {
                                            handleShow();
                                        }
                                    }}>
                                    {t('startChallengeCTA')}
                                </button>
                                <UpgradeModal show={modalShow} handleClose={handleClose} message="Want to Upgrade now!"/>
                            </div>
                        )}
                    </div>
                )
            )}
        </>
    );
};

export default ChallengeDetails;