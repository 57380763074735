import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {publicMessageService} from "../../_services";
import {TbArrowLeft} from "react-icons/tb";
import {Footer, Header} from "./Public";
import {Link, useParams} from "react-router-dom";
import versePhoto from "../../assets/images/global/versePhoto.jpg"

const Verse = () => {
    useEffect(() => {
        document.title = "Holylane - Verse";
    }, []);

    let {cid} = useParams();
    const {t, i18n} = useTranslation();
    const [categoryData, setCategoryData] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const data = await publicMessageService.getCategoryDataById(cid);
                setCategoryData(data);
            } catch (error) {
                console.error("Error fetching category data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const getReference = () => {
        return i18n.language.startsWith('fr') ? categoryData.reference_fr : categoryData.reference_en;
    };

    const getCategoryName = () => {
        return i18n.language.startsWith('fr') ? categoryData.name_fr : categoryData.name_en;
    };

    const getVerseText = () => {
        return i18n.language.startsWith('fr') ? categoryData.verse_text_fr : categoryData.verse_text_en;
    };

    const getMessage = () => {
        return i18n.language.startsWith('fr') ? categoryData.message_fr : categoryData.message_en;
    };

    const getPrayer = () => {
        return i18n.language.startsWith('fr') ? categoryData.prayer_fr : categoryData.prayer_en;
    };

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="main-verse-page home-container">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                <section className="main-title-section px-3 pt-3 my-1">
                    <div className="custom-title-holder">
                        <div className="d-flex align-items-center">
                            <Link to="/" className="icon-holder rounded-circle p-2 me-2">
                                <TbArrowLeft size="30" className="title-icon" color="#000"/>
                            </Link>
                            <div className="title-text">
                                <div className="fw-bold-600 small">
                                    {t('wordsToCategory')} {getCategoryName()}
                                </div>
                                <div className="text-real-small text-black-50">
                                    {t('stayCloser')}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="verse-text-section px-3 pt-3 position-relative">
                    {loading ? (
                        <>
                            <div style={{height: "120px"}}
                                 className="rounded-4 mb-3 me-2 main-loading-effect w-100 position-relative">
                            </div>
                            <div className="category-loading position-absolute shadow-sm rounded-4"></div>
                        </>
                    ) : (
                        <>
                            <div
                                className="row m-0 align-items-center custom-card-content p-2 border-bottom-0 position-relative">
                                <div className="col-4 p-0 p-1">
                                    <div className="image-holder">
                                        <img src={versePhoto} className="rounded-4 image-pic" alt={t('moodQuestion')}/>
                                    </div>
                                </div>
                                <div className="col-8 p-1 ps-3">
                                    <div className="texts-holder">
                                        <div className="text-black-50 small mb-3">
                                            {getVerseText()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="category-holder rounded-top-0 rounded-4 border-top-0 position-relative">
                                <div
                                    className="category-title small px-5 position-absolute d-flex justify-content-center align-items-center">
                            <span className="mt-3 small">
                                {getReference()}
                            </span>
                                </div>
                            </div>
                        </>
                    )}
                </section>
                <p className="clearfix"></p>
                <div className="pb-5">
                    <section className="message-section px-3 pt-3">
                        <div className="small fw-bold-600 mb-2 ps-3">
                            {t('theMessage')}
                        </div>
                        {loading ? (
                            <div style={{height: "120px"}}
                                 className="rounded-4 mb-3 me-2 placeholder-message w-100"/>
                        ) : (
                            <div className="row m-0 align-items-center custom-card-content p-3 rounded-4 small lh-lg">
                                {getMessage()}
                            </div>
                        )}
                    </section>
                    <section className="prayer-section px-3 py-3 pb-5">
                        <div className="small fw-bold-600 mb-2 ps-3">
                            {t('letsPray')}
                        </div>
                        {loading ? (
                            <div style={{height: "150px"}}
                                 className="rounded-4 mb-3 me-2 placeholder-prayer w-100"/>
                        ) : (
                            <div className="row m-0 align-items-center custom-card-content p-3 rounded-4 small lh-lg">
                                {getPrayer()}
                            </div>
                        )}
                    </section>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default Verse;
