import React, {useEffect, useState} from "react";
import defaultImage from "../../../assets/images/global/default_image.jpg";
import {Link} from "react-router-dom";
import {publicProductService} from "../../../_services";
import {useTranslation} from "react-i18next";

const ProductsByCategoryFiltered = ({category}) => {
    const {t, i18n} = useTranslation();
    const [productsData, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true);
                const data = await publicProductService.getProducts(category);
                setData(data);
            } catch (error) {
                console.error("Error fetching products:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, [category]);

    const getTitle = (product) => {
        return i18n.language.startsWith('fr') ? product.title_fr : product.title_en;
    };
    const getCategory = (product) => {
        return i18n.language.startsWith('fr') ? product.name_fr : product.name_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="my-3">
            {loading ? (
                <div className="products-holder row m-0">
                    <div className="col-4 px-2">
                        <div style={{height: "200px"}}
                             className="w-100 rounded-4 main-loading-effect d-inline-block"/>
                    </div>
                    <div className="col-4 px-2">
                        <div style={{height: "200px"}}
                             className="w-100 rounded-4 main-loading-effect d-inline-block"/>
                    </div>
                    <div className="col-4 px-2">
                        <div style={{height: "200px"}}
                             className="w-100 rounded-4 main-loading-effect d-inline-block"/>
                    </div>
                </div>
            ) : (
                <div className="products-holder row m-0">
                    {productsData.length > 0 && (
                        productsData.map((product) => (
                            <div className="col-4 mb-3" key={product.id}>
                                <Link to={`/bookshelf/book/${product.id}`} className="product-box d-inline-block w-100">
                                    <div className="img-holder w-100">
                                        {product.image ? (
                                            <img
                                                src={`${baseURL}thedss/images/products/${product.image}`}
                                                className="w-100 rounded-1"
                                                height="180"
                                                alt=""
                                            />
                                        ) : (
                                            <img
                                                src={defaultImage}
                                                className="w-100 rounded-1"
                                                height="180"
                                                alt=""
                                            />
                                        )}
                                    </div>
                                    <div className="title fw-bold-500 black-text-color small mt-2 px-2 one-line-limit text-center">
                                        {getTitle(product)}
                                    </div>
                                    <div className="title text-black-50 text-real-small fw-bold-500 px-3 one-line-limit text-center">
                                        {getCategory(product)}
                                    </div>
                                </Link>
                            </div>
                        )))}
                </div>
            )}
        </div>
    );
};

export default ProductsByCategoryFiltered;