import AdSettingsForm from "./AdSettingsForm";
import {BiEdit} from "react-icons/bi";
import {useEffect, useState} from "react";
import {adminAdService} from "../../../_services";

const AdSettings = () => {
    const [adSettingsData, setAdSettingsData] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminAdService.getAdSettings();
                setAdSettingsData(data);
            } catch (error) {
                console.error("Error fetching ad settings data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const setFormHidden = () => {
        setHidden(!hidden);
    }

    if (adSettingsData) {
        return (
            <>
                <div className="fw-bold-600 small mb-2">Ad Settings</div>
                <div className={hidden ? "d-none" : "d-block"}>
                    <AdSettingsForm
                        settings_id={adSettingsData.id}
                        ad_frequency_minutes={adSettingsData.ad_frequency_minutes}
                        ad_close_delay_seconds={adSettingsData.ad_close_delay_seconds}
                        sendRefreshToParent={setRefresh}
                        refresh={refresh}
                        sendHiddenToParent={setHidden}
                        hidden={hidden}
                    />
                </div>
                <div className={`row m-0 justify-content-center ${hidden ? "d-block" : "d-none"}`}>
                    <div
                        className="light-shadow bg-white p-2 p-lg-3 p-xl-4 rounded-2 d-flex flex-column flex-lg-row align-items-center justify-content-between small">
                        <div className="d-flex flex-column flex-lg-row flex-grow-1 justify-content-evenly">
                            <div className="setting-holder text-center mb-2 mb-lg-0">
                                <div
                                    className="text-black-50">Ad Frequency
                                </div>
                                <div className="setting-value">
                                    {adSettingsData.ad_frequency_minutes} Minutes
                                </div>
                            </div>
                            <div className="setting-holder text-center mb-2 mb-lg-0">
                                <div
                                    className="text-black-50">Ad Close Delay
                                </div>
                                <div className="setting-value">
                                    {adSettingsData.ad_close_delay_seconds} Seconds
                                </div>
                            </div>
                        </div>
                        <div onClick={setFormHidden}
                             className="edit-holder c-pointer d-flex align-items-center align-self-end mt-2 mt-lg-0 me-2 edit-icon">
                            <div className="icon-holder me-2"><BiEdit className="icon" size="25"/></div>
                            <div className="edit-text">Edit</div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <></>
        )
    }
}

export default AdSettings;