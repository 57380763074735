import React, {useState, useRef, useEffect} from 'react';
import {FaPlay, FaPause, FaForwardStep, FaBackwardStep} from "react-icons/fa6";
import ReactPlayer from "react-player";

const PublicBibleStoryPlayer = ({episodes, currentEpisodeId, playing, setPlaying, onNext, onPrevious}) => {
    const [played, setPlayed] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const playerRef = useRef(null);

    const currentEpisode = episodes.find((episode) => episode.id === currentEpisodeId);

    const handlePlayPause = () => {
        setPlaying(!playing);  // Toggle the global play state
    };

    const handleSeekChange = (e) => {
        const value = parseFloat(e.target.value);
        setPlayed(value);
        if (playerRef.current) {
            playerRef.current.seekTo(value, "fraction");
        }
    };

    const playedPercent = `${played * 100}%`;

    const handleProgress = (state) => {
        setPlayed(state.played);
        setCurrentTime(state.playedSeconds);
    };

    const handleDuration = (total) => {
        setDuration(total);
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div
            className="player-container d-flex flex-column align-items-center bg-white px-3 py-2 rounded-4 light-shadow">
            {currentEpisode && (
                <>
                    <ReactPlayer
                        ref={playerRef}
                        url={`https://api.holylane.io/thedss/sounds/bible-story-episodes/${currentEpisode.file_name_en}`}
                        playing={playing}
                        controls={false}
                        width="0"
                        height="0"
                        onProgress={handleProgress}
                        onDuration={handleDuration}
                        onEnded={onNext}
                    />

                    <div className="time-display d-flex justify-content-between w-100 mb-1 text-real-small">
                        <span>{formatTime(currentTime)}</span>
                        <span>{formatTime(duration)}</span>
                    </div>

                    <input
                        type="range"
                        min={0}
                        max={1}
                        step="0.01"
                        value={played}
                        onChange={handleSeekChange}
                        className="w-100 rounded-2"
                        style={{
                            background: `linear-gradient(to right, black 0%, black ${playedPercent}, #e0e0e0 ${playedPercent}, #e0e0e0 100%)`
                        }}
                    />

                    <div className="d-flex justify-content-center w-100 align-items-center mt-3">
                        <button className="border-0 bg-transparent" onClick={onPrevious}
                                disabled={episodes[0].id === currentEpisodeId}>
                            <FaBackwardStep size={20}/>
                        </button>

                        <button className="border-0 bg-black rounded-circle mx-3" onClick={handlePlayPause}
                                style={{width: '40px', height: '40px', padding: '0px 0px 3px 3px'}}>
                            {playing ? <FaPause size={20} color="#fff" style={{margin: '0 3px 0 0'}}/> :
                                <FaPlay size={20} color="#fff"/>}
                        </button>

                        <button className="border-0 bg-transparent" onClick={onNext}
                                disabled={episodes[episodes.length - 1].id === currentEpisodeId}>
                            <FaForwardStep size={20}/>
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default PublicBibleStoryPlayer;
