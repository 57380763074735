import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            // Footer Menu
            homeItem: "Home",
            bibleStoriesItem: "Bible Stories",
            bookShelfItem: "Christian Resources",
            moreItem: "More",
            verseForYou: "Verse for you",
            selectMoodAndLet: "Select your mood and let God speak to you",
            betterMeChallenge: "Better me challenge",
            moodQuestion: "How are you feeling now?",
            randomVerseTitle: "Some random verses",
            wordsToCategory: "God’s word for the",
            stayCloser: "Stay Closer to God",
            theMessage: "The Message",
            letsPray: "Let us pray 🙏",
            category: "Category",
            categories: "Categories",
            seeAll: "See All",
            bibleStories: "Bible Stories",
            allChallenges: "All challenges",
            day: "Day",
            duration: "Duration",
            // All Bible Stories Page:
            search: "Search",
            searchInBibleStories: "search in bible stories...",
            all: "All",
            // All Bible Story Categories
            bibleStoryCategories: "Bible story categories",
            otherCategories: "Other categories",
            // Episodes Page
            episodes: 'Episodes',
            // Challenge Page
            challengeDescription: 'Challenge description',
            challengeDuration: 'Duration of the challenge',
            noDayCompleted: 'No day is completed yet',
            allDaysCompleted: "Congratulations you've already finished this challenge",
            actionTitle: "Ready to Take the Challenge?",
            actionDescription: "Join our community of go-getters! Sign up now to track your progress, compete with friends, and achieve your personal best. Let’s embark on this journey of self-improvement together.",
            actionCTA: "Get Started",
            startChallengeCTA: "Take the Leap!",
            progress: "Progress",
            progressZero: "Waiting",
            progressCompleted: "Completed",
            markItDone: "Mark it Completed",
            stayOnTrack: "Stay on track! Finish previous days challenges to unlock this day.",
            dayCompleted: "Day completed! Look ahead to complete the whole challenge",
            seeYouTomorrow: "See you tomorrow for next day Challenge",
            challengeCompleted: "Congratulations on completing this challenge! We look forward to seeing you take on more challenges soon.",
            // Bookshelf Page
            hello: "Hello",
            whichBook1: "Which book suits your",
            whichBook2: "current mood?",
            searchBooks: "Search title, topic...",
            topEbook: "Top E-Book Reading",
            topByCategory: "Top E-books By Category",
            pages: "Pages",
            recentEbook: "Recent E-books",
            price: "Price",
            details: "Details",
            buyNow: "Buy Now",
            // Payments
            paymentMethods: "Payment Methods",
            payCreditCard: "Pay With Credit Card",
            payMobileWallet: "Pay With Mobile Wallet",
            // Register / Login
            yourFullName: "Your full name",
            yourEmail: "Your email address",
            enterVerificationCode: "Enter the verification code",
            sendVerificationCode: "Send verification code",
            verifyAndLogin: "Verify and login",
            completeSignup: "Complete Signup",
            // Thank you page
            great: "Great!",
            paymentConfirmed1: "Payment information",
            paymentConfirmed2: "confirmed",
            congratsDownload1: "Congratulations!",
            congratsDownload2: "You can download the E-Book bellow",
            yourSummary: "Your summary",
            totalToBePaid: "Total to be paid",
            downloadCta: "Download The Book Now",
        }
    },
    fr: {
        translation: {
            // Footer Menu
            homeItem: "Accueil",
            bibleStoriesItem: "Récits bibliques",
            bookShelfItem: "Ressources Chrétiennes",
            moreItem: "Plus",
            verseForYou: "Verset pour vous",
            selectMoodAndLet: "Sélectionnez votre humeur et laissez Dieu vous parler",
            betterMeChallenge: "Mieux me défier",
            moodQuestion: "Comment te sens-tu maintenant ?",
            randomVerseTitle: "Quelques versets aléatoires",
            wordsToCategory: "La parole de Dieu pour le",
            stayCloser: "Restez proche de Dieu",
            theMessage: "Le message",
            letsPray: "Prions ensemble 🙏",
            categories: "Catégories",
            category: "Catégorie",
            seeAll: "Voir Tout",
            bibleStories: "Récits bibliques",
            allChallenges: "Tous les défis",
            day: "Jour",
            duration: "Durée",
            // All Bible Stories Page:
            search: "Rechercher",
            searchInBibleStories: "rechercher dans les récits bibliques...",
            all: "Tous",
            // All Bible Story Categories
            bibleStoryCategories: "Catégories de récits bibliques",
            otherCategories: "Autres catégories",
            // Episodes Page
            episodes: 'Épisodes',
            // Challenge Page
            challengeDescription: 'Description du défi',
            challengeDuration: 'La durée du défi',
            noDayCompleted: 'Pas encore de jour terminé',
            allDaysCompleted: "Félicitations, vous avez déjà terminé ce défi",
            actionTitle: "Prêt à relever le défi ?",
            actionDescription: "Rejoignez notre communauté de battants ! Inscrivez-vous maintenant pour suivre vos progrès, concourir avec vos amis et atteindre votre meilleur niveau. Embarquons ensemble dans ce voyage d'amélioration personnelle.",
            actionCTA: "Commencer",
            startChallengeCTA: "Lancez-vous !",
            progress: "Progrès",
            progressZero: "Attente",
            progressCompleted: "Terminé",
            markItDone: "Marquez-le comme Terminé",
            stayOnTrack: "Restez sur la bonne voie ! Terminez les défis des jours précédents pour débloquer cette journée.",
            dayCompleted: "Journée terminée ! Poursuivez pour achever l'ensemble du défi.",
            seeYouTomorrow: "À demain pour le défi du jour suivant.",
            challengeCompleted: "Félicitations pour avoir complété ce défi ! Nous avons hâte de vous voir relever d'autres défis bientôt.",
            // Bookshelf Page
            hello: "Bonjour",
            whichBook1: "Quel livre correspond à votre",
            whichBook2: "humeur actuelle ?",
            searchBooks: "Rechercher un titre, un sujet...",
            topEbook: "Meilleures lectures d'e-books",
            topByCategory: "Meilleurs e-books par catégorie",
            pages: "Pages",
            recentEbook: "Livres électroniques récents",
            price: "Prix",
            details: "Détails",
            buyNow: "Achetez maintenant",
            // Payments
            paymentMethods: "Méthodes de paiement",
            payCreditCard: "Payer par carte de crédit",
            payMobileWallet: "Payer avec un portefeuille mobile",
            // Register / Login
            yourFullName: "Votre nom complet",
            yourEmail: "Votre adresse e-mail",
            enterVerificationCode: "Entrez le code de vérification",
            sendVerificationCode: "Envoyer le code de vérification",
            verifyAndLogin: "Vérifier et se connecter",
            completeSignup: "Terminer l'inscription",
            // Thank you page
            great: "Génial !",
            paymentConfirmed1: "Informations de paiement",
            paymentConfirmed2: "confirmées",
            congratsDownload1: "Félicitations !",
            congratsDownload2: "Vous pouvez télécharger l'E-Book ci-dessous",
            yourSummary: "Votre résumé",
            totalToBePaid: "Total à payer",
            downloadCta: "Télécharger le livre maintenant",
        }
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
