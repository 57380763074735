import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import './pages/public/public.css';
import './App.css';
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import Error from "./_utils/Error";
import '../src/_helpers/i18n';
import PrivateRouter from "./pages/private/PrivateRouter";
import AuthRouter from "./pages/auth/AuthRouter";
import AuthGuard from "./_helpers/AuthGuard";
import IsLogged from "./_helpers/IsLogged";
import {
    Home,
    Verse,
    BibleStories,
    BibleStory,
    AllBibleStories,
    AllBibleStoryCategories,
    BibleStoriesByCategory,
    Challenge,
    Bookshelf,
    Book,
    ThankYou,
    PricingPlans,
    ThankYouSubscription,
    MyProfile,
    MorePageContent,
    QuizHome,
    QuizzesByCategory,
    QuizQuestions,
} from "./pages/public/Public";
import ScrollRestoration from "./components/global/ScrollRestoration";
import DisplayAds from "./components/global/DisplayAds";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <DisplayAds/>
                <ScrollRestoration/>
                <Routes>
                    {/* Public Pages */}
                    <Route index element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/verse/:cid" element={<Verse/>}/>
                    <Route path="/bible-stories" element={<BibleStories/>}/>
                    <Route path="/bible-stories/all" element={<AllBibleStories/>}/>
                    <Route path="/bible-stories/categories" element={<AllBibleStoryCategories/>}/>
                    <Route path="/bible-stories/category/:bscid" element={<BibleStoriesByCategory/>}/>
                    <Route path="/bible-stories/bible-story/:bid" element={<BibleStory/>}/>
                    <Route path="/challenges/challenge/:chid" element={<Challenge/>}/>
                    <Route path="/bookshelf" element={<Bookshelf/>}/>
                    <Route path="/bookshelf/book/:bid" element={<Book/>}/>
                    <Route path="/thank-you" element={<ThankYou/>}/>
                    <Route path="/thank-you-s" element={<ThankYouSubscription/>}/>
                    <Route path="/pricing-plans" element={<PricingPlans/>}/>
                    <Route path="/my-profile" element={<MyProfile/>}/>
                    <Route path="/more" element={<MorePageContent/>}/>
                    <Route path="/bible-quizzes" element={<QuizHome/>}/>
                    <Route path="/bible-quizzes/:qcid/quizzes" element={<QuizzesByCategory/>}/>
                    <Route path="/bible-quizzes/quiz/:qid/questions" element={<QuizQuestions/>}/>
                    {/* Authentication Pages */}
                    <Route path="/auth/*" element={
                        <IsLogged>
                            <AuthRouter/>
                        </IsLogged>
                    }/>

                    {/* Private Pages */}
                    <Route path="/admin/*" element={
                        <AuthGuard>
                            <PrivateRouter/>
                        </AuthGuard>
                    }/>

                    {/* Catch-All 404 Page */}
                    <Route path="*" element={<Error/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;