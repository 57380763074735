import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {publicBibleStoryService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";
import {Link, useNavigate} from "react-router-dom";
import {MdWorkspacePremium} from "react-icons/md";

const RecentBibleStories = () => {
    const {t, i18n} = useTranslation();
    const [bibleStories, setBibleStories] = useState([]);
    const [bibleStoriesCount, setBibleStoriesCount] = useState(0);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();

    useEffect(() => {
        const fetchBibleStories = async () => {
            try {
                setLoading(true);
                const data = await publicBibleStoryService.getRecentBibleStories();
                const count = await publicBibleStoryService.getCountBibleStories();
                setBibleStories(data);
                setBibleStoriesCount(count)
            } catch (error) {
                console.error("Error fetching bible stories:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBibleStories();
    }, []);

    const getTitle = (bibleStory) => {
        return i18n.language.startsWith('fr') ? bibleStory.title_fr : bibleStory.title_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <section className="recent-bible-stories-section p-3 pb-5">
            <div className="d-flex align-items-center justify-content-between mb-3 px-1 small">
                <div className="title fw-bold-600">
                    {t('bibleStories')}
                </div>
                <Link to="/bible-stories/all" className="link-text-holder fw-bold-600 main-text-color">
                    {t('seeAll')}
                    {bibleStoriesCount > 0 && (
                        <span className="ms-1">
                            ({bibleStoriesCount})
                        </span>
                    )}
                </Link>
            </div>
            <div className="bible-story-categories-holder">
                {loading ? (
                    <div className="w-100">
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "65px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                    </div>
                ) : (
                    <div className="w-100">
                        {bibleStories.map((bibleStory) => (
                            <div className="d-flex light-shadow w-100 rounded-3 mb-3" key={bibleStory.id}
                                 onClick={() => {
                                     navigate(`/bible-stories/bible-story/${bibleStory.id}`);
                                 }}>
                                <div className="img-holder rounded-3 position-relative">
                                    {bibleStory.image ? (
                                        <img
                                            src={`${baseURL}thedss/images/bible-stories/${bibleStory.image}`}
                                            className="border rounded-3 rounded-end-0"
                                            width="130"
                                            height="65"
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={defaultImage}
                                            className="border rounded-3 rounded-end-0"
                                            width="130"
                                            height="65"
                                            alt=""
                                        />
                                    )}
                                    {bibleStory.is_premium === 1 && (
                                        <div className="position-absolute top-0 ms-1 mt-1">
                                            <div
                                                className="d-flex align-items-center justify-content-center main-background-premium-color text-white rounded-circle p-1">
                                                <MdWorkspacePremium size="15"/>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="w-100">
                                    <div className="p-2">
                                        <div
                                            className="name-holder text-real-small fw-bold-600 black-text-color pe-1 two-lines-limit">
                                            {getTitle(bibleStory)}
                                        </div>
                                    </div>
                                    <div
                                        className="main-text-dark-color text-real-small fw-bold-500 text-end pb-1 pe-3">
                                        {bibleStory.episodes_num} Episode{bibleStory.episodes_num > 1 && 's'}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default RecentBibleStories;