import {PricingPlansFeaturesForm, PricingPlansFeaturesRow, Header} from "./Private";
import React, {useEffect, useState} from "react";
import {adminPricingPlanService} from "../../_services";

const PricingPlansFeatures = () => {

    const [pricingPlansFeaturesData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [pricingPlansFeatureToEdit, setPricingPlansFeatureToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminPricingPlanService.getPricingPlansFeatures();
                setData(data);
            } catch (error) {
                console.error("Error fetching Pricing Plans Features data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditPricingPlansFeature = (pricingPlansFeature) => {
        setEditMode(true);
        setPricingPlansFeatureToEdit(pricingPlansFeature);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="Pricing Plans Features"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="pricing-plans-features-page bg-white py-3 px-1 px-sm-3 rounded-3">
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                            Pricing Plans Features List
                        </div>
                        <div className="add-btn-holder">
                            <button
                                className={`main-button-color border-0 small rounded-2 py-1 px-3 ${hidden ? "d-block" : "d-none"}`}
                                type="button" disabled={!hidden}
                                onClick={setFormHiddenAdd}>
                                Add New
                            </button>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <PricingPlansFeaturesForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            pricingPlansFeature={pricingPlansFeatureToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div
                        className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${hidden ? "d-block" : "d-none"}`}>
                        <table className="table table-sm">
                            <thead>
                            <tr className="text-nowrap small">
                                <td className="text-black-50">Image Icon</td>
                                <td className="text-black-50">Title (En)</td>
                                <td className="text-black-50">Title (Fr)</td>
                                <td className="text-black-50">Description (En)</td>
                                <td className="text-black-50">Description (Fr)</td>
                                <td className="text-black-50 text-end">Created</td>
                                <td className="text-black-50 text-center">...</td>
                            </tr>
                            </thead>
                            <tbody>
                            {pricingPlansFeaturesData.length > 0 ? (
                                pricingPlansFeaturesData.map((pricingPlansFeature) => (
                                    <PricingPlansFeaturesRow
                                        key={pricingPlansFeature.id}
                                        pricingPlansFeature_id={pricingPlansFeature.id}
                                        image={pricingPlansFeature.image}
                                        title_en={pricingPlansFeature.title_en}
                                        title_fr={pricingPlansFeature.title_fr}
                                        description_en={pricingPlansFeature.description_en}
                                        description_fr={pricingPlansFeature.description_fr}
                                        created_date={pricingPlansFeature.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditPricingPlansFeature={handleEditPricingPlansFeature}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*End Page Content*/}
            </div>
        </>
    );
}

export default PricingPlansFeatures;