import {useEffect, useState} from "react";
import {publicAdService} from "../../_services";
import Modal from "react-bootstrap/Modal";
import {Link, useLocation} from "react-router-dom";

const DisplayAdContent = ({adFrequency, adCloseDelay}) => {
    const [loading, setLoading] = useState(true);
    const [randomAd, setRandomAd] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [canClose, setCanClose] = useState(false);
    const [closeDelayRemaining, setCloseDelayRemaining] = useState(adCloseDelay);
    const [frequencyRemaining, setFrequencyRemaining] = useState(adFrequency);
    const [frequencyTimer, setFrequencyTimer] = useState(null);
    const location = useLocation();

    const fetchRandomAd = async () => {
        try {
            setLoading(true);
            const randomAdData = await publicAdService.getRandomAd();
            if (randomAdData) {
                setRandomAd(randomAdData);
                setShowModal(true);
                setCanClose(false);
                setCloseDelayRemaining(adCloseDelay);

                const closeDelayTimer = setTimeout(() => {
                    setCanClose(true);
                }, adCloseDelay);

                let countdownCloseDelay = adCloseDelay / 1000;
                const intervalCloseDelay = setInterval(() => {
                    countdownCloseDelay -= 1;
                    setCloseDelayRemaining(countdownCloseDelay * 1000);
                    if (countdownCloseDelay <= 0) {
                        clearInterval(intervalCloseDelay);
                    }
                }, 1000);

                return () => {
                    clearTimeout(closeDelayTimer);
                    clearInterval(intervalCloseDelay);
                };
            }
        } catch (e) {
            console.error("Error fetching data:", e);
        } finally {
            setLoading(false);
        }
    };

    const startFrequencyCountdown = () => {
        if (frequencyTimer) {
            clearInterval(frequencyTimer);
        }

        let countdownFrequency = adFrequency / 1000;
        const intervalFrequency = setInterval(() => {
            countdownFrequency -= 1;
            setFrequencyRemaining(countdownFrequency * 1000);
            if (countdownFrequency <= 0) {
                clearInterval(intervalFrequency);
                fetchRandomAd();
            }
        }, 1000);

        setFrequencyTimer(intervalFrequency);
    };

    useEffect(() => {
        startFrequencyCountdown();

        return () => {
            if (frequencyTimer) {
                clearInterval(frequencyTimer);
            }
        };
    }, []);

    const handleClose = () => {
        if (canClose) {
            setShowModal(false);
            setFrequencyRemaining(adFrequency);
            setCanClose(false);
            startFrequencyCountdown();
        }
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        !loading ? (
            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="p-0" style={{width: "100%", maxWidth: "475px", margin: "auto"}}>
                    <div className="light-shadow rounded-1 position-relative">
                        <div className="position-absolute top-0 end-0 translate-middle-y pe-2">
                            <button type="button" onClick={handleClose} disabled={!canClose}
                                    className="border-0 py-1 px-2 rounded-3 main-hover-background-color text-white small">
                                {canClose ? 'Close' : `Close at ${Math.ceil(closeDelayRemaining / 1000)}s`}
                            </button>
                        </div>
                        {randomAd.type === 'Square' ? (
                            <div style={{
                                width: "100%",
                                maxWidth: "475px",
                                height: "100%",
                                maxHeight: "475px"
                            }}>
                                <a href={randomAd.link} target="_blank" rel="noopener noreferrer"
                                   className="d-inline-block w-100 h-100 rounded-1">
                                    <img src={`${baseURL}thedss/images/ads/${randomAd.image}`} alt="Ad"
                                         className="w-100 h-100 rounded-1"/>
                                </a>
                            </div>
                        ) : (
                            <div style={{
                                width: "100%",
                                maxWidth: "475px",
                                height: "100%",
                                maxHeight: "300px"
                            }}>
                                <a href={randomAd.link} target="_blank" rel="noopener noreferrer"
                                   className="d-inline-block w-100 h-100 rounded-1">
                                    <img src={`${baseURL}thedss/images/ads/${randomAd.image}`} alt="Ad"
                                         className="w-100 h-100 rounded-1"/>
                                </a>
                            </div>
                        )}
                        <div className="position-absolute top-100 start-50 translate-middle-x text-center w-100">
                            <div className="d-inline-block mx-auto bg-white px-2 rounded-bottom-3 black-text-color small">
                                Don't want to watch ads? click <Link state={{from: location.pathname}}
                                                                     to="/pricing-plans" className="main-text-dark-color fw-bold-500">Here</Link>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        ) : null
    );
};

export default DisplayAdContent;