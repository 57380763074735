import defaultImage from "../../../assets/images/global/default_image.jpg";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {publicChallengeService} from "../../../_services";
import {Link} from "react-router-dom";
import {MdWorkspacePremium} from "react-icons/md";

const AllChallenges = () => {
    const {t, i18n} = useTranslation();
    const [challenges, setChallenges] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchChallenges = async () => {
            try {
                setLoading(true);
                const data = await publicChallengeService.getChallenges();
                setChallenges(data);
            } catch (error) {
                console.error("Error fetching challenges:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchChallenges();
    }, []);

    const getTitle = (challenge) => {
        return i18n.language.startsWith('fr') ? challenge.title_fr : challenge.title_en;
    };
    const getDescription = (challenge) => {
        return i18n.language.startsWith('fr') ? challenge.description_fr : challenge.description_en;
    };
    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="all-challenges-section p-3">
            <div className="title fw-bold-600 small mb-2">
                {t('allChallenges')}
            </div>
            {loading ? (
                <>
                    <div style={{height: "100px"}}
                         className="rounded-4 mb-3 main-loading-effect w-100"/>
                    <div style={{height: "100px"}}
                         className="rounded-4 mb-3 main-loading-effect w-100"/>
                    <div style={{height: "100px"}}
                         className="rounded-4 mb-3 main-loading-effect w-100"/>
                    <div style={{height: "100px"}}
                         className="rounded-4 mb-3 main-loading-effect w-100"/>
                    <div style={{height: "100px"}}
                         className="rounded-4 mb-3 main-loading-effect w-100"/>
                    <div style={{height: "100px"}}
                         className="rounded-4 mb-3 main-loading-effect w-100"/>
                </>
            ) : (
                <div className="challenges-holder">
                    {challenges.length > 0 && (
                        challenges.map((challenge) => (
                            <Link to={`/challenges/challenge/${challenge.id}`} key={challenge.id} className="border border-light-subtle shadow-sm rounded-3 small mb-3 d-block">
                                <div className="row align-items-center m-0 p-2" style={{height: "100px"}}>
                                    <div className="col-8 p-0 h-100">
                                        <div className="info-holder d-flex flex-column h-100 pe-2">
                                            <div className="challenge-title fw-bold-600 small mb-1 black-text-color two-lines-limit">
                                                {getTitle(challenge)}
                                            </div>
                                            <div className="challenge-progress gray-text-color small mb-1 two-lines-limit">
                                                {getDescription(challenge)}
                                            </div>
                                            <div
                                                className="mt-auto challenge-duration text-real-small main-text-dark-color">
                                                {t('duration')}: <span className="fw-bold-500">
                                                {`${challenge.days_num} ${t('day')}${challenge.days_num > 1 ? 's' : ''}`}
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 p-0 h-100">
                                        <div className="image-holder h-100 position-relative">
                                            {challenge.image ? (
                                                <img
                                                    src={`${baseURL}thedss/images/challenges/${challenge.image}`}
                                                    className="border h-100 w-100"
                                                    alt=""
                                                />
                                            ) : (
                                                <img src={defaultImage} alt=""
                                                     className="border h-100 w-100"/>
                                            )}
                                            {challenge.is_premium === 1 && (
                                                <div className="position-absolute top-0 end-0 me-1 mt-1">
                                                    <div className="d-flex align-items-center justify-content-center main-background-premium-color text-white rounded-circle p-1">
                                                        <MdWorkspacePremium size="15"/>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default AllChallenges;