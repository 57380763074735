import React, {useEffect, useState} from "react";
import {adminChallengeService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";

const ChallengesForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        image: "",
        title_en: "",
        title_fr: "",
        description_en: "",
        description_fr: "",
        days_num: 1,
        is_premium: 0,
    });

    const [image, setImage] = useState(null);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title_en: props.challenge.title_en,
                title_fr: props.challenge.title_fr,
                description_en: props.challenge.description_en,
                description_fr: props.challenge.description_fr,
                days_num: props.challenge.days_num,
                is_premium: props.challenge.is_premium,
                image: props.challenge.image
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                image: "",
                title_en: "",
                title_fr: "",
                description_en: "",
                description_fr: "",
                days_num: 1,
                is_premium: 0,
            });
        }
    }, [props.editMode, props.challenge]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("title_en", params.title_en);
            formData.append("title_fr", params.title_fr);
            formData.append("description_en", params.description_en);
            formData.append("description_fr", params.description_fr);
            formData.append("days_num", params.days_num);
            formData.append("is_premium", params.is_premium);
            formData.append("image", image);

            if (isCreatingMode) {
                await adminChallengeService.createChallenge(formData);
            } else {
                formData.append("id", props.challenge.challenge_id);
                await adminChallengeService.updateChallenge(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                image: "",
                title_en: "",
                title_fr: "",
                description_en: "",
                description_fr: "",
                days_num: 1,
                is_premium: 0,
            });
            setImage(null);
        } catch (error) {
            console.error("Error Creating/Updating Challenge:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            image: "",
            title_en: "",
            title_fr: "",
            description_en: "",
            description_fr: "",
            days_num: 1,
            is_premium: 0,
        });
        props.sendHiddenToParent(!props.hidden);
        setImage(null);
    }

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="main-form-holder add-challenge-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Add / Update a Challenge</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-group text-center">
                                <label className="mb-1 text-black-50 small" htmlFor="selectedImage">
                                    Selected Image:
                                    <span className="text-black-50 text-real-small"> (Landscape Images)</span>
                                </label>
                                <div className="selected-image-holder border rounded-2 rounded-bottom-0">
                                    <div className="selected-image mx-auto rounded-2 rounded-bottom-0">
                                        {image ? (
                                            <img
                                                height="200"
                                                
                                                src={URL.createObjectURL(image)}
                                                alt="Selected Image For Challenge"
                                                className="w-100 rounded-2 rounded-bottom-0"
                                            />
                                        ) : params.image ? (
                                            <img
                                                height="200"
                                                
                                                src={`${baseURL}thedss/images/challenges/${params.image}`}
                                                alt="Current Image For Challenge"
                                                className="w-100 rounded-2 rounded-bottom-0"
                                            />
                                        ) : (
                                            <img
                                                height="200"
                                                
                                                src={defaultImage}
                                                alt="Default Image For Challenge"
                                                className="w-100 rounded-2 rounded-bottom-0"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="upload-btn-holder">
                                    <label className="mb-1 w-100" htmlFor="image">
                                        <div
                                            className="link-as-button px-3 py-2 rounded-top-0 rounded-2 mb-1 w-100 small">Upload
                                            Image
                                        </div>
                                    </label>
                                    <div className="image-holder d-flex align-items-center">
                                        <div className="image-input flex-grow-1">
                                            <input
                                                type="file"
                                                className="form-control py-2 d-none"
                                                onChange={onImageChange}
                                                name="image"
                                                id="image"
                                                accept="image/*"
                                                required={isCreatingMode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="title_en">
                                    Title (English):
                                </label>
                                <div className="challenge-en-holder d-flex align-items-center">
                                    <div className="challenge-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Challenge Title in English"
                                            value={params.title_en}
                                            onChange={onChange}
                                            name="title_en"
                                            id="title_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="title_fr">
                                    Title (French):
                                </label>
                                <div className="challenge-en-holder d-flex align-items-center">
                                    <div className="challenge-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Challenge Title in French"
                                            value={params.title_fr}
                                            onChange={onChange}
                                            name="title_fr"
                                            id="title_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="days_num">
                                    Challenge Days
                                </label>
                                <div className="days-num-en-holder d-flex align-items-center">
                                    <div className="days-num-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Number Of Days"
                                            value={params.days_num}
                                            onChange={onChange}
                                            name="days_num"
                                            id="days_num"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="is_premium">
                                    Premium Challenge:
                                </label>
                                <div className="is-premium-holder d-flex align-items-center">
                                    <div className="is-premium-input flex-grow-1">
                                        <select
                                            className="form-select form-select-sm py-2"
                                            value={params.is_premium}
                                            onChange={onChange}
                                            name="is_premium"
                                            id="is_premium"
                                            required
                                        >
                                            <option value="0">Not Premium Challenge</option>
                                            <option value="1">Premium Challenge</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="description_en">
                                    Description (English):
                                </label>
                                <div className="description-en-holder d-flex align-items-center">
                                    <div className="description-en-input flex-grow-1">
                                        <textarea
                                            className="form-control form-control-sm py-2"
                                            placeholder="Description text in English"
                                            value={params.description_en}
                                            onChange={onChange}
                                            name="description_en"
                                            id="description_en"
                                            required
                                            rows={3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="description_fr">
                                    Description (French):
                                </label>
                                <div className="description-en-holder d-flex align-items-center">
                                    <div className="description-en-input flex-grow-1">
                                        <textarea
                                            className="form-control form-control-sm py-2"
                                            placeholder="Description text in French"
                                            value={params.description_fr}
                                            onChange={onChange}
                                            name="description_fr"
                                            id="description_fr"
                                            required
                                            rows={3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChallengesForm;