import React, {useEffect, useState} from "react";
import {userAccountService} from "../../../_services";
import {Link, useLocation} from "react-router-dom";
import {BiLockOpen} from "react-icons/bi";
import {MdWorkspacePremium} from "react-icons/md";
import {useTranslation} from "react-i18next";

const UserDetailsSection = () => {
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState('');
    const [premiumUser, setPremiumUser] = useState(false);
    let location = useLocation();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const data = await userAccountService.getUserData();
                setUserDetails(data);
                const accessData = await userAccountService.isPremium();
                if (accessData) {
                    setPremiumUser(accessData === 'premium');
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, []);

    const formatDateTime = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short',
        };
        return new Date(dateString).toLocaleString(i18n.language, options);
    };

    return (
        <div>
            {loading ? (
                <div></div>
            ) : (
                <div className="my-details-section pt-3 px-3">
                    <div className="title fw-bold-600 mb-3">
                        My Details
                    </div>
                    <div className="light-shadow p-2 rounded-2">
                        <div className="user-name-holder small black-text-color mb-1">
                            {userDetails.full_name}
                        </div>
                        <div className="user-phone-holder small gray-text-color">
                            {userDetails.email}
                        </div>
                    </div>
                    <div className="membership-content mt-4">
                        <div className="title fw-bold-600 mb-3">
                            Membership Details
                        </div>
                        <div className="light-shadow p-2 rounded-2">
                            {premiumUser ? (
                                <div>
                                    <div style={{width: "fit-content"}}
                                         className="d-flex align-items-center text-white light-shadow ps-1 pe-2 py-1 rounded-3 main-background-premium-color ms-auto">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <MdWorkspacePremium size="25"/>
                                        </div>
                                        <div className="ms-1 small fw-bold-600">
                                            Premium
                                        </div>
                                    </div>
                                    <div className="fw-bold-600 limit black-text-color two-lines-limit lh-sm mb-1">
                                    </div>
                                    <div className="small gray-text-color">
                                        <div className="fw-bold-600">
                                            Subscription start:<br/>
                                            <span className="fw-normal">
                                        {formatDateTime(userDetails.subscription_start)}
                                       </span>
                                        </div>
                                        <div className="fw-bold-600">
                                            Subscription end:<br/>
                                            <span className="fw-normal">
                                        {formatDateTime(userDetails.subscription_end)}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="upgrade-holder">
                                    <div className="small black-text-color mb-3">
                                        You have only a limited access
                                    </div>
                                    <Link to="/pricing-plans"
                                          state={{from: location.pathname}}
                                          className="d-flex align-items-center justify-content-center link mx-auto rounded-2 py-1 px-2">
                                        <div className="d-flex align-items-center justify-content-center px-1">
                                            <BiLockOpen size="18"/>
                                        </div>
                                        <div className="px-1 small fw-bold-500">Upgrade Now</div>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserDetailsSection;