import Axios from "../ServiceCaller";

let getAdSettings = async () => {
    const {data} = await Axios.get("/public-ads/settings");
    return data.AdSettings;
};

let getRandomAd = async () => {
    const {data} = await Axios.get("/public-ads/random-ad");
    return data.Ad;
};

export const publicAdService = {
    getAdSettings,
    getRandomAd,
};