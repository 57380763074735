import {useState} from "react";
import {Header, OrdersFiltered} from "./Private";

const Orders = () => {
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <Header title="Orders"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="bg-white mb-3 p-2 p-lg-3 rounded-3 row m-0">
                    <div className="col-6 col-xl-4 col-xxl-3">
                        <div className="form-group">
                            <label className="mb-1 text-real-small fw-bold-500" htmlFor="search">
                                Search
                            </label>
                            <input
                                type="text"
                                className="form-control form-select-sm text-real-small"
                                value={searchQuery}
                                id="search"
                                onChange={handleSearchInputChange}
                                placeholder="email, amount, methods..."
                            />
                        </div>
                    </div>
                </div>
                <OrdersFiltered
                    searchQuery={searchQuery}
                />
                {/*End Page Content*/}
            </div>
        </>
    );
}

export default Orders;