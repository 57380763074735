import Axios from "../ServiceCaller";

// Quiz Categories
let getQuizCategories = async () => {
    const {data} = await Axios.get("/admin-quizzes/categories");
    return data.QuizCategories;
};
let createQuizCategory = async (quizCategoryData) => {
    const {data} = await Axios.post("/admin-quizzes/categories", quizCategoryData);
    return data;
};
let getQuizCategoryById = async (id) => {
    const {data} = await Axios.get(`/admin-quizzes/categories/${id}`);
    return data.QuizCategory;
};
let updateQuizCategory = async (quizCategoryData) => {
    const {data} = await Axios.put("/admin-quizzes/categories", quizCategoryData);
    return data;
};
let deleteQuizCategory = async (quizCategory_id) => {
    try {
        const response = await Axios.delete(`/admin-quizzes/categories`, {
            data: {id: quizCategory_id},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Quiz Category:", error);
        throw error;
    }
};

// Quizzes
let getQuizzes = async (category) => {
    const {data} = await Axios.get(`/admin-quizzes/cat/${category}`);
    return data.Quizzes;
};
let createQuiz = async (quizData) => {
    const {data} = await Axios.post("/admin-quizzes", quizData);
    return data;
};
let getQuizById = async (id) => {
    const {data} = await Axios.get(`/admin-quizzes/${id}`);
    return data.Quiz;
};
let updateQuiz = async (quizData) => {
    const {data} = await Axios.put("/admin-quizzes", quizData);
    return data;
};
let deleteQuiz = async (quiz_id) => {
    try {
        const response = await Axios.delete(`/admin-quizzes`, {
            data: {id: quiz_id},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Quiz: ", error);
        throw error;
    }
};

// Quiz Questions
let getQuizQuestions = async (quiz) => {
    const {data} = await Axios.get(`/admin-quizzes/quiz-questions/quiz/${quiz}`);
    return data.QuizQuestions;
};
let createQuizQuestion = async (quizQuestionData) => {
    const {data} = await Axios.post("/admin-quizzes/quiz-questions", quizQuestionData);
    return data;
};
let importQuestions = async (questionsData) => {
    const {data} = await Axios.post("/admin-quizzes/quiz-questions/import", questionsData);
    return data;
};
let getQuizQuestionById = async (id) => {
    const {data} = await Axios.get(`/admin-quizzes/quiz-questions/${id}`);
    return data.QuizQuestion;
};
let updateQuizQuestion = async (quizQuestionData) => {
    const {data} = await Axios.put("/admin-quizzes/quiz-questions", quizQuestionData);
    return data;
};
let deleteQuizQuestion = async (quiz_question_id) => {
    try {
        const response = await Axios.delete(`/admin-quizzes/quiz-questions`, {
            data: {id: quiz_question_id},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Quiz Question: ", error);
        throw error;
    }
};

export const adminQuizService = {
    // Quiz Categories
    getQuizCategories,
    createQuizCategory,
    updateQuizCategory,
    deleteQuizCategory,
    getQuizCategoryById,
    // Quizzes
    getQuizzes,
    createQuiz,
    updateQuiz,
    deleteQuiz,
    getQuizById,
    // Quiz Questions
    getQuizQuestions,
    createQuizQuestion,
    importQuestions,
    updateQuizQuestion,
    deleteQuizQuestion,
    getQuizQuestionById,
};