import payment_logo_1 from "../../assets/images/payments/payment_logo_1.jpg";
import payment_logo_2 from "../../assets/images/payments/payment_logo_2.jpg";
import payment_logo_3 from "../../assets/images/payments/payment_logo_3.jpg";
import payment_logo_4 from "../../assets/images/payments/payment_logo_4.jpg";
import payment_logo_5 from "../../assets/images/payments/payment_logo_5.jpg";
import payment_logo_6 from "../../assets/images/payments/payment_logo_6.jpg";
import payment_logo_7 from "../../assets/images/payments/payment_logo_7.jpg";
import payment_logo_8 from "../../assets/images/payments/payment_logo_8.jpg";
import payment_logo_9 from "../../assets/images/payments/payment_logo_9.jpg";

const PaymentAcceptances = () => {
    return (
        <div>
            <div className="mt-4 px-2 main-text-color text-center pb-4">
                We accept payment in <span className="fw-bold-600">100+</span> currencies
            </div>
            <div className="pb-2">
                <div
                    className="logos-holder bg-white p-2 shadow-sm d-flex flex-wrap align-items-center justify-content-center">
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_1} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_2} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_3} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_4} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_5} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_6} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_7} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_8} alt=""/>
                    </div>
                    <div className="logo-holder mx-1 mb-2">
                        <img src={payment_logo_9} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentAcceptances;