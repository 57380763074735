import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import ProductsByCategoryFiltered from "./ProductsByCategoryFiltered";
import {publicProductService} from "../../../_services";

const ProductsByCategory = () => {
    const {t, i18n} = useTranslation();
    const [productCategoriesData, setData] = useState([]);
    const [category, setCategory] = useState(0);
    const [loading, setLoading] = useState(true);
    const categoryRefs = useRef({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicProductService.getProductCategories();
                setData(data);
            } catch (error) {
                console.error("Error fetching product Categories data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getName = (product) => {
        return i18n.language.startsWith('fr') ? product.name_fr : product.name_en;
    };

    const handleCategoryClick = (id) => {
        setCategory(id);
        if (categoryRefs.current[id]) {
            categoryRefs.current[id].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        }
    };

    return (
        <div className="products-by-category pt-3 px-3">
            <div className="title fw-bold-600 small mb-2">
                {t('topByCategory')}
            </div>
            {loading ? (
                <div className="w-100 overflow-x-scroll text-nowrap" style={{scrollbarWidth: "none"}}>
                    <div style={{height: "33px", width: "110px"}}
                         className="rounded-4 me-2 main-loading-effect d-inline-block"/>
                    <div style={{height: "33px", width: "150px"}}
                         className="rounded-4 me-2 main-loading-effect d-inline-block"/>
                    <div style={{height: "33px", width: "90px"}}
                         className="rounded-4 me-2 main-loading-effect d-inline-block"/>
                    <div style={{height: "33px", width: "120px"}}
                         className="rounded-4 me-2 main-loading-effect d-inline-block"/>
                    <div style={{height: "33px", width: "100px"}}
                         className="rounded-4 me-2 main-loading-effect d-inline-block"/>
                    <div style={{height: "33px", width: "80px"}}
                         className="rounded-4 me-2 main-loading-effect d-inline-block"/>
                    <div style={{height: "33px", width: "130px"}}
                         className="rounded-4 me-2 main-loading-effect d-inline-block"/>
                </div>
            ) : (
                <div className="products-with-categories">
                    <div className="w-100 overflow-x-scroll text-nowrap" style={{scrollbarWidth: "none"}}>
                        <div
                            ref={(el) => categoryRefs.current[0] = el}
                            className={`category-item py-1 px-3 border border-light-subtle rounded-2 small me-2 d-inline-block text-wrap overflow-hidden ${category === 0 ? 'active' : ''}`}
                            onClick={() => handleCategoryClick(0)}
                        >
                            {t('all')}
                        </div>
                        {productCategoriesData.length > 0 && (
                            productCategoriesData.map((item) => (
                                <div
                                    key={item.id}
                                    ref={(el) => categoryRefs.current[item.id] = el}
                                    className={`category-item py-1 px-3 border border-light-subtle rounded-2 small me-2 d-inline-block text-wrap overflow-hidden ${item.id === category ? 'active' : ''}`}
                                    onClick={() => handleCategoryClick(item.id)}
                                >
                                    {getName(item)}
                                </div>
                            ))
                        )}
                    </div>
                    <ProductsByCategoryFiltered category={category}/>
                </div>
            )}
        </div>
    );
};

export default ProductsByCategory;