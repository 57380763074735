import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {publicChallengeService} from "../../../_services";
import ChallengeActions from "./ChallengeActions";

const ChallengeDayDetails = ({challenge_id, day_number, lastCompletedDay, startedChallenge, status, setRefresh, completed}) => {
    const {t, i18n} = useTranslation();
    const [challengeData, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicChallengeService.getChallengeDayDetails(challenge_id, day_number);
                setData(data);
            } catch (error) {
                console.error("Error fetching Challenge data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [challenge_id, day_number]);

    const getColumnValue = (challenge, column) => {
        return i18n.language.startsWith('fr') ? challenge[`${column}_fr`] : challenge[`${column}_en`];
    };

    return (
        <>
            {loading ? (
                <div className="p-3">
                    <div style={{height: "18px", width: "150px"}}
                         className="rounded-2 main-loading-effect mb-2"/>
                    <div style={{height: "90px", width: "100%"}}
                         className="rounded-3 main-loading-effect mb-3"/>
                    <div style={{height: "18px", width: "150px"}}
                         className="rounded-2 main-loading-effect mb-2"/>
                    <div style={{height: "90px", width: "100%"}}
                         className="rounded-3 main-loading-effect mb-3"/>
                    <div style={{height: "18px", width: "150px"}}
                         className="rounded-2 main-loading-effect mb-2"/>
                    <div style={{height: "90px", width: "100%"}}
                         className="rounded-3 main-loading-effect mb-3"/>
                </div>
            ) : (
                <div className="day-details-holder pt-1 p-3">
                    <div className="tasks-holder mb-3">
                        <div className="fw-bold-600 small black-text-color mb-2">
                            Today tasks
                        </div>
                        <div className="task-info light-shadow p-2 rounded-3" style={{backgroundColor: "#F7D8CF"}}>
                            <div className="fw-bold-500 small black-text-color mb-1">
                                {getColumnValue(challengeData, 'task_title')}
                            </div>
                            <div className="small black-text-color">
                                {getColumnValue(challengeData, 'task_details')}
                            </div>
                        </div>
                    </div>

                    <div className="meditations-holder mb-3">
                        <div className="fw-bold-600 small black-text-color mb-2">
                            Today meditations
                        </div>
                        <div className="meditation-info light-shadow p-2 rounded-3" style={{backgroundColor: "#DFECF8"}}>
                            <div className="fw-bold-500 small black-text-color mb-1">
                                {getColumnValue(challengeData, 'meditation_title')}
                            </div>
                            <div className="small black-text-color">
                                {getColumnValue(challengeData, 'meditation_details')}
                            </div>
                        </div>
                    </div>

                    <div className="assignments-holder mb-3">
                        <div className="fw-bold-600 small black-text-color mb-2">
                            Today assignments
                        </div>
                        <div className="assignment-info light-shadow p-2 rounded-3" style={{backgroundColor: "#EBF7DE"}}>
                            <div className="fw-bold-500 small black-text-color mb-1">
                                {getColumnValue(challengeData, 'assignment_title')}
                            </div>
                            <div className="small black-text-color">
                                {getColumnValue(challengeData, 'assignment_details')}
                            </div>
                        </div>
                    </div>

                    <div className="challenge-actions mt-4">
                        <ChallengeActions
                            challenge_id={challenge_id}
                            startedChallenge={startedChallenge}
                            lastCompletedDay={lastCompletedDay}
                            status={status}
                            setRefresh={setRefresh}
                            completed={completed}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default ChallengeDayDetails;