import React, {useEffect, useState} from "react";
import {adminQuizService} from "../../../_services";

const QuizQuestionsForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        question_text_en: "",
        question_text_fr: "",
        correct_answer_en: "",
        correct_answer_fr: "",
        wrong_answer_1_en: "",
        wrong_answer_1_fr: "",
        wrong_answer_2_en: "",
        wrong_answer_2_fr: "",
        wrong_answer_3_en: "",
        wrong_answer_3_fr: "",
    });

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                question_text_en: props.quizQuestion.question_text_en,
                question_text_fr: props.quizQuestion.question_text_fr,
                correct_answer_en: props.quizQuestion.correct_answer_en,
                correct_answer_fr: props.quizQuestion.correct_answer_fr,
                wrong_answer_1_en: props.quizQuestion.wrong_answer_1_en,
                wrong_answer_1_fr: props.quizQuestion.wrong_answer_1_fr,
                wrong_answer_2_en: props.quizQuestion.wrong_answer_2_en,
                wrong_answer_2_fr: props.quizQuestion.wrong_answer_2_fr,
                wrong_answer_3_en: props.quizQuestion.wrong_answer_3_en,
                wrong_answer_3_fr: props.quizQuestion.wrong_answer_3_fr,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                question_text_en: "",
                question_text_fr: "",
                correct_answer_en: "",
                correct_answer_fr: "",
                wrong_answer_1_en: "",
                wrong_answer_1_fr: "",
                wrong_answer_2_en: "",
                wrong_answer_2_fr: "",
                wrong_answer_3_en: "",
                wrong_answer_3_fr: "",
            });
        }
    }, [props.editMode, props.quizQuestion]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("question_text_en", params.question_text_en);
            formData.append("question_text_fr", params.question_text_fr);
            formData.append("correct_answer_en", params.correct_answer_en);
            formData.append("correct_answer_fr", params.correct_answer_fr);
            formData.append("wrong_answer_1_en", params.wrong_answer_1_en);
            formData.append("wrong_answer_1_fr", params.wrong_answer_1_fr);
            formData.append("wrong_answer_2_en", params.wrong_answer_2_en);
            formData.append("wrong_answer_2_fr", params.wrong_answer_2_fr);
            formData.append("wrong_answer_3_en", params.wrong_answer_3_en);
            formData.append("wrong_answer_3_fr", params.wrong_answer_3_fr);
            formData.append("quiz_id", props.quiz);

            if (isCreatingMode) {
                await adminQuizService.createQuizQuestion(formData);
            } else {
                formData.append("id", props.quizQuestion.quizQuestion_id);
                await adminQuizService.updateQuizQuestion(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                question_text_en: "",
                question_text_fr: "",
                correct_answer_en: "",
                correct_answer_fr: "",
                wrong_answer_1_en: "",
                wrong_answer_1_fr: "",
                wrong_answer_2_en: "",
                wrong_answer_2_fr: "",
                wrong_answer_3_en: "",
                wrong_answer_3_fr: "",
            });
        } catch (error) {
            console.error("Error Creating/Updating quiz question:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            question_text_en: "",
            question_text_fr: "",
            correct_answer_en: "",
            correct_answer_fr: "",
            wrong_answer_1_en: "",
            wrong_answer_1_fr: "",
            wrong_answer_2_en: "",
            wrong_answer_2_fr: "",
            wrong_answer_3_en: "",
            wrong_answer_3_fr: "",
        });
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-quiz-question-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Add / Update a quiz question</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="question_text_en">
                                    Question Text (English):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Question Text in English"
                                            value={params.question_text_en}
                                            onChange={onChange}
                                            name="question_text_en"
                                            id="question_text_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="question_text_fr">
                                    Question Text (French):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Question Text in French"
                                            value={params.question_text_fr}
                                            onChange={onChange}
                                            name="question_text_fr"
                                            id="question_text_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="correct_answer_en">
                                    Correct Answer (English):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Correct Answer in English"
                                            value={params.correct_answer_en}
                                            onChange={onChange}
                                            name="correct_answer_en"
                                            id="correct_answer_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="correct_answer_fr">
                                    Correct Answer (French):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Correct Answer in French"
                                            value={params.correct_answer_fr}
                                            onChange={onChange}
                                            name="correct_answer_fr"
                                            id="correct_answer_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="wrong_answer_1_en">
                                    Wrong Answer 1 (English):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Wrong Answer 1 in English"
                                            value={params.wrong_answer_1_en}
                                            onChange={onChange}
                                            name="wrong_answer_1_en"
                                            id="wrong_answer_1_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="wrong_answer_1_fr">
                                    Wrong Answer 1 (French):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Wrong Answer 1 in French"
                                            value={params.wrong_answer_1_fr}
                                            onChange={onChange}
                                            name="wrong_answer_1_fr"
                                            id="wrong_answer_1_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="wrong_answer_2_en">
                                    Wrong Answer 2 (English):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Wrong Answer 2 in English"
                                            value={params.wrong_answer_2_en}
                                            onChange={onChange}
                                            name="wrong_answer_2_en"
                                            id="wrong_answer_2_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="wrong_answer_2_fr">
                                    Wrong Answer 2 (French):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Wrong Answer 2 in French"
                                            value={params.wrong_answer_2_fr}
                                            onChange={onChange}
                                            name="wrong_answer_2_fr"
                                            id="wrong_answer_2_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="wrong_answer_3_en">
                                    Wrong Answer 3 (English):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Wrong Answer 3 in English"
                                            value={params.wrong_answer_3_en}
                                            onChange={onChange}
                                            name="wrong_answer_3_en"
                                            id="wrong_answer_3_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="wrong_answer_3_fr">
                                    Wrong Answer 3 (French):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Wrong Answer 3 in French"
                                            value={params.wrong_answer_3_fr}
                                            onChange={onChange}
                                            name="wrong_answer_3_fr"
                                            id="wrong_answer_3_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default QuizQuestionsForm;