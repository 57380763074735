import {Link, NavLink, useNavigate} from "react-router-dom";
import mainLogo from "../../../assets/images/global/primeband_logo.png"
import {GoSignOut} from "react-icons/go";
import {userAccountService} from "../../../_services";
import {BsCart3, BsChatLeftQuote} from "react-icons/bs";
import {MdOutlineAutoStories, MdOutlineQuiz} from "react-icons/md";
import {BiAbacus, BiDialpadAlt} from "react-icons/bi";
import {AiOutlineProduct} from "react-icons/ai";
import {PiGear} from "react-icons/pi";
import {IoPricetagsOutline} from "react-icons/io5";
import {LuLayoutDashboard, LuUsers2} from "react-icons/lu";

const Sidebar = () => {
    let navigate = useNavigate();
    const menuItem = [
        {
            path: "/admin/dashboard",
            name: "Dashboard",
            icon: <LuLayoutDashboard size="25"/>
        },
        {
            path: "/admin/users",
            name: "Users",
            icon: <LuUsers2 size="25"/>
        },
        {
            path: "/admin/messages",
            name: "Messages",
            icon: <BsChatLeftQuote size="25"/>
        },
        {
            path: "/admin/bible-stories",
            name: "Bible Stories",
            icon: <MdOutlineAutoStories size="25"/>
        },
        {
            path: "/admin/challenges",
            name: "Challenges",
            icon: <BiAbacus size="25"/>
        },
        {
            path: "/admin/quiz-categories",
            name: "Quizzes",
            icon: <MdOutlineQuiz size="25"/>
        },
        {
            path: "/admin/products",
            name: "Products",
            icon: <AiOutlineProduct size="25"/>
        },
        {
            path: "/admin/orders",
            name: "Orders",
            icon: <BsCart3 size="25"/>
        },
        {
            path: "/admin/pricing-plans",
            name: "Pricing Plans",
            icon: <IoPricetagsOutline size="25"/>
        },
        {
            path: "/admin/ads",
            name: "Ads",
            icon: <BiDialpadAlt size="25"/>
        },
        {
            path: "/admin/settings",
            name: "Settings",
            icon: <PiGear size="25"/>
        }

    ]

    const logout = () => {
        userAccountService.logout();
        navigate("/auth/login")
    }

    return (
        <div className="the-main-sidebar position-fixed top-0 start-0 d-none d-lg-block">
            <div className="h-100 d-flex flex-column px-3 pb-3 overflow-y-auto">
                <div className="sidebar-logo-holder mb-2">
                    <Link to="/home"
                          className="logo-holder d-flex align-items-center justify-content-center h-100">
                        <img src={mainLogo} alt="Primeband" className="logo-img"/>
                    </Link>
                </div>
                <div className="sidebar-links">
                    {
                        menuItem.map((item, index) => (
                            <div className="nav-link mt-2 list-unstyled align-items-center" key={index}>
                                <div className="menu-item">
                                    <NavLink to={item.path} key={index}
                                             className="nav-link-item rounded-3 py-3 px-2 d-flex align-items-center">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            {item.icon}
                                        </div>
                                        <div className="nav-text">{item.name}</div>
                                    </NavLink>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="mt-auto">
                    <div className="nav-link list-unstyled align-items-center">
                        <div className="menu-item logout-button">
                            <button type="button" onClick={logout}
                                    className="nav-link-item rounded-3 py-3 px-2 d-flex align-items-center border-0 bg-transparent">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <GoSignOut size="25"/>
                                </div>
                                <div className="nav-text">Logout</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Sidebar;