import {PayPalButtons} from "@paypal/react-paypal-js";
import {userAccountService, userOrderService, userSubscriptionService} from "../../_services";
import {useLocation, useNavigate} from "react-router-dom";

const PaypalPaymentButton = ({product_id, product_name, price, type}) => {
    let navigate = useNavigate();
    let location = useLocation();

    const createOrder = (data) => {
        return fetch("https://api.holylane.io/payments/paypal/create-paypal-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-token': userAccountService.getToken()
            },
            body: JSON.stringify({
                product:
                    {
                        description: product_name,
                        price: price,
                    },
            }),
        })
            .then((response) => response.json())
            .then((order) => order.id);
    };

    const onApprove = (data) => {
        const ORDER_ID = data.orderID;
        // Order is captured on the server and the response is returned to the browser
        return fetch(`https://api.holylane.io/payments/paypal/capture-paypal-order/${ORDER_ID}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                return response.json();
            })
            .then(async data => {
                if (data.status === "COMPLETED") {
                    if (type === "subscription") {
                        const subscriptionData = {
                            subscription_id: ORDER_ID,
                            plan_id: product_id,
                            total: price,
                            payment_method: "paypal",
                            status: "paid",
                        };
                        await userSubscriptionService.createSubscription(subscriptionData)
                            .then((res) => {
                                if (res.data.status === 200) {
                                    userAccountService.saveToken(res.data.jwt);
                                    localStorage.setItem('HOLYLANE_SUBSCRIPTION_ID', ORDER_ID);
                                    const redirectTo = location.state?.from || "/my-profile";
                                    localStorage.setItem('HOLYLANE_REDIRECTION_PATH', redirectTo);
                                    navigate('/thank-you-s', {replace: true});
                                }
                            })
                    } else {
                        const orderData = {
                            order_id: ORDER_ID,
                            product_id: product_id,
                            total: price,
                            payment_method: "paypal",
                            status: "paid",
                        }
                        await userOrderService.createOrder(orderData);
                        localStorage.setItem('HOLYLANE_ORDER_ID', ORDER_ID);
                        navigate('/thank-you', {replace: true});
                    }
                }
            });
    };

    return (
        <div className="custom-paypal-pay-button">
            <PayPalButtons
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
                style={{
                    layout: "horizontal",
                    shape: "rect",
                    color: "white",
                    label: "pay",
                    borderRadius: 5,
                    tagline: false
                }}
            />
        </div>
    );
}

export default PaypalPaymentButton;