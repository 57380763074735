import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {publicChallengeService} from "../../../_services";
import {Link} from "react-router-dom";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import defaultImage from "../../../assets/images/global/default_image.jpg";
import {MdWorkspacePremium} from "react-icons/md";

const UserChallengesSection = () => {
    const {t, i18n} = useTranslation();
    const [userChallenges, setUserChallenges] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserChallenges = async () => {
            try {
                setLoading(true);
                const data = await publicChallengeService.getUserChallenges();
                setUserChallenges(data);
            } catch (error) {
                console.error("Error fetching user challenges:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserChallenges();
    }, []);

    const getTitle = (userChallenge) => {
        return i18n.language.startsWith('fr') ? userChallenge.title_fr : userChallenge.title_en;
    };

    let baseURL = 'https://api.holylane.io/';

    const calculateProgress = (day_number, challenge_days_number) => {
        if (!challenge_days_number || challenge_days_number === 0) return 0;
        return Math.min(100, (day_number / challenge_days_number) * 100);
    };

    return (
        <>
            {loading ? (
                <>
                    <div className="my-challenges-section pt-3 px-3">
                        <div className="title fw-bold-600 mb-3">
                            My Challenges
                        </div>
                    </div>
                    <div className="w-100 overflow-x-scroll px-3 pb-3">
                        <div style={{height: "100px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "100px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                        <div style={{height: "100px"}}
                             className="rounded-3 mb-3 main-loading-effect"/>
                    </div>
                </>
            ) : (
                userChallenges.length > 0 && (
                    <>
                        <div className="my-challenges-section pt-3 px-3">
                            <div className="title fw-bold-600 mb-3">
                                My Challenges
                            </div>
                        </div>
                        <div className="my-challenges-text-holder d-flex align-items-center pb-3 px-3">
                            <div className="w-100">
                                {userChallenges.map((challenge) => (
                                    <Link to={`/challenges/challenge/${challenge.id}`}
                                          style={{width: "160px", height: "100px"}} key={challenge.id}
                                          className="border border-light-subtle shadow-sm rounded-3 small me-2 d-block w-100 p-2">
                                        <div className="row align-items-center mx-0 p-1">
                                            <div className="col-8 p-0 h-100">
                                                <div className="info-holder d-flex flex-column h-100">
                                                    <div
                                                        className="challenge-title fw-bold-600 small black-text-color two-lines-limit">
                                                        {getTitle(challenge)}
                                                    </div>
                                                    <div className="mt-auto">
                                                        <div
                                                            className="challenge-duration text-real-small gray-text-color">
                                                            {`${challenge.days_num} ${t('day')}${challenge.days_num > 1 ? 's' : ''}`}
                                                        </div>
                                                        {
                                                            challenge.day_number === 0 ? (
                                                                <div
                                                                    className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                                    {t('progressZero')}
                                                                </div>
                                                            ) : challenge.day_number >= challenge.days_num ? (
                                                                <div
                                                                    className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                                    {t('progressCompleted')}
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="challenge-progress text-real-small d-flex align-items-center main-text-dark-color">
                                                                    <div className="me-1">{t('progress')}</div>
                                                                    <div
                                                                        className="small-circular-progress text-center">
                                                                        <CircularProgressbar
                                                                            value={calculateProgress(challenge.day_number, challenge.days_num)}
                                                                            text={`${calculateProgress(challenge.day_number, challenge.days_num).toFixed()}%`}
                                                                            styles={buildStyles({
                                                                                textSize: '25px',
                                                                                pathColor: '#482f9f',
                                                                                textColor: '#1F1345',
                                                                                trailColor: '#aaa4fe',
                                                                                backgroundColor: '#3e98c7',
                                                                            })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 p-0 h-100">
                                                <div className="image-holder h-100 position-relative">
                                                    {challenge.image ? (
                                                        <img
                                                            src={`${baseURL}thedss/images/challenges/${challenge.image}`}
                                                            className="border h-100 w-100 rounded-end-2"
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <img src={defaultImage} alt=""
                                                             className="border h-100 w-100 rounded-end-2"/>
                                                    )}
                                                    {challenge.is_premium === 1 && (
                                                        <div className="position-absolute top-0 end-0 me-1 mt-1">
                                                            <div className="d-flex align-items-center justify-content-center main-background-premium-color text-white rounded-circle p-1">
                                                                <MdWorkspacePremium size="15"/>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </>
                )
            )}
        </>
    );
};

export default UserChallengesSection;