import {ChallengeDetails, ChallengeDescription, Footer, Header} from "./Public";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {userAccountService, publicChallengeService} from "../../_services";

const Challenge = () => {
    useEffect(() => {
        document.title = "Holylane - Challenge";
    }, []);

    let {chid} = useParams();
    let [loginStatus, setLoginStatus] = useState(false);
    const [userChallenges, setUserChallenges] = useState([]);
    const [userChallengeDetails, setUserChallengeDetails] = useState({});
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (userAccountService.isLogged()) {
            setLoginStatus(true);
            const fetchUserChallenges = async () => {
                try {
                    const data = await publicChallengeService.getUserChallenges();
                    setUserChallenges(data);
                } catch (error) {
                    console.error("Error fetching user challenges:", error);
                }
            };

            fetchUserChallenges();
        }
    }, [refresh]);

    useEffect(() => {
        if (userChallenges.length > 0) {
            let specificChallenge = getSpecificChallenge(userChallenges, chid);
            if (specificChallenge.length > 0) {
                setUserChallengeDetails(specificChallenge[0]);
            }
        }
    }, [userChallenges, chid, refresh]);

    function getSpecificChallenge(userChallenges, challengeId) {
        return userChallenges.filter(challenge => challenge.challenge_id.toString() === challengeId);
    }

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="main-challenge-page home-container">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                <div className="bible-story-content bottom-padding">
                    {
                        loginStatus ? (
                            <ChallengeDetails
                                challenge_id={chid}
                                userChallengeDetails={userChallengeDetails || false}
                                setRefresh={setRefresh}
                            />
                        ) : (
                            <ChallengeDescription challenge_id={chid}/>
                        )
                    }
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default Challenge;