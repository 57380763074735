import Axios from "../ServiceCaller";

let createSubscription = (orderData) => {
    return Axios.post('/user-subscriptions', orderData);
};

let getSubscriptionById = async (id) => {
    const {data} = await Axios.get(`/user-subscriptions/${id}`);
    return data.Subscription;
};

let verifySubscriptionTransaction = async (transactionId) => {
    const {data} = await Axios.get(`/payments/tranzak/verify-subscription-transaction/${transactionId}`);
    return data.Response;
};

export const userSubscriptionService = {
    createSubscription,
    getSubscriptionById,
    verifySubscriptionTransaction
};