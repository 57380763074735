import Axios from "../ServiceCaller";

// Settings
let getAdSettings = async () => {
    const {data} = await Axios.get("admin-ads/settings");
    return data.AdSettings;
};
let updateAdSettings = async (adSettingsData) => {
    const {data} = await Axios.put("admin-ads/settings", adSettingsData);
    return data;
};

// Ads
let getAds = async () => {
    const {data} = await Axios.get('/admin-ads/ads');
    return data.Ads;
};
let createAd = async (adData) => {
    const {data} = await Axios.post("/admin-ads/ads", adData);
    return data;
};
let getAdById = async (id) => {
    const {data} = await Axios.get(`/admin-ads/ads/${id}`);
    return data.Ad;
};
let updateAd = async (adData) => {
    const {data} = await Axios.put("/admin-ads/ads", adData);
    return data;
};
let deleteAd = async (adId) => {
    try {
        const response = await Axios.delete(`/admin-ads/ads`, {
            data: {id: adId},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Ad: ", error);
        throw error;
    }
};

export const adminAdService = {
    // Settings
    getAdSettings,
    updateAdSettings,
    // Ads
    getAds,
    createAd,
    getAdById,
    updateAd,
    deleteAd,
};