import React, {useEffect, useState} from 'react';
import {adminOrderService} from "../../../_services";
import OrdersRow from "./OrdersRow";

const OrdersFiltered = (props) => {
    const {searchQuery} = props;
    const [ordersData, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminOrderService.getOrders();
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching Orders data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(ordersData);
            return;
        }

        const filtered = ordersData.filter((order) =>
            Object.values(order).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, ordersData]);


    return (
        <div className="orders-page bg-white py-3 px-1 px-sm-3 rounded-3">
            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                    Orders List
                </div>
            </div>
            <div className="bg-white p-sm-2 p-lg-3 rounded-4 mb-3 table-responsive">
                <table className="table table-sm">
                    <thead>
                    <tr className="text-nowrap small">
                        <td className="text-black-50">Product</td>
                        <td className="text-black-50">Customer</td>
                        <td className="text-black-50">Email</td>
                        <td className="text-black-50">Amount</td>
                        <td className="text-black-50">Payment Method</td>
                        <td className="text-black-50">Status</td>
                        <td className="text-black-50 text-end">Created</td>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((order) => (
                            <OrdersRow
                                key={order.id}
                                order_id={order.id}
                                product={order.title_en}
                                customer={order.full_name}
                                email={order.customer_email}
                                amount={order.amount}
                                payment={order.payment_method}
                                status={order.status}
                                created_date={order.created_date}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrdersFiltered;