import defaultBackground from "../../../assets/images/global/default_image.jpg";
import {Link} from "react-router-dom";
import {TbArrowBackUp} from "react-icons/tb";
import {useEffect, useState} from "react";
import {publicBibleStoryService} from "../../../_services";
import {useTranslation} from "react-i18next";
import {MdWorkspacePremium} from "react-icons/md";

const BibleStoryDetails = ({bibleStoryId, setAccessible}) => {
    const {t, i18n} = useTranslation();
    const [bibleStoryData, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicBibleStoryService.getBibleStoryById(bibleStoryId);
                setData(data);
                setAccessible(data.accessible);
            } catch (error) {
                console.error("Error fetching Bible Story data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getTitle = (bibleStory) => {
        return i18n.language.startsWith('fr') ? bibleStory.title_fr : bibleStory.title_en;
    };

    const getCategoryName = (category) => {
        return i18n.language.startsWith('fr') ? category.name_fr : category.name_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <>
            {loading ? (
                <div className="w-100">
                    <div style={{height: "225px"}}
                         className="rounded-2 main-loading-effect position-relative">
                        <div className="back-btn-holder position-absolute pt-2 ps-2">
                            <Link to="/bible-stories">
                                <TbArrowBackUp size="35" className="main-text-dark-color"/>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                bibleStoryData && (
                    <div className="bible-story-details-box background-image-center"
                         style={{
                             backgroundImage: `url(${bibleStoryData.image ? `${baseURL}thedss/images/bible-stories/${bibleStoryData.image}` : defaultBackground})`
                         }}>
                        <div className="content position-relative d-flex flex-column" style={{height: "225px"}}>
                            <div className="back-btn-holder position-absolute pt-2 ps-2">
                                <Link to="/bible-stories">
                                    <TbArrowBackUp size="35" className="main-text-dark-color"/>
                                </Link>
                            </div>
                            <div className="current-episode-info mt-auto ps-3 pt-2 pb-3"
                                 style={{backgroundImage: 'linear-gradient(25deg, #ffffff, transparent)'}}>
                                <div
                                    className="title black-text-color fw-bold small mb-1">{getTitle(bibleStoryData)}</div>
                                <div
                                    className="category gray-text-color fw-bold-500 text-real-small">{getCategoryName(bibleStoryData)}</div>
                            </div>
                            {bibleStoryData.is_premium === 1 && (
                                <div className="position-absolute top-100 end-0 me-4 translate-middle-y">
                                    <div
                                        className="d-flex align-items-center text-white light-shadow ps-1 pe-2 py-1 rounded-3 main-background-premium-color">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <MdWorkspacePremium size="25"/>
                                        </div>
                                        <div className="ms-1 small fw-bold-600">
                                            Premium
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )
            )}
        </>
    );
};

export default BibleStoryDetails;