import {useEffect, useState} from "react";
import {userAccountService, userSettingService} from "../../_services";
import CreateAccount from "./CreateAccount";
import PaymentMethods from "./PaymentMethods";

const GetReadyToPay = ({product_id, product_name, price, type}) => {
    const [loginStatus, setLoginStatus] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [clientId, setClientId] = useState('');
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({
        full_name: '',
        email: '',
    });

    useEffect(() => {
        if (userAccountService.isLogged()) {
            setLoginStatus(true);
            const fetchUserData = async () => {
                try {
                    setLoading(true);
                    const data = await userAccountService.getUserData();
                    const data1 = await userSettingService.getPaypalClientId();
                    setUserDetails({
                        full_name: data.full_name,
                        email: data.email,
                    });
                    setClientId(data1.client_id)
                } catch (error) {
                    console.error("Error fetching user data:", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchUserData();
        }
    }, [refresh]);

    return (
        <div className="book-content-holder bottom-padding p-3">
            {
                loginStatus ? (
                    loading ? (
                        <div/>
                    ) : (
                        <PaymentMethods
                            client_id={clientId}
                            product_id={product_id}
                            product_name={product_name}
                            price={price}
                            full_name={userDetails.full_name}
                            email={userDetails.email}
                            type={type}
                        />
                    )
                ) : (
                    <CreateAccount
                        userDetails={userDetails}
                        setUserDetails={setUserDetails}
                        setRefresh={setRefresh}
                    />
                )
            }
        </div>
    );
};

export default GetReadyToPay;