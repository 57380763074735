import {publicChallengeService} from "../../../_services";
import {useTranslation} from "react-i18next";

const ChallengeActions = ({challenge_id, setRefresh, lastCompletedDay, status, completed}) => {
    const {t, i18n} = useTranslation();

    const updateUserChallenge = () => {
        let nextCompletedDay = lastCompletedDay + 1;
        publicChallengeService.updateUserChallenge(challenge_id, nextCompletedDay)
            .then(res => {
                if (res.data.status === 200) {
                    setRefresh(prev => !prev);
                }
            })
            .catch(error => console.log(error))
    };

    return (
        <div className="start-challenge text-center">
            {
                completed ? (
                    <div className="main-text-dark-color small">
                        {t('challengeCompleted')}
                    </div>
                ) : (
                    status === 'completed' ? (
                        <div className="main-text-dark-color small">
                        {t('dayCompleted')}
                        </div>
                    ) : status === 'ready' ? (
                            <button
                                className="border-0 main-hover-background-color text-white small fw-bold-500 py-2 px-5 rounded-2"
                                onClick={updateUserChallenge}>
                                {t('markItDone')}
                            </button>
                        ) :
                        null
                )
            }
        </div>
    );
};

export default ChallengeActions;