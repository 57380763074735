import React, {useEffect, useState} from "react";
import {userAccountService, userOrderService} from "../../_services";
import {useTranslation} from "react-i18next";
import defaultImage from "../../assets/images/global/default_image.jpg";

const OrderSuccessData = ({order_id}) => {
    const {t, i18n} = useTranslation();
    const [orderData, setOrderData] = useState('');
    const [loading, setLoading] = useState(true);
    const [productId, setProductId] = useState(0);
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                setLoading(true);
                const data = await userOrderService.getOrderById(order_id);
                setOrderData(data);
                setProductId(data.product_id)
                setFileName(data.pdf_file)
            } catch (error) {
                console.error("Error fetching product data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchOrders();
    }, []);

    const downloadProduct = async () => {
        const response = await fetch(`https://api.holylane.io/user-products/download/${productId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                'x-token': userAccountService.getToken(),
            },
        });

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const fileExtension = fileName.split('.').pop();
            const pdf_name = getColumnValue(orderData, 'title').trim();
            a.download = `_HOLYLANE_${pdf_name}.${fileExtension}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } else {
            const errorMessage = await response.json();
            console.error('Download failed:', errorMessage.message);
        }
    };

    const getColumnValue = (product, column) => {
        return i18n.language.startsWith('fr') ? product[`${column}_fr`] : product[`${column}_en`];
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="p-3">
            {loading ? (
                <div></div>
            ) : (
                orderData && (
                    <div>
                        <div className="horizontal-line mx-auto"/>
                        <div className="gray-text-color small mt-4 ps-4">{t('yourSummary')}</div>
                        <div
                            className="product-box d-flex w-75 mx-auto mt-3 bg-light-subtle p-1 light-shadow rounded-3">
                            <div className="image-holder">
                                {orderData.image ? (
                                    <img
                                        src={`${baseURL}thedss/images/products/${orderData.image}`}
                                        className="rounded-2 mx-auto h-auto"
                                        width="80"
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        src={defaultImage}
                                        className="rounded-2 mx-auto h-auto"
                                        width="80"
                                        alt=""
                                    />
                                )}
                            </div>
                            <div className="flex-grow-1 px-2 pt-1">
                                <div className="fw-bold-600 limit black-text-color two-lines-limit lh-sm mb-1">
                                    {getColumnValue(orderData, 'title')}
                                </div>
                                <div className="small gray-text-color two-lines-limit">
                                    {getColumnValue(orderData, 'name')}
                                </div>
                            </div>
                        </div>
                        <div className="text-center fw-bold-600 mt-4">
                            {t('totalToBePaid')}
                        </div>
                        <div className="text-center fw-bold main-text-dark-color fs-5 mt-1">
                            ${parseFloat(orderData.amount).toFixed(2)}
                        </div>
                        <div className="download-btn-holder text-center py-4">
                            <button className="main-hover-background-color text-white border-0 py-3 px-5 rounded-3"
                                    onClick={downloadProduct}>
                                {t('downloadCta')}
                            </button>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default OrderSuccessData;