import {useEffect, useId, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {userOrderService} from "../../_services";
import {useQuery} from "react-query";
import {FaCheck} from "react-icons/fa6";
import {Footer, Header, OrderSuccessData} from "./Public";
import {useTranslation} from "react-i18next";

const ThankYou = () => {
    useEffect(() => {
        document.title = "Holylane - Thank You";
    }, []);

    const {t, i18n} = useTranslation();
    let randomID = useId();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const transactionId = queryParams.get('transactionId');
    const navigate = useNavigate();
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [caseType, setCaseType] = useState('');
    const [orderId, setOrderId] = useState('');

    useEffect(() => {
        const storedOrderId = localStorage.getItem('HOLYLANE_ORDER_ID');

        if (transactionId) {
            setCaseType('url');
        } else if (storedOrderId) {
            setCaseType('localStorage');
            setOrderId(storedOrderId)
            setOrderSuccess(true);
        } else {
            navigate('/', {replace: true});
        }
    }, [transactionId]);

    const verifyTransaction = () => {
        return userOrderService.verifyTransaction(transactionId).then(res => {
            if (res && res.status === 'order_status_success') {
                setOrderId(transactionId)
                setOrderSuccess(true);
            } else {
                navigate('/');
            }
        });
    };

    useQuery(randomID, verifyTransaction, {
        enabled: caseType === 'url'
    });

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="main-bible-story-page home-container">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                {orderSuccess && (
                    <div className="thank-you-content bottom-padding">
                        <div className="text-center mt-4 px-3">
                            <div className="circle-holder mx-auto">
                                <div className="circle-1 rounded-circle p-4">
                                    <div className="circle-2 rounded-circle p-4">
                                        <div className="circle-3 rounded-circle p-4">
                                            <FaCheck size="40" color="#fff"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fw-bold text-center main-text-dark-color mt-3 mb-2">
                                {t('great')}
                            </div>
                            <div className="black-text-color fw-bold-500 lh-sm mb-2">
                                {t('paymentConfirmed1')}<br/>{t('paymentConfirmed2')}
                            </div>
                            <div className="gray-text-color small">
                                {t('congratsDownload1')}<br/>{t('congratsDownload2')}
                            </div>
                        </div>
                        <OrderSuccessData
                            order_id={orderId}
                        />
                    </div>
                )}
                <Footer/>
            </div>
        </div>
    );
}

export default ThankYou;