import React, {useEffect, useState} from 'react';
import {PricingPlansForm, PricingPlansRow, Header} from "./Private";
import {adminPricingPlanService} from "../../_services";
import {Link} from "react-router-dom";
import {TbCategory2} from "react-icons/tb";

const PricingPlans = () => {
    const [pricingPlanData, setData] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [pricingPlanToEdit, setPricingPlanToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminPricingPlanService.getPricingPlans();
                setData(data);
            } catch (error) {
                console.error("Error fetching Pricing Plans data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditPricingPlan = (pricingPlan) => {
        setEditMode(true);
        setPricingPlanToEdit(pricingPlan);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="Pricing Plans"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="pricing-plans-page bg-white py-3 px-1 px-sm-3 rounded-3">
                    <div className="features-holder mb-3 text-center">
                        <Link to="/admin/pricing-plans/features"
                              className="link-as-button small rounded-1 py-2 px-3">
                            <TbCategory2 size="20"/>
                            <span className="ms-2">Manage Features</span>
                        </Link>
                    </div>
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                            Pricing Plans List
                        </div>
                        <div className="d-flex">
                            <div className="add-btn-holder">
                                <button
                                    className={`main-button-color border-0 small rounded-2 py-1 px-3 ${hidden ? "d-block" : "d-none"}`}
                                    type="button" disabled={!hidden}
                                    onClick={setFormHiddenAdd}>
                                    Add New
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <PricingPlansForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            pricingPlan={pricingPlanToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div
                        className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${hidden ? "d-block" : "d-none"}`}>
                        <table className="table table-sm">
                            <thead>
                            <tr className="text-nowrap small">
                                <td className="text-black-50">Title (En)</td>
                                <td className="text-black-50">Title (Fr)</td>
                                <td className="text-black-50">Period Type</td>
                                <td className="text-black-50">Period</td>
                                <td className="text-black-50 text-center">Price</td>
                                <td className="text-black-50 text-end">Created</td>
                                <td className="text-black-50 text-center">...</td>
                            </tr>
                            </thead>
                            <tbody>
                            {pricingPlanData.length > 0 ? (
                                pricingPlanData.map((pricingPlan) => (
                                    <PricingPlansRow
                                        key={pricingPlan.id}
                                        pricing_plan_id={pricingPlan.id}
                                        title_en={pricingPlan.title_en}
                                        title_fr={pricingPlan.title_fr}
                                        period_type={pricingPlan.period_type}
                                        period={pricingPlan.period}
                                        price={pricingPlan.price}
                                        is_featured={pricingPlan.is_featured}
                                        created_date={pricingPlan.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditPricingPlan={handleEditPricingPlan}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*End Page Content*/}
            </div>
        </>
    );
};

export default PricingPlans;