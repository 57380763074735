import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {publicPricingPlanService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";

const PricingPlansFeatures = () => {
    const {t, i18n} = useTranslation();
    const [pricingPlansFeatures, setPricingPlansFeatures] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPricingPlansFeatures = async () => {
            try {
                setLoading(true);
                const data = await publicPricingPlanService.getPricingPlansFeatures();
                setPricingPlansFeatures(data);
            } catch (error) {
                console.error("Error fetching pricing plans:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPricingPlansFeatures();
    }, []);

    const getTitle = (pricingPlan) => {
        return i18n.language.startsWith('fr') ? pricingPlan.title_fr : pricingPlan.title_en;
    };

    const getDescription = (pricingPlan) => {
        return i18n.language.startsWith('fr') ? pricingPlan.description_fr : pricingPlan.description_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="main-pricing-plans-features">
            <div className="gray-text-color opacity-75 fw-bold-600 small">
                Benefits includes:
            </div>
            {loading ? (
                <div>

                </div>
            ) : (
                <div className="pricing-features-holder mt-4">
                    {pricingPlansFeatures.map((pricingPlansFeature) => (
                        <div className="d-flex align-items-center feature-item mb-3" key={pricingPlansFeature.id}>
                            <div className="image-holder">
                                {pricingPlansFeature.image ? (
                                    <img
                                        src={`${baseURL}thedss/images/pricing-plans-features/${pricingPlansFeature.image}`}
                                        width="60"
                                        height="60"
                                        className="p-2"
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        src={defaultImage}
                                        width="60"
                                        height="60"
                                        className="border"
                                        alt=""
                                    />
                                )}
                            </div>
                            <div className="info-holder px-2">
                                <div className="feature-title fw-bold-600 black-text-color">
                                    {getTitle(pricingPlansFeature)}
                                </div>
                                <div className="gray-text-color small">
                                    {getDescription(pricingPlansFeature)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PricingPlansFeatures;