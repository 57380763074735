import React, {useEffect, useState} from "react";
import {adminPricingPlanService} from "../../../_services";

const PricingPlansForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        title_en: "",
        title_fr: "",
        period_type: "month",
        period: 1,
        price: "",
        is_featured: 0,
    });

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title_en: props.pricingPlan.title_en,
                title_fr: props.pricingPlan.title_fr,
                period_type: props.pricingPlan.period_type,
                period: props.pricingPlan.period,
                price: props.pricingPlan.price,
                is_featured: props.pricingPlan.is_featured,
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                title_en: "",
                title_fr: "",
                period_type: "month",
                period: 1,
                price: "",
                is_featured: 0,
            });
        }
    }, [props.editMode, props.pricingPlan]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("title_en", params.title_en);
            formData.append("title_fr", params.title_fr);
            formData.append("period_type", params.period_type);
            formData.append("period", params.period);
            formData.append("price", params.price);
            formData.append("is_featured", params.is_featured);

            if (isCreatingMode) {
                await adminPricingPlanService.createPricingPlan(formData);
            } else {
                formData.append("id", props.pricingPlan.pricing_plan_id);
                await adminPricingPlanService.updatePricingPlan(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                title_en: "",
                title_fr: "",
                period_type: "month",
                period: 1,
                price: "",
                is_featured: 0,
            });
        } catch (error) {
            console.error("Error Creating/Updating Pricing Plan:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            title_en: "",
            title_fr: "",
            period_type: "month",
            period: 1,
            price: "",
            is_featured: 0,
        });
        props.sendHiddenToParent(!props.hidden);
    }

    return (
        <div className="main-form-holder add-pricingPlan-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Add / Update a Pricing Plan</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="title_en">
                                    Title (English):
                                </label>
                                <div className="title-en-holder d-flex align-items-center">
                                    <div className="title-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Pricing Plan Title in English"
                                            value={params.title_en}
                                            onChange={onChange}
                                            name="title_en"
                                            id="title_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="title_fr">
                                    Title (French):
                                </label>
                                <div className="title-fr-holder d-flex align-items-center">
                                    <div className="title-fr-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="textarea form-control form-control-sm py-2"
                                            placeholder="Pricing Plan Title in French"
                                            value={params.title_fr}
                                            onChange={onChange}
                                            name="title_fr"
                                            id="title_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group d-none d-md-block">
                                <label className="mb-1 small fw-bold-500" htmlFor="period_type">
                                    Period Type:
                                </label>
                                <div className="period-type-holder d-flex align-items-center">
                                    <div className="period-type-input flex-grow-1">
                                        <select
                                            className="form-select form-select-sm py-2"
                                            value={params.period_type}
                                            onChange={onChange}
                                            name="period_type"
                                            id="period_type"
                                            required
                                        >
                                            <option value="month">Per month</option>
                                            <option value="year">Per year</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="period">
                                    Period:
                                </label>
                                <div className="period-holder d-flex align-items-center">
                                    <div className="period-input flex-grow-1">
                                        <input
                                            type="number"
                                            className="textarea form-control form-control-sm py-2"
                                            placeholder="Pricing Plan Title in French"
                                            value={params.period}
                                            onChange={onChange}
                                            name="period"
                                            id="period"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="price">
                                    Price:
                                </label>
                                <div className="price-holder d-flex align-items-center">
                                    <div className="price-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="textarea form-control form-control-sm py-2"
                                            placeholder="Pricing Plan Price"
                                            value={params.price}
                                            onChange={onChange}
                                            name="price"
                                            id="price"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="is_featured">
                                    Featured Plan:
                                </label>
                                <div className="is-featured-holder d-flex align-items-center">
                                    <div className="is-featured-input flex-grow-1">
                                        <select
                                            className="form-select form-select-sm py-2"
                                            value={params.is_featured}
                                            onChange={onChange}
                                            name="is_featured"
                                            id="is_featured"
                                            required
                                        >
                                            <option value="0">Not Featured</option>
                                            <option value="1">Featured</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PricingPlansForm;