import React, {useEffect, useState} from "react";
import {userSubscriptionService} from "../../_services";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {PricingPlansFeatures} from "../../pages/public/Public";

const SubscriptionSuccessData = ({subscription_id}) => {
    const {t, i18n} = useTranslation();
    const [subscriptionData, setSubscriptionData] = useState('');
    const [loading, setLoading] = useState(true);
    const [redirectData, setRedirectData] = useState("/my-profile");

    useEffect(() => {
        const redirectPath = localStorage.getItem('HOLYLANE_REDIRECTION_PATH');
        if (redirectPath) {
            setRedirectData(redirectPath)
        }
        const fetchSubscriptions = async () => {
            try {
                setLoading(true);
                const data = await userSubscriptionService.getSubscriptionById(subscription_id);
                setSubscriptionData(data);
            } catch (error) {
                console.error("Error fetching product data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchSubscriptions();
    }, []);

    const getColumnValue = (product, column) => {
        return i18n.language.startsWith('fr') ? product[`${column}_fr`] : product[`${column}_en`];
    };

    const formatDateTime = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short',
        };
        return new Date(dateString).toLocaleString(i18n.language, options);
    };

    return (
        <div className="p-3">
            {loading ? (
                <div></div>
            ) : (
                subscriptionData && (
                    <div>
                        <div className="horizontal-line mx-auto"/>
                        <div className="pt-3">
                            <PricingPlansFeatures/>
                        </div>
                        <div
                            className="px-3 mx-auto mt-3 bg-light-subtle p-1 light-shadow rounded-3">
                            <div className="flex-grow-1 px-2 pt-1">
                                <div className="fw-bold-600 limit black-text-color two-lines-limit lh-sm mb-1">
                                    {getColumnValue(subscriptionData, 'title')}
                                </div>
                                <div className="small gray-text-color">
                                    <div className="fw-bold-600">
                                        Subscription start:<br/>
                                        <span className="fw-normal">
                                        {formatDateTime(subscriptionData.subscription_start)}
                                       </span>
                                    </div>
                                    <div className="fw-bold-600">
                                        Subscription end:<br/>
                                        <span className="fw-normal">
                                        {formatDateTime(subscriptionData.subscription_end)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center fw-bold-600 mt-4">
                            {t('totalToBePaid')}
                        </div>
                        <div className="text-center fw-bold main-text-dark-color fs-5 mt-1">
                            ${parseFloat(subscriptionData.amount).toFixed(2)}
                        </div>
                        <div className="download-btn-holder text-center pt-3 pb-4">
                            <Link to={redirectData}
                                  className="main-hover-background-color text-white bsubscription-0 py-3 px-5 rounded-3 d-inline-block">
                                Browse the premium content
                            </Link>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default SubscriptionSuccessData;