import defaultUserPortrait from "../../../assets/images/global/default_user_portrait.png";
import React, {useEffect, useState} from "react";
import {userAccountService} from "../../../_services";
import {GoSignOut} from "react-icons/go";
import {useNavigate} from "react-router-dom";

const UserHeaderSection = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({
        full_name: '',
        email: '',
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const data = await userAccountService.getUserData();
                setUserDetails({
                    full_name: data.full_name,
                    email: data.email,
                });
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, []);

    const logout = () => {
        userAccountService.logout();
        navigate("/auth/login")
    }

    return (
        <div className="user-nav-holder">
            {loading ? (
                <div className="d-flex align-items-center p-3">
                    <div className="profile-img-holder rounded-3 main-loading-effect"
                         style={{width: "110px", height: "150px"}}>
                    </div>
                    <div className="user-infos-holder ms-3">
                        <div className="main-loading-effect mb-2 rounded-2" style={{height: "18px", width: "140px"}}></div>
                        <div className="main-loading-effect rounded-2" style={{height: "15px", width: "170px"}}></div>
                    </div>
                </div>
            ) : (
                <div className="d-flex align-items-center p-3">
                    <div className="profile-img-holder rounded-3"
                         style={{width: "110px", height: "150px"}}>
                        <img src={defaultUserPortrait} className="w-100 h-100 rounded-3" alt=""/>
                    </div>
                    <div className="user-infos-holder ms-3">
                        <div className="user-name-holder fw-bold-600 black-text-color">
                            {userDetails.full_name}
                        </div>
                        <div className="user-phone-holder text-real-small gray-text-color">
                            {userDetails.email}
                        </div>
                    </div>
                    <div className="align-self-start ms-auto">
                        <button type="button" onClick={logout}
                                className="d-flex logout-button align-items-center border-0 bg-transparent text-danger">
                            <div className="icon d-flex align-items-center justify-content-center me-1">
                                <GoSignOut size="20"/>
                            </div>
                            <div>Logout</div>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserHeaderSection;