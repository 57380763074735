import {GoPlusCircle} from "react-icons/go";
import {NavLink} from "react-router-dom";
import {TbMessages} from "react-icons/tb";
import {LuStore} from "react-icons/lu";
import {AiFillHome} from "react-icons/ai";
import {useTranslation} from "react-i18next";
import {MdOutlineQuiz} from "react-icons/md";

const Footer = () => {
    const {t, i18n} = useTranslation();

    const menuItem = [
        {
            path: "/",
            name: `${t('homeItem')}`,
            icon: <AiFillHome size="35"/>
        },
        {
            path: "/bible-stories",
            name: `${t('bibleStoriesItem')}`,
            icon: <TbMessages size="35"/>
        },
        {
            path: "/bible-quizzes",
            name: 'Bible Quizzes',
            icon: <MdOutlineQuiz size="35"/>,
        },
        {
            path: "/more",
            name: `${t('moreItem')}`,
            icon: <GoPlusCircle size="35"/>
        },
    ]

    return (
        <footer className="main-footer position-fixed bottom-0 d-flex align-items-center">
            <div className="bottom-navbar-links w-100 d-flex justify-content-between">
                {
                    menuItem.map((item, index) => (
                        <div className="nav-link" key={index}>
                            {item.external ? (
                                <a href={item.path} target="_blank" rel="noopener noreferrer" className="nav-link-item d-flex flex-column align-items-center py-2">
                                    <span className="icon">
                                        {item.icon}
                                    </span>
                                    <span className="nav-text">{item.name}</span>
                                </a>
                            ) : (
                                <NavLink to={item.path} className="nav-link-item d-flex flex-column align-items-center py-2">
                                    <span className="icon">
                                        {item.icon}
                                    </span>
                                    <span className="nav-text">{item.name}</span>
                                </NavLink>
                            )}
                        </div>
                    ))
                }
            </div>
        </footer>
    );
};

export default Footer;