import React, {useEffect, useState} from 'react';
import {BibleStoriesForm, BibleStoriesRow} from "../../../pages/private/Private";
import {adminBibleStoryService} from "../../../_services";
import {Link} from "react-router-dom";
import {TbCategory2} from "react-icons/tb";

const BibleStoriesFiltered = (props) => {
    const {category, searchQuery} = props;
    const [bibleStoryData, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [bibleStoryToEdit, setBibleStoryToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminBibleStoryService.getBibleStories(category);
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching Bible Story data:", error);
            }
        };

        fetchData();
    }, [refresh, category]);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(bibleStoryData);
            return;
        }

        const filtered = bibleStoryData.filter((bibleStory) =>
            Object.values(bibleStory).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, bibleStoryData]);

    const handleEditBibleStory = (bibleStory) => {
        setEditMode(true);
        setBibleStoryToEdit(bibleStory);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <div className="bible-stories-page bg-white py-3 px-1 px-sm-3 rounded-3">
            <div className="categories-holder mb-3 text-center">
                <Link to="/admin/bible-stories/categories"
                      className="link-as-button small rounded-1 py-2 px-3">
                    <TbCategory2 size="20"/>
                    <span className="ms-2">Manage Categories</span>
                </Link>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                    Bible Story List
                </div>
                <div className="d-flex">
                    <div className="add-btn-holder">
                        <button
                            className={`main-button-color border-0 small rounded-2 py-1 px-3 ${hidden ? "d-block" : "d-none"}`}
                            type="button" disabled={!hidden}
                            onClick={setFormHiddenAdd}>
                            Add New
                        </button>
                    </div>
                </div>
            </div>
            <div className={hidden ? "d-none" : "d-block"}>
                <BibleStoriesForm
                    sendRefreshToParent={setRefresh}
                    refresh={refresh}
                    editMode={editMode}
                    bibleStory={bibleStoryToEdit}
                    sendHiddenToParent={setHidden}
                    hidden={hidden}
                />
            </div>
            <div
                className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${hidden ? "d-block" : "d-none"}`}>
                <table className="table table-sm">
                    <thead>
                    <tr className="text-nowrap small">
                        <td className="text-black-50">Image</td>
                        <td className="text-black-50">Title (En)</td>
                        <td className="text-black-50">Title (Fr)</td>
                        <td className="text-black-50">Summary (En)</td>
                        <td className="text-black-50">Summary (Fr)</td>
                        <td className="text-black-50 text-center">Episodes</td>
                        <td className="text-black-50 text-center">Status</td>
                        <td className="text-black-50 text-center">Premium</td>
                        <td className="text-black-50 text-center">Category</td>
                        <td className="text-black-50 text-end">Created</td>
                        <td className="text-black-50 text-center">...</td>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((bibleStory) => (
                            <BibleStoriesRow
                                key={bibleStory.id}
                                bible_story_id={bibleStory.id}
                                image={bibleStory.image}
                                title_en={bibleStory.title_en}
                                title_fr={bibleStory.title_fr}
                                summary_en={bibleStory.summary_en}
                                summary_fr={bibleStory.summary_fr}
                                episodes_num={bibleStory.episodes_num}
                                is_featured={bibleStory.is_featured}
                                is_premium={bibleStory.is_premium}
                                category_id={bibleStory.category_id}
                                category_name_en={bibleStory.name_en}
                                created_date={bibleStory.created_date}
                                sendRefreshToParent={setRefresh}
                                refresh={refresh}
                                handleEditBibleStory={handleEditBibleStory}
                                sendHiddenToParent={setHidden}
                                hidden={hidden}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BibleStoriesFiltered;