import {useEffect, useRef, useState} from "react";
import {userAccountService, publicAdService} from "../../_services";
import DisplayAdContent from "./DisplayAdContent";
import {useLocation} from "react-router-dom";

const AD_FREQUENCY_DEFAULT = 60000;
const AD_CLOSE_DELAY_DEFAULT = 5000;

const DisplayAds = () => {
    const [loading, setLoading] = useState(true);
    const [loginStatus, setLoginStatus] = useState(false);
    const [premiumUser, setPremiumUser] = useState(false);
    const [adFrequency, setAdFrequency] = useState(AD_FREQUENCY_DEFAULT);
    const [adCloseDelay, setAdCloseDelay] = useState(AD_CLOSE_DELAY_DEFAULT);
    const location = useLocation();

    // const hiddenAdPaths = [
    //     "/",
    //     "/home",
    //     "/auth",
    //     "/admin",
    //     "/thank-you",
    //     "/thank-you-s",
    //     "/pricing-plans",
    //     "/my-profile",
    //     "/more",
    //     "/bookshelf",
    // ];

    const checkUserStatus = async () => {
        try {
            setLoading(true);
            const isLogged = userAccountService.isLogged();
            setLoginStatus(isLogged);

            if (isLogged) {
                const data = await userAccountService.isPremium();
                setPremiumUser(data === 'premium');
            }

            if (!isLogged || !premiumUser) {
                const adSettingsData = await publicAdService.getAdSettings();
                if (adSettingsData) {
                    setAdFrequency(adSettingsData.ad_frequency_minutes * 60 * 1000);
                    setAdCloseDelay(adSettingsData.ad_close_delay_seconds * 1000);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkUserStatus();
    }, []);

    if (loading) {
        return null;
    }

    // const shouldHideAds = hiddenAdPaths.some(path => location.pathname === path || location.pathname.startsWith(path + "/"));

    return (
        !loginStatus || !premiumUser ? (
            <DisplayAdContent
                adFrequency={adFrequency}
                adCloseDelay={adCloseDelay}
            />
        ) : null
    );
};

export default DisplayAds;