import React, {useEffect, useState} from 'react';
import {ChallengesForm, ChallengesRow} from "../../../pages/private/Private";
import {adminChallengeService} from "../../../_services";

const ChallengesFiltered = (props) => {
    const {searchQuery} = props;
    const [challengesData, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [challengeToEdit, setChallengeToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminChallengeService.getChallenges();
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching Challenges data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(challengesData);
            return;
        }

        const filtered = challengesData.filter((challenge) =>
            Object.values(challenge).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, challengesData]);

    const handleEditChallenge = (challenge) => {
        setEditMode(true);
        setChallengeToEdit(challenge);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <div className="challenges-page bg-white py-3 px-1 px-sm-3 rounded-3">
            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                    Challenges List
                </div>
                <div className="d-flex">
                    <div className="add-btn-holder">
                        <button
                            className={`main-button-color border-0 small rounded-2 py-1 px-3 ${hidden ? "d-block" : "d-none"}`}
                            type="button" disabled={!hidden}
                            onClick={setFormHiddenAdd}>
                            Add New
                        </button>
                    </div>
                </div>
            </div>
            <div className={hidden ? "d-none" : "d-block"}>
                <ChallengesForm
                    sendRefreshToParent={setRefresh}
                    refresh={refresh}
                    editMode={editMode}
                    challenge={challengeToEdit}
                    sendHiddenToParent={setHidden}
                    hidden={hidden}
                />
            </div>
            <div
                className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${hidden ? "d-block" : "d-none"}`}>
                <table className="table table-sm">
                    <thead>
                    <tr className="text-nowrap small">
                        <td className="text-black-50">Image</td>
                        <td className="text-black-50">Title (En)</td>
                        <td className="text-black-50">Title (Fr)</td>
                        <td className="text-black-50">Description (En)</td>
                        <td className="text-black-50">Description (Fr)</td>
                        <td className="text-black-50 text-center">Days</td>
                        <td className="text-black-50 text-center">Premium</td>
                        <td className="text-black-50 text-end">Created</td>
                        <td className="text-black-50 text-center">...</td>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((challenge) => (
                            <ChallengesRow
                                key={challenge.id}
                                challenge_id={challenge.id}
                                image={challenge.image}
                                title_en={challenge.title_en}
                                title_fr={challenge.title_fr}
                                description_en={challenge.description_en}
                                description_fr={challenge.description_fr}
                                days_num={challenge.days_num}
                                is_premium={challenge.is_premium}
                                created_date={challenge.created_date}
                                sendRefreshToParent={setRefresh}
                                refresh={refresh}
                                handleEditChallenge={handleEditChallenge}
                                sendHiddenToParent={setHidden}
                                hidden={hidden}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ChallengesFiltered;