import React, {useEffect, useState} from "react";
import {adminBibleStoryService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";

const BibleStoryEpisodesForm = (props) => {
    const bible_story_id = props.bible_story_id;
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        image: "",
        title_en: "",
        title_fr: "",
        duration_en: "",
        duration_fr: "",
        file_name_en: "",
        file_name_fr: "",
        is_free: 0,
    });

    const [image, setImage] = useState(null);
    const [audioEn, setAudioEn] = useState(null);
    const [audioFr, setAudioFr] = useState(null);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title_en: props.bibleStoryEpisode.title_en,
                title_fr: props.bibleStoryEpisode.title_fr,
                duration_en: props.bibleStoryEpisode.duration_en,
                duration_fr: props.bibleStoryEpisode.duration_fr,
                is_free: props.bibleStoryEpisode.is_free,
                image: props.bibleStoryEpisode.image,
                file_name_en: props.bibleStoryEpisode.file_name_en,
                file_name_fr: props.bibleStoryEpisode.file_name_fr
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                image: "",
                title_en: "",
                title_fr: "",
                duration_en: "",
                duration_fr: "",
                file_name_en: "",
                file_name_fr: "",
                is_free: 0,
            });
        }
    }, [props.editMode, props.bibleStoryEpisode]);

    function formatDuration(duration) {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = Math.floor(duration % 60);
        return `${hours > 0 ? `${hours}:` : ''}${hours > 0 ? minutes.toString().padStart(2, '0') : minutes}:${seconds.toString().padStart(2, '0')}`;
    }

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const onAudioEnChange = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            setAudioEn(file);
            getAudioDuration(file, "en");
        } else {
            setAudioEn(null);
            setParamsValues(prevState => ({
                ...prevState,
                duration_en: ""
            }));
        }
    };

    const onAudioFrChange = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            setAudioFr(file);
            getAudioDuration(file, "fr");
        } else {
            setAudioFr(null);
            setParamsValues(prevState => ({
                ...prevState,
                duration_fr: ""
            }));
        }
    };

    const getAudioDuration = (file, lang) => {
        const url = URL.createObjectURL(file);
        const audio = new Audio(url);
        audio.onloadedmetadata = () => {
            URL.revokeObjectURL(url);
            const formattedDuration = formatDuration(audio.duration);
            setParamsValues(prevState => ({
                ...prevState,
                [`duration_${lang}`]: formattedDuration
            }));
        };
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("bible_story_id", bible_story_id);
            formData.append("title_en", params.title_en);
            formData.append("title_fr", params.title_fr);
            formData.append("is_free", params.is_free);
            formData.append("duration_en", params.duration_en);
            formData.append("duration_fr", params.duration_fr);
            formData.append("image", image);
            formData.append("file_en", audioEn);
            formData.append("file_fr", audioFr);

            if (isCreatingMode) {
                await adminBibleStoryService.createBibleStoryEpisode(formData);
            } else {
                formData.append("id", props.bibleStoryEpisode.bibleStoryEpisode_id);
                await adminBibleStoryService.updateBibleStoryEpisode(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                image: "",
                title_en: "",
                title_fr: "",
                duration_en: "",
                duration_fr: "",
                file_name_en: "",
                file_name_fr: "",
                is_free: 0,
            });
            setImage(null);
            setAudioEn(null);
            setAudioFr(null);
        } catch (error) {
            console.error("Error Creating/Updating Bible Story Episode:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            image: "",
            title_en: "",
            title_fr: "",
            duration_en: "",
            duration_fr: "",
            file_name_en: "",
            file_name_fr: "",
            is_free: 0,
        });
        props.sendHiddenToParent(!props.hidden);
        setImage(null);
        setAudioEn(null);
        setAudioFr(null);
        document.getElementById("file_name_en").value = "";
        document.getElementById("file_name_fr").value = "";
    }

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="main-form-holder add-bibleStoryEpisode-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Add / Update a Bible Story Episode</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row m-0">
                        <div className={`mb-3 ${props.isPremiumBibleStory > 0 && 'col-6'}`}>
                            <div className="form-group text-center">
                                <label className="mb-1 text-black-50 small" htmlFor="selectedImage">
                                    Selected Image:
                                    <span className="text-black-50 text-real-small"> (Square Images)</span>
                                </label>
                                <div className="selected-image-holder mx-auto border rounded-2 rounded-bottom-0"
                                     style={{width: "fit-content"}}>
                                    <div className="selected-image mx-auto rounded-2 rounded-bottom-0"
                                         style={{width: "fit-content"}}>
                                        {image ? (
                                            <img
                                                height="200"
                                                width="200"
                                                
                                                src={URL.createObjectURL(image)}
                                                alt="Selected Image For Bible Story Episode"
                                                className="rounded-2 rounded-bottom-0"
                                            />
                                        ) : params.image ? (
                                            <img
                                                height="200"
                                                width="200"
                                                
                                                src={`${baseURL}thedss/images/bible-story-episodes/${params.image}`}
                                                alt="Current Image For Bible Story Episode"
                                                className="rounded-2 rounded-bottom-0"
                                            />
                                        ) : (
                                            <img
                                                height="200"
                                                width="200"
                                                
                                                src={defaultImage}
                                                alt="Default Image For Bible Story Episode"
                                                className="rounded-2 rounded-bottom-0"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="upload-btn-holder">
                                    <label className="mb-1" htmlFor="image">
                                        <div style={{width: "200px"}}
                                             className="link-as-button px-3 py-2 rounded-top-0 rounded-2 mb-1 small">
                                            Upload Image
                                        </div>
                                    </label>
                                    <div className="image-holder d-flex align-items-center">
                                        <div className="image-input flex-grow-1">
                                            <input
                                                type="file"
                                                className="form-control py-2 d-none"
                                                onChange={onImageChange}
                                                name="image"
                                                id="image"
                                                accept="image/*"
                                                required={isCreatingMode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {props.isPremiumBibleStory > 0 && (
                            <div className="col-md-6 mb-3">
                                <div className="form-group">
                                    <label className="mb-1 small fw-bold-500" htmlFor="is_free">
                                        Is Free Episode:
                                    </label>
                                    <div className="is-free-holder d-flex align-items-center">
                                        <div className="is-free-input flex-grow-1">
                                            <select
                                                className="form-select form-select-sm py-2"
                                                value={params.is_free}
                                                onChange={onChange}
                                                name="is_free"
                                                id="is_free"
                                                required
                                            >
                                                <option value="0">Not Free Episode</option>
                                                <option value="1">Free Episode</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row p-0 m-0">
                            <div className="form-group col-md-6 mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="file_name_en">Audio File
                                    (English):</label>
                                <input
                                    style={{height: "25px"}}
                                    type="file"
                                    className="form-control form-control-sm text"
                                    id="file_name_en"
                                    name="file_name_en"
                                    onChange={onAudioEnChange}
                                    accept="audio/mpeg"
                                    required={isCreatingMode}
                                />
                            </div>
                            <div className="form-group col-md-6 mb-3">
                                <label className="mb-1 small fw-bold-500">Duration (English):</label>
                                <input
                                    style={{height: "25px"}}
                                    type="text"
                                    className="form-control form-control-sm text py-2"
                                    value={params.duration_en}
                                    readOnly
                                    required
                                />
                            </div>
                        </div>
                        <div className="row p-0 m-0">
                            <div className="form-group col-md-6 mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="file_name_fr">Audio File
                                    (French):</label>
                                <input
                                    style={{height: "25px"}}
                                    type="file"
                                    className="form-control form-control-sm text"
                                    id="file_name_fr"
                                    name="file_name_fr"
                                    onChange={onAudioFrChange}
                                    accept="audio/mpeg"
                                    required={isCreatingMode}
                                />
                            </div>
                            <div className="form-group col-md-6 mb-3">
                                <label className="mb-1 small fw-bold-500">Duration (French):</label>
                                <input
                                    style={{height: "25px"}}
                                    type="text"
                                    className="form-control form-control-sm text py-2"
                                    value={params.duration_fr}
                                    readOnly
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="title_en">
                                    Title (English):
                                </label>
                                <div className="title-en-holder d-flex align-items-center">
                                    <div className="title-en-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            type="text"
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Episode Title (English)"
                                            value={params.title_en}
                                            onChange={onChange}
                                            name="title_en"
                                            id="title_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="title_fr">
                                    Title (French):
                                </label>
                                <div className="title-fr-holder d-flex align-items-center">
                                    <div className="title-fr-input flex-grow-1">
                                        <input
                                            style={{height: "25px"}}
                                            type="text"
                                            className="form-control form-control-sm text py-2"
                                            placeholder="Episode Title (French)"
                                            value={params.title_fr}
                                            onChange={onChange}
                                            name="title_fr"
                                            id="title_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BibleStoryEpisodesForm;