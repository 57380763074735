import {useState} from "react";
import {adminUserService} from "../../../_services";
import {BiEdit, BiTrash} from "react-icons/bi";
import {Button, Modal} from "react-bootstrap";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    let yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
};

const UsersRow = (props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleDeleteUser = async () => {
        setIsDeleting(true);

        try {
            await adminUserService.deleteUser(props.user_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting user:", error);
        } finally {
            setIsDeleting(false);
            setShowConfirmModal(false);
        }
    };

    // const handleEditUser = () => {
    //     props.handleEditUser(props);
    //     props.sendHiddenToParent(!props.hidden);
    // };

    return (
        <>
            {props.user_id > 0 ? (
                <tr className="text-nowrap small">
                    <td>
                        {props.full_name && (`${props.full_name.substring(0, 25)}${
                            props.full_name.length > 25 ? "..." : ""
                        }`)}
                    </td>
                    <td>
                        {`${props.email.substring(0, 25)}${
                            props.email.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.status.substring(0, 25)}${
                            props.status.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td className="text-center">
                        {props.subscription || ""}
                    </td>
                    <td>
                        <span className="text-black-50">
                          {props.subscription_start ? getDate(props.subscription_start) : ""}
                        </span>
                    </td>
                    <td>
                        <span className="text-black-50">
                          {props.subscription_end ? getDate(props.subscription_end) : ""}
                        </span>
                    </td>
                    <td className="text-end">
                        <span className="text-black-50">
                          {props.created_date ? getDate(props.created_date) : ""}
                        </span>
                    </td>
                    <td className="text-center">
                        <span className="text-center">
                          {/*<button*/}
                            {/*    className="border-0 bg-transparent"*/}
                            {/*    onClick={handleEditUser}*/}
                            {/*    disabled={!props.hidden}*/}
                            {/*>*/}
                            {/*  <BiEdit size="22" className="edit-icon"/>*/}
                            {/*</button>*/}
                            <button
                                className="border-0 bg-transparent"
                                onClick={() => setShowConfirmModal(true)}
                            >
                            <BiTrash size="22" className="remove-icon mx-auto"/>
                          </button>
                        </span>
                    </td>
                </tr>
            ) : (
                <tr>
                    <td colSpan="8" className="text-center">
                        There is no user yet
                    </td>
                </tr>
            )}
            {/* Confirmation Modal */}
            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this user?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmModal(false)}
                        disabled={isDeleting}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleDeleteUser}
                        disabled={isDeleting}
                    >
                        {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UsersRow;
