import React, {useEffect, useState, useRef} from 'react';
import {publicBibleStoryService} from "../../../_services";
import defaultBackground from "../../../assets/images/global/default_image.jpg";
import {FaPlay, FaPause, FaStepBackward, FaStepForward} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import UpgradeModal from "../global/UpgradeModal";
import PublicBibleStoryPlayer from "./PublicBibleStoryPlayer";

const PublicBibleStoryEpisodes = ({bibleStoryId, accessible}) => {
    const {t, i18n} = useTranslation();
    const [bibleStoryEpisodesData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentEpisodeId, setCurrentEpisodeId] = useState(null);
    const [playing, setPlaying] = useState(false);  // Controls playback in both components
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicBibleStoryService.getBibleStoryEpisodes(bibleStoryId);
                setData(data);
                if (data.length > 0) {
                    setCurrentEpisodeId(data[0].id); // Load the first episode by default
                }
            } catch (error) {
                console.error("Error fetching Bible Story Episodes data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [bibleStoryId]);

    const getTitle = (episode) => (i18n.language.startsWith('fr') ? episode.title_fr : episode.title_en);
    const getDuration = (episode) => (i18n.language.startsWith('fr') ? episode.duration_fr : episode.duration_en);
    const getFileName = (episode) => (i18n.language.startsWith('fr') ? episode.file_name_fr : episode.file_name_en);

    const togglePlay = (id) => {
        if (currentEpisodeId === id) {
            setPlaying(!playing);  // Toggle play/pause for the current episode
        } else {
            setCurrentEpisodeId(id);
            setPlaying(true);      // Play the newly selected episode
        }
    };

    const handleNext = () => {
        const currentIndex = bibleStoryEpisodesData.findIndex((e) => e.id === currentEpisodeId);
        if (currentIndex < bibleStoryEpisodesData.length - 1) {
            const nextEpisode = bibleStoryEpisodesData[currentIndex + 1];
            if (accessible || nextEpisode.is_free === 1) {
                setCurrentEpisodeId(nextEpisode.id);
                setPlaying(true);
            } else {
                handleShow();  // Show upgrade modal if the next episode is not accessible
            }
        }
    };

    const handlePrevious = () => {
        const currentIndex = bibleStoryEpisodesData.findIndex((e) => e.id === currentEpisodeId);
        if (currentIndex > 0) {
            setCurrentEpisodeId(bibleStoryEpisodesData[currentIndex - 1].id);
            setPlaying(true);
        }
    };

    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);

    const baseURL = 'https://api.holylane.io/';

    return (
        <div className="episodes-section gray-bg-color rounded-top-4 p-3 pt-4">
            {loading ? (
                <div className="fw-bold black-text-color mb-3">
                    {/* Loading Skeleton */}
                </div>
            ) : (
                <>
                    <div className="fw-bold black-text-color mb-3 small">{t('episodes')}</div>
                    {currentEpisodeId && (
                        <div className="react-player-container mb-3">
                            <PublicBibleStoryPlayer
                                episodes={bibleStoryEpisodesData}
                                currentEpisodeId={currentEpisodeId}
                                playing={playing}
                                setPlaying={setPlaying}
                                onNext={handleNext}
                                onPrevious={handlePrevious}
                            />
                        </div>
                    )}
                    <div className="episodes-holder">
                        {bibleStoryEpisodesData.map((episode) => (
                            <div key={episode.id} className="light-shadow bg-white rounded-2 episode-item mb-3">
                                <div className="d-flex align-items-center position-relative">
                                    {(!accessible && episode.is_free === 1) && (
                                        <div
                                            className="text-real-small position-absolute top-0 end-0 translate-middle-y main-light-background-color px-1 rounded-3 me-2 text-white">
                                            Free to play
                                        </div>
                                    )}
                                    <div className="image-holder me-3">
                                        <img
                                            src={episode.image ? `${baseURL}thedss/images/bible-story-episodes/${episode.image}` : defaultBackground}
                                            alt="episode"
                                            width="80"
                                            height="80"
                                        />
                                    </div>
                                    <div className="episode-info mb-auto pt-2 small">
                                        <div className="black-text-color fw-bold-600 mb-1">{getTitle(episode)}</div>
                                        <div className="gray-text-color text-real-small">{getDuration(episode)}</div>
                                    </div>
                                    <UpgradeModal show={modalShow} handleClose={handleClose}
                                                  message="Want to Upgrade now!"/>
                                    <div className="episode-play-status ms-auto pe-3">
                                        {(accessible || episode.is_free === 1) && currentEpisodeId === episode.id && playing ? (
                                            <div className="music-bars" onClick={() => togglePlay(episode.id)}>
                                                {[...Array(5)].map((_, i) => (
                                                    <span key={i} className="bar"/>
                                                ))}
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    if (accessible || episode.is_free === 1) {
                                                        togglePlay(episode.id);
                                                    } else {
                                                        handleShow();
                                                    }
                                                }}
                                                className="play-btn-holder main-background-color p-2 rounded-circle d-flex align-items-center justify-content-center"
                                            >
                                                {currentEpisodeId === episode.id && playing ? (
                                                    <FaPause size="20" className="text-white"/>
                                                ) : (
                                                    <FaPlay size="20" className="text-white ps-1"/>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default PublicBibleStoryEpisodes;
