import {useEffect, useId, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {userAccountService, userSubscriptionService} from "../../_services";
import {useQuery} from "react-query";
import {FaCheck} from "react-icons/fa6";
import {Footer, Header, SubscriptionSuccessData} from "./Public";
import {useTranslation} from "react-i18next";

const ThankYouSubscription = () => {
    useEffect(() => {
        document.title = "Holylane - Thank You";
    }, []);

    const {t, i18n} = useTranslation();
    let randomID = useId();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const transactionId = queryParams.get('transactionId');
    const navigate = useNavigate();
    const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
    const [caseType, setCaseType] = useState('');
    const [subscriptionId, setSubscriptionId] = useState('');

    useEffect(() => {
        const storedSubscriptionId = localStorage.getItem('HOLYLANE_SUBSCRIPTION_ID');

        if (transactionId) {
            setCaseType('url');
        } else if (storedSubscriptionId) {
            setCaseType('localStorage');
            setSubscriptionId(storedSubscriptionId)
            setSubscriptionSuccess(true);
        } else {
            navigate('/', {replace: true});
        }
    }, [transactionId]);

    const verifySubscriptionTransaction = () => {
        return userSubscriptionService.verifySubscriptionTransaction(transactionId).then(res => {
            if (res && res.status === 'subscription_status_success') {
                setSubscriptionId(transactionId)
                setSubscriptionSuccess(true);
                userAccountService.saveToken(res.jwt);
            } else {
                navigate('/');
            }
        });
    };

    useQuery(randomID, verifySubscriptionTransaction, {
        enabled: caseType === 'url'
    });

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="thank-you-subscription-page home-container">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                {subscriptionSuccess && (
                    <div className="thank-you-content bottom-padding">
                        <div className="text-center mt-3 px-3">
                            <div className="circle-holder mx-auto">
                                <div className="circle-1 rounded-circle p-3">
                                    <div className="circle-2 rounded-circle p-3">
                                        <div className="circle-3 rounded-circle p-3">
                                            <FaCheck size="25" color="#fff"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fw-bold text-center main-text-dark-color mt-3 mb-2">
                                {t('great')}
                            </div>
                            <div className="black-text-color fw-bold-500 lh-sm mb-2">
                                {t('paymentConfirmed1')}<br/>{t('paymentConfirmed2')}
                            </div>
                            <div className="gray-text-color small">
                                {t('congratsDownload1')}
                            </div>
                            <div className="gray-text-color small mt-1">
                                You are now a <span className="fw-bold main-text-dark-color">Premium</span> member
                            </div>
                        </div>
                        <SubscriptionSuccessData
                            subscription_id={subscriptionId}
                        />
                    </div>
                )}
                <Footer/>
            </div>
        </div>
    );
}

export default ThankYouSubscription;