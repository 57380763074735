import {userOrderService, userSubscriptionService} from "../../_services";
import tranzakLogo from "../../assets/images/global/tranzak_logo.png";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const CreditCardForm = ({product_id, price, product_name, type}) => {
    const {t, i18n} = useTranslation();
    let location = useLocation();

    const productData = {
        product: {
            amount: price,
            currencyCode: "USD",
            paymentMethod: 'card',
            description: product_name,
            payerNote: `Holylane ${type === 'subscription' ? 'Subscription' : 'Order'}`,
            cancelUrl: "",
            returnUrl: `https://holylane.io/thank-you${type === 'subscription' ? '-s' : ''}`,
        }
    }

    const handlePayClickToPay = () => {
        userOrderService.PayOrderWithTranzak(productData)
            .then(async res => {
                if (res.status === 200) {
                    if (res.data.data) {
                        if (type === "subscription") {
                            const subscriptionData = {
                                subscription_id: res.data.data.requestId,
                                plan_id: product_id,
                                total: price,
                                payment_method: "tranzak",
                                status: "not_paid",
                            };
                            await userSubscriptionService.createSubscription(subscriptionData);
                            if (res.data.data.requestId) {
                                const redirectTo = location.state?.from || "/my-profile";
                                localStorage.setItem('HOLYLANE_REDIRECTION_PATH', redirectTo);
                                window.location.href = res.data.data.links.paymentAuthUrl
                            }
                        } else {
                            const orderData = {
                                order_id: res.data.data.requestId,
                                product_id: product_id,
                                total: price,
                                payment_method: "tranzak",
                                status: "not_paid",
                            }
                            await userOrderService.createOrder(orderData);
                            if (res.data.data.requestId) {
                                window.location.href = res.data.data.links.paymentAuthUrl
                            }
                        }
                    }
                }
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="pay-button">
            <button type="button"
                    onClick={() => handlePayClickToPay()}
                    className="pay-btn w-100 my-4">
                <span className="fw-light d-inline-block small fw-bold-500 me-2">
                    {t('payMobileWallet')}
                </span>
                <img src={tranzakLogo} className="tranzak-logo" alt=""/>
            </button>
        </div>
    );
}

export default CreditCardForm;