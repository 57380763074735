import {BiEdit, BiTrash} from "react-icons/bi";
import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {adminAdService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    let yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
};

const AdsRow = (props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleDeleteAd = async () => {
        setIsDeleting(true);

        try {
            await adminAdService.deleteAd(props.ad_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting ad:", error);
        } finally {
            setIsDeleting(false);
            setShowConfirmModal(false);
        }
    };

    const handleEditAd = () => {
        props.handleEditAd(props);
        props.sendHiddenToParent(!props.hidden);
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <>
            {props.ad_id > 0 ? (
                <tr className="text-nowrap small" style={{lineHeight: "50px"}}>
                    <td>
                        <span>
                            {props.image ? (
                                <img
                                    height="50"
                                    width="100"
                                    
                                    src={`${baseURL}thedss/images/ads/${props.image}`}
                                    alt="Current Image For Ad"
                                    className="rounded-2"
                                />
                            ) : (
                                <img
                                    height="50"
                                    width="100"
                                    
                                    src={defaultImage}
                                    alt="Default Image For Ad"
                                    className="rounded-2"
                                />
                            )}
                        </span>
                    </td>
                    <td>
                        {`${props.title.substring(0, 25)}${
                            props.title.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {props.type}
                    </td>
                    <td>
                        {`${props.link.substring(0, 25)}${
                            props.link.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td className="text-end">
                        <span className="text-black-50">
                          {props.created_date ? getDate(props.created_date) : ""}
                        </span>
                    </td>
                    <td className="text-center">
                        <span className="text-center">
                          <button
                              className="border-0 bg-transparent"
                              onClick={handleEditAd}
                              disabled={!props.hidden}
                          >
                            <BiEdit size="22" className="edit-icon"/>
                          </button>
                          <button
                              className="border-0 bg-transparent"
                              onClick={() => setShowConfirmModal(true)}
                          >
                            <BiTrash size="22" className="remove-icon mx-auto"/>
                          </button>
                        </span>
                    </td>
                </tr>
            ) : (
                <tr>
                    <td colSpan="11" className="text-center">
                        There is no Ad yet
                    </td>
                </tr>
            )}

            {/* Confirmation Modal */}
            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this ad?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmModal(false)}
                        disabled={isDeleting}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleDeleteAd}
                        disabled={isDeleting}
                    >
                        {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AdsRow;
