import Axios from "../ServiceCaller";

let getChallenges = async () => {
    const {data} = await Axios.get('/public-challenges');
    return data.Challenges;
};
let getChallengeById = async (id) => {
    const {data} = await Axios.get(`/public-challenges/${id}`);
    return data.Challenge;
};
let getUserChallenges = async () => {
    const {data} = await Axios.get('/public-challenges/user/challenges');
    return data.Challenges;
};
let getChallengeDayDetails = async (challenge_id, day_number) => {
    const {data} = await Axios.get(`/public-challenges/day/${challenge_id}/${day_number}`);
    return data.ChallengeDay;
};

let startChallengeForUser = (challengeId) => {
    return Axios.post(`/public-challenges/user/start`, {'id': challengeId});
};

let updateUserChallenge = (challengeId, nextCompletedDay) => {
    return Axios.post(`/public-challenges/user/update`,
        {
            'id': challengeId,
            'day': nextCompletedDay,
        });
};

export const publicChallengeService = {
    getChallenges,
    getChallengeById,
    getUserChallenges,
    getChallengeDayDetails,
    startChallengeForUser,
    updateUserChallenge,
};