import axios from "axios";
import {userAccountService} from "./user/UserAccountService";

const Axios = axios.create({
    baseURL: 'https://api.holylane.io/',
    // baseURL: 'http://localhost:3006/'0,
    // baseURL: 'http://192.168.0.122:3006/',
});

// Interceptor for the token
Axios.interceptors.request.use(request => {
    if (userAccountService.isLogged()) {
        request.headers.Authorization = 'Bearer ' + userAccountService.getToken();
        request.headers =
            {
                'x-token': userAccountService.getToken()
            }
    }
    return request;
});

export default Axios;