import Axios from "../ServiceCaller";

let getProducts = async (category) => {
    const {data} = await Axios.get(`/public-products/cat/${category}`);
    return data.Products;
};

let getProductById = async (id) => {
    const {data} = await Axios.get(`/public-products/product/${id}`);
    return data.Product;
};

let getProductCategories = async () => {
    const {data} = await Axios.get("/public-products/categories");
    return data.ProductCategories;
};

let getSpecialProducts = async () => {
    const {data} = await Axios.get("/public-products/special");
    return data.Products;
};

let getRecentProducts = async () => {
    const {data} = await Axios.get("/public-products/recent");
    return data.Products;
};

export const publicProductService = {
    getProducts,
    getProductById,
    getProductCategories,
    getSpecialProducts,
    getRecentProducts,
};