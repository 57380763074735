import Axios from "../ServiceCaller";

let completeUserQuiz = (score) => {
    return Axios.post('/user-quizzes/complete-quiz', {score: score});
};

let getUserRankData = async () => {
    const {data} = await Axios.get('/user-quizzes/user-rank-data');
    return data.User;
};

export const userQuizService = {
    completeUserQuiz,
    getUserRankData,
};