import Axios from "../ServiceCaller";

let getPricingPlans = async () => {
    const {data} = await Axios.get(`/admin-pricing-plans`);
    return data.PricingPlans;
};
let createPricingPlan = async (pricingPlanData) => {
    const {data} = await Axios.post("/admin-pricing-plans", pricingPlanData);
    return data;
};
let getPricingPlanById = async (id) => {
    const {data} = await Axios.get(`/admin-pricing-plans/${id}`);
    return data.PricingPlan;
};
let updatePricingPlan = async (pricingPlanData) => {
    const {data} = await Axios.put("/admin-pricing-plans", pricingPlanData);
    return data;
};
let deletePricingPlan = async (pricingPlanId) => {
    try {
        const response = await Axios.delete(`/admin-pricing-plans`, {
            data: {id: pricingPlanId},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Bible Story: ", error);
        throw error;
    }
};

let getPricingPlansFeatures = async () => {
    const {data} = await Axios.get("/admin-pricing-plans/features");
    return data.PricingPlansFeatures;
};
let createPricingPlansFeature = async (pricingPlansFeatureData) => {
    const {data} = await Axios.post("/admin-pricing-plans/features", pricingPlansFeatureData);
    return data;
};
let getPricingPlansFeatureById = async (id) => {
    const {data} = await Axios.get(`/admin-pricing-plans/features/${id}`);
    return data.PricingPlansFeature;
};
let updatePricingPlansFeature = async (pricingPlansFeatureData) => {
    const {data} = await Axios.put("/admin-pricing-plans/features", pricingPlansFeatureData);
    return data;
};
let deletePricingPlansFeature = async (pricingPlansFeatureId) => {
    try {
        const response = await Axios.delete(`/admin-pricing-plans/features`, {
            data: {id: pricingPlansFeatureId},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Bible Story Category:", error);
        throw error;
    }
};

export const adminPricingPlanService = {
    getPricingPlans,
    createPricingPlan,
    getPricingPlanById,
    updatePricingPlan,
    deletePricingPlan,
    getPricingPlansFeatures,
    createPricingPlansFeature,
    getPricingPlansFeatureById,
    updatePricingPlansFeature,
    deletePricingPlansFeature
};