import {QuizCategoriesForm, QuizCategoriesRow, Header} from "./Private";
import React, {useEffect, useState} from "react";
import {adminQuizService} from "../../_services";

const QuizCategories = () => {
    const [quizCategoriesData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [quizCategoryToEdit, setQuizCategoryToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminQuizService.getQuizCategories();
                setData(data);
            } catch (error) {
                console.error("Error fetching Quiz Categories data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleEditQuizCategories = (quiz_category) => {
        setEditMode(true);
        setQuizCategoryToEdit(quiz_category);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <>
            <Header title="Quiz Categories"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="quiz-categories-page bg-white py-3 px-1 px-sm-3 rounded-3">
                    <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                        <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                            Quiz Categories List
                        </div>
                        <div className="add-btn-holder">
                            <button
                                className={`main-button-color border-0 small rounded-2 py-1 px-3 ${hidden ? "d-block" : "d-none"}`}
                                type="button" disabled={!hidden}
                                onClick={setFormHiddenAdd}>
                                Add New
                            </button>
                        </div>
                    </div>
                    <div className={hidden ? "d-none" : "d-block"}>
                        <QuizCategoriesForm
                            sendRefreshToParent={setRefresh}
                            refresh={refresh}
                            editMode={editMode}
                            quiz_categories={quizCategoryToEdit}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                        />
                    </div>
                    <div
                        className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${hidden ? "d-block" : "d-none"}`}>
                        <table className="table table-sm">
                            <thead>
                            <tr className="text-nowrap small">
                                <td className="text-black-50">Image</td>
                                <td className="text-black-50">Name (En)</td>
                                <td className="text-black-50">Name (Fr)</td>
                                <td className="text-black-50 text-center">Quizzes</td>
                                <td className="text-black-50 text-center">Color</td>
                                <td className="text-black-50 text-end">Created</td>
                                <td className="text-black-50 text-center">...</td>
                            </tr>
                            </thead>
                            <tbody>
                            {quizCategoriesData.length > 0 ? (
                                quizCategoriesData.map((quiz_categories) => (
                                    <QuizCategoriesRow
                                        key={quiz_categories.id}
                                        quiz_category_id={quiz_categories.id}
                                        image={quiz_categories.image}
                                        name_en={quiz_categories.name_en}
                                        name_fr={quiz_categories.name_fr}
                                        quizzes_number={quiz_categories.quizzes_number}
                                        color={quiz_categories.color}
                                        created_date={quiz_categories.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                        handleEditQuizCategories={handleEditQuizCategories}
                                        sendHiddenToParent={setHidden}
                                        hidden={hidden}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*End Page Content*/}
            </div>
        </>
    );
}

export default QuizCategories;