import {BiEdit, BiTrash} from "react-icons/bi";
import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {adminBibleStoryService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";
import {Link} from "react-router-dom";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    let yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
};

const BibleStoriesRow = (props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleDeleteBibleStory = async () => {
        setIsDeleting(true);

        try {
            await adminBibleStoryService.deleteBibleStory(props.bible_story_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting bibleStory:", error);
        } finally {
            setIsDeleting(false);
            setShowConfirmModal(false);
        }
    };

    const handleEditBibleStory = () => {
        props.handleEditBibleStory(props);
        props.sendHiddenToParent(!props.hidden);
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <>
            {props.bible_story_id > 0 ? (
                <tr className="text-nowrap small" style={{lineHeight: "50px"}}>
                    <td>
                        <span>
                            {props.image ? (
                                <img
                                    height="50"
                                    width="100"
                                    
                                    src={`${baseURL}thedss/images/bible-stories/${props.image}`}
                                    alt="Current Image For Bible Story"
                                    className="rounded-2"
                                />
                            ) : (
                                <img
                                    height="50"
                                    width="100"
                                    
                                    src={defaultImage}
                                    alt="Default Image For Bible Story"
                                    className="rounded-2"
                                />
                            )}
                        </span>
                    </td>
                    <td>
                        {`${props.title_en.substring(0, 25)}${
                            props.title_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.title_fr.substring(0, 25)}${
                            props.title_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.summary_en.substring(0, 25)}${
                            props.summary_en.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${props.summary_fr.substring(0, 25)}${
                            props.summary_fr.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td className="text-center">
                        <Link to={`/admin/bible-stories/episodes/${props.bible_story_id}`}
                              className="link-as-button py-1 px-2 rounded-2">
                            {`${props.episodes_num} Episode${props.episodes_num > 1 ? 's' : ''}`}
                        </Link>
                    </td>
                    <td className="text-center">
                        {props.is_featured ? 'Featured' : 'Not featured'}
                    </td>
                    <td className="text-center">
                        {props.is_premium ? 'Premium' : 'Not premium'}
                    </td>
                    <td className="text-center">
                        {props.category_name_en}
                    </td>
                    <td className="text-end">
                        <span className="text-black-50">
                          {props.created_date ? getDate(props.created_date) : ""}
                        </span>
                    </td>
                    <td className="text-center">
                        <span className="text-center">
                          <button
                              className="border-0 bg-transparent"
                              onClick={handleEditBibleStory}
                              disabled={!props.hidden}
                          >
                            <BiEdit size="22" className="edit-icon"/>
                          </button>
                          <button
                              className="border-0 bg-transparent"
                              onClick={() => setShowConfirmModal(true)}
                          >
                            <BiTrash size="22" className="remove-icon mx-auto"/>
                          </button>
                        </span>
                    </td>
                </tr>
            ) : (
                <tr>
                    <td colSpan="11" className="text-center">
                        There is no Bible story yet
                    </td>
                </tr>
            )}

            {/* Confirmation Modal */}
            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this bibleStory?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmModal(false)}
                        disabled={isDeleting}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleDeleteBibleStory}
                        disabled={isDeleting}
                    >
                        {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BibleStoriesRow;
