import React, {useState, useEffect} from "react";
import {adminQuizService} from "../../../_services";

const ImportQuestionsExcelForm = ({sendRefreshToParent, refresh, sendImportHiddenToParent, importHidden, quiz}) => {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage("");

        const formData = new FormData();
        formData.append("quiz_id", quiz);
        formData.append("file", file);

        adminQuizService.importQuestions(formData)
            .then((res) => {
                if (res && res.status && res.status === 400) {
                    setErrorMessage(res.message);
                    return;
                }
                sendRefreshToParent(!refresh);
                sendImportHiddenToParent(!importHidden);
            })
            .catch((error) => {
                console.error("Error importing questions:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const setFormHidden = () => {
        setFile(null);
        setErrorMessage("");
        sendImportHiddenToParent(!importHidden);
    }

    return (
        <div className="main-form-holder add-questions-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Import Questions from Excel</div>
            {errorMessage && (
                <div className="text-center text-danger fw-bold-600 my-3" role="alert">
                    {errorMessage}
                </div>
            )}
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="file">
                                    Excel File:
                                </label>
                                <input
                                    type="file"
                                    className="form-control form-control-sm py-2"
                                    onChange={handleFileChange}
                                    accept=".xlsx, .xls"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Importing..." : "Import"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ImportQuestionsExcelForm;