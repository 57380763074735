import {Navigate} from "react-router-dom";
import {userAccountService} from "../_services";

const IsLogged = ({children}) => {
    if (userAccountService.isLogged()) {
        return <Navigate to="/"/>;
    }
    return children;

};

export default IsLogged;