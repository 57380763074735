import Axios from "../ServiceCaller";

// Authentication
let checkOrSignup = (userDetails) => {
    return Axios.post('/auth/check-or-signup', userDetails);
}
let verifyCode = (userDetails) => {
    return Axios.post('/auth/verify-code', userDetails);
}
let completeSignup = (userDetails) => {
    return Axios.post('/auth/complete-signup', userDetails);
};
let saveToken = (token) => {
    localStorage.setItem('token_primeband', token);
};
let getToken = () => {
    return localStorage.getItem('token_primeband');
};
let logout = () => {
    localStorage.removeItem('token_primeband');
};

// Access
let isLogged = () => {
    let token = localStorage.getItem('token_primeband');
    return !!token;
};
let isAdmin = async () => {
    const {data} = await Axios.get('/auth/is-admin');
    return data.User;
};
let isPremium = async () => {
    const {data} = await Axios.get('/auth/is-premium');
    return data.User;
};

// Data
let getUserData = async () => {
    const {data} = await Axios.get('/user-account/user-data');
    return data.User;
};
let getUserProducts = async () => {
    const {data} = await Axios.get('/user-products/products');
    return data.UserProducts;
};

export const userAccountService = {
    // Authentication
    checkOrSignup,
    verifyCode,
    completeSignup,
    saveToken,
    getToken,
    logout,
    // Access
    isLogged,
    isAdmin,
    isPremium,
    // Data
    getUserData,
    getUserProducts,
};