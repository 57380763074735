import Axios from "../ServiceCaller";

let getPaypalClientId = async () => {
    const { data } = await Axios.get("/user-settings/paypal/client-id");
    return data.ClientId;
};

let getStripePromiseKey = async () => {
    const { data } = await Axios.get("user-settings/stripe/promise-key");
    return data.PromiseKey;
};

export const userSettingService = {
    getStripePromiseKey,
    getPaypalClientId,
};