import {Navigate, useNavigate} from "react-router-dom";
import {userAccountService} from "../_services";
import {useEffect, useState} from "react";

const AuthGuard = ({children}) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isChecking, setIsChecking] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!userAccountService.isLogged()) {
                    navigate("/auth/login", {replace: true});
                    return;
                }

                const data = await userAccountService.isAdmin();
                if (data !== 'admin') {
                    navigate("/auth/login", {replace: true});
                } else {
                    setIsAdmin(true);
                }
            } catch (error) {
                console.error("Error checking login/admin status:", error);
                navigate("/auth/login", {replace: true});
            } finally {
                setIsChecking(false);
            }
        };

        fetchData();
    }, [navigate]);

    if (isChecking) {
        return null;
    }

    return isAdmin ? children : <Navigate to="/"/>;
};

export default AuthGuard;