import React, {useEffect, useState} from "react";
import {adminAdService} from "../../../_services";

const AdSettingsForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [params, setParamsValues] = useState({
        ad_frequency_minutes: 1,
        ad_close_delay_seconds: 10,
    });

    useEffect(() => {
        setParamsValues({
            ad_frequency_minutes: props.ad_frequency_minutes,
            ad_close_delay_seconds: props.ad_close_delay_seconds,
        });
    }, [props]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("ad_frequency_minutes", params.ad_frequency_minutes);
            formData.append("ad_close_delay_seconds", params.ad_close_delay_seconds);
            formData.append("settings_id", props.settings_id);
            await adminAdService.updateAdSettings(formData);
            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
        } catch (error) {
            console.error("Error Updating Ad Settings:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="main-form-holder bg-white light-shadow rounded-2 p-2 p-md-3">
            <div className="fw-bold-600 small text-center mb-3">Edit Ad Settings</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="form-group col-md-6 mb-3">
                            <label className="mb-1 small fw-bold-500" htmlFor="ad_frequency_minutes">
                                Ad Frequency (minutes):
                            </label>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm py-2"
                                        placeholder="Ad Frequency in Minutes"
                                        value={params.ad_frequency_minutes}
                                        onChange={onChange}
                                        name="ad_frequency_minutes"
                                        id="ad_frequency_minutes"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label className="mb-1 small fw-bold-500" htmlFor="ad_close_delay_seconds">
                                Ad Close Delay (Seconds):
                            </label>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm py-2"
                                        placeholder="Ad Close Delay In Seconds"
                                        value={params.ad_close_delay_seconds}
                                        onChange={onChange}
                                        name="ad_close_delay_seconds"
                                        id="ad_close_delay_seconds"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-3 mt-md-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AdSettingsForm;