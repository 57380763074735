import Axios from "../ServiceCaller";

let getProducts = async (category) => {
    const { data } = await Axios.get(`/admin-products/cat/${category}`);
    return data.Products;
};
let createProduct = async (productData) => {
    const { data } = await Axios.post("/admin-products", productData);
    return data;
};
let getProductById = async (id) => {
    const { data } = await Axios.get(`/admin-products/${id}`);
    return data.Product;
};
let updateProduct = async (productData) => {
    const { data } = await Axios.put("/admin-products", productData);
    return data;
};
let deleteProduct = async (productId) => {
    try {
        const response = await Axios.delete(`/admin-products`, {
            data: { id: productId },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Product: ", error);
        throw error;
    }
};

let getProductCategories = async () => {
    const { data } = await Axios.get("/admin-products/categories");
    return data.ProductCategories;
};
let createProductCategory = async (productCategoryData) => {
    const { data } = await Axios.post("/admin-products/categories", productCategoryData);
    return data;
};
let getProductCategoryById = async (id) => {
    const { data } = await Axios.get(`/admin-products/categories/${id}`);
    return data.ProductCategory;
};
let updateProductCategory = async (productCategoryData) => {
    const { data } = await Axios.put("/admin-products/categories", productCategoryData);
    return data;
};
let deleteProductCategory = async (productCategoryId) => {
    try {
        const response = await Axios.delete(`/admin-products/categories`, {
            data: { id: productCategoryId },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Product Category:", error);
        throw error;
    }
};

export const adminProductService = {
    getProducts,
    createProduct,
    getProductById,
    updateProduct,
    deleteProduct,
    getProductCategories,
    createProductCategory,
    getProductCategoryById,
    updateProductCategory,
    deleteProductCategory,
};