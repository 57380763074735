import {Footer, Header, BibleStoryCategories, RecentBibleStories, FeaturedBibleStories} from "./Public";
import {useEffect} from "react";

const BibleStories = () => {
    useEffect(() => {
        document.title = "Holylane - Bible Stories";
    }, []);

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="main-bible-stories-page home-container">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                <div className="bible-stories-content pb-5">
                    <FeaturedBibleStories/>
                    <div className="horizontal-line mx-auto"/>
                    <BibleStoryCategories/>
                    <div className="horizontal-line mx-auto"/>
                    <RecentBibleStories/>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default BibleStories;