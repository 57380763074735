import Modal from "react-bootstrap/Modal";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {BiLockOpen} from "react-icons/bi";
import {useEffect, useState} from "react";
import {publicPricingPlanService, userAccountService} from "../../../_services";

const UpgradeModal = ({show, handleClose, message}) => {
    const [loading, setLoading] = useState(true);
    const [planData, setPlanData] = useState('');
    const [loginStatus, setLoginStatus] = useState(false);

    let location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        if (userAccountService.isLogged()) {
            setLoginStatus(true)
        }
        const fetchPlanData = async () => {
            try {
                setLoading(true);
                const data = await publicPricingPlanService.getFeaturedPricingPlanData();
                setPlanData(data)
            } catch (error) {
                console.error("Error fetching plan data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchPlanData();
    }, []);

    useEffect(() => {
        if (show && !loginStatus) {
            navigate('/auth/login', {state: {from: location.pathname}});
        }
    }, [show, loginStatus, navigate, location.pathname]);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="py-3 px-2 upgrade-holder light-shadow rounded-3">
                    <div className="text-center fw-bold-600 main-text-dark-color mb-2">
                        Go Premium to Access this…
                    </div>
                    {loading ? (
                        <></>
                    ) : (
                        planData && (
                            <div className="gray-text-color text-center px-2 mb-3 small">
                                Get <span
                                className="main-text-dark-color">{planData.period} {planData.period_type}{planData.period > 1 ? 's' : ''}</span> of
                                unlimited access and No ads for
                                ${parseFloat(planData.price / planData.period).toFixed(2)} per {planData.period_type}
                            </div>
                        )
                    )}
                    {loginStatus ? (
                        <div>
                            <Link to="/pricing-plans"
                                  state={{from: location.pathname}}
                                  className="d-flex align-items-center justify-content-center link mx-auto rounded-2 py-1 px-2">
                                <div className="d-flex align-items-center justify-content-center px-1">
                                    <BiLockOpen size="18"/>
                                </div>
                                <div className="px-1 small fw-bold-500">Upgrade Now</div>
                            </Link>
                            <div className="upgrade-notice text-real-small mt-2 text-center gray-text-color">
                                This small fee helps us continue developing the project and delivering top quality
                                material.
                            </div>
                        </div>
                    ) : null}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default UpgradeModal;