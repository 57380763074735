import {Footer, Header, SpecialProducts, ProductsByCategory, RecentProducts} from "./Public";
import {useTranslation} from "react-i18next";
import bookshelfBg from "../../assets/images/global/bookshelf_bg.jpg";
import {useEffect, useState} from "react";
import {userAccountService, userSettingService} from "../../_services";

const Bookshelf = () => {
    useEffect(() => {
        document.title = "Holylane - Bookshelf";
    }, []);

    const {t, i18n} = useTranslation();
    const [fullName, setFullName] = useState('');
    useEffect(() => {
        if (userAccountService.isLogged()) {
            const fetchUserData = async () => {
                try {
                    const data = await userAccountService.getUserData();
                    setFullName(data.full_name);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };
            fetchUserData();
        }
    }, []);

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="main-bookshelf-page">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                <div className="products-sections-holder bottom-padding">
                    <div className="p-2">
                        <div className="background-image-center pt-3 rounded-4"
                             style={{backgroundImage: `url(${bookshelfBg})`, paddingBottom: "150px"}}>
                            <div>
                                <div className="text-center text-white fs-3 fw-bold-600">
                                    {t('hello')}{`${fullName ? `, ${fullName.replace(/^\w/, c => c.toUpperCase())}` : ''}`}!
                                </div>
                                <div className="text-white-50 text-center small">
                                    {t('whichBook1')}<br/>{t('whichBook2')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="" style={{marginTop: "-160px"}}>
                        <SpecialProducts/>
                    </div>
                    <div className="horizontal-line mx-auto"/>
                    <ProductsByCategory/>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default Bookshelf;