import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {userAccountService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";

const UserBooksSection = () => {
    const {t, i18n} = useTranslation();
    const [productsData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [productId, setProductId] = useState(0);
    const [productTitle, setProductTitle] = useState("");
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        const fetchUserProducts = async () => {
            try {
                setLoading(true);
                const data = await userAccountService.getUserProducts();
                setData(data);
            } catch (error) {
                console.error("Error fetching user products:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserProducts();
    }, []);

    const getTitle = (product) => {
        return i18n.language.startsWith('fr') ? product.title_fr : product.title_en;
    };
    const getCategory = (product) => {
        return i18n.language.startsWith('fr') ? product.name_fr : product.name_en;
    };

    const prepareToDownloadProduct = (product) => {
        setProductId(product.product_id);
        setProductTitle(getTitle(product));
        if (productId && productTitle) {
            downloadProduct();
        }
    }

    const downloadProduct = async () => {
        const response = await fetch(`https://api.holylane.io/user-products/download/${productId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                'x-token': userAccountService.getToken(),
            },
        });

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const fileExtension = fileName.split('.').pop();
            const pdf_name = productTitle.trim();
            a.download = `_HOLYLANE_${pdf_name}.${fileExtension}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } else {
            const errorMessage = await response.json();
            console.error('Download failed:', errorMessage.message);
        }
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="my-books-section pt-3">
            <div className="title fw-bold-600 mb-3">
                My Books
            </div>
            {loading ? (
                <div className="products-holder row m-0">
                    <div className="col-4 px-2">
                        <div style={{height: "200px"}}
                             className="w-100 rounded-4 main-loading-effect d-inline-block"/>
                    </div>
                    <div className="col-4 px-2">
                        <div style={{height: "200px"}}
                             className="w-100 rounded-4 main-loading-effect d-inline-block"/>
                    </div>
                    <div className="col-4 px-2">
                        <div style={{height: "200px"}}
                             className="w-100 rounded-4 main-loading-effect d-inline-block"/>
                    </div>
                </div>
            ) : (
                <div className="products-holder row m-0">
                    {productsData.length > 0 ? (
                            productsData.map((product) => (
                                <div className="col-4 mb-3" key={product.product_id}>
                                    <div className="product-box d-inline-block w-100">
                                        <div className="img-holder w-100">
                                            {product.image ? (
                                                <img
                                                    src={`${baseURL}thedss/images/products/${product.image}`}
                                                    className="w-100 rounded-1"
                                                    height="180"
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    src={defaultImage}
                                                    className="w-100 rounded-1"
                                                    height="180"
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                        <div
                                            className="title fw-bold-500 black-text-color small mt-2 px-2 one-line-limit text-center">
                                            {getTitle(product)}
                                        </div>
                                        <div
                                            className="title text-black-50 text-real-small fw-bold-500 px-3 one-line-limit text-center">
                                            {getCategory(product)}
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            onClick={() => prepareToDownloadProduct(product)}
                                            type="button"
                                            className="border-0 small py-1 main-hover-background-color text-white rounded-2 w-100">
                                            Download
                                        </button>
                                    </div>
                                </div>
                            )))
                        : (
                            <div className="small black-text-color">
                                You have no books yet, you can check <Link className="main-text-dark-color fw-bold-500"
                                                                           to="/bookshelf">Bookshelf</Link>
                            </div>
                        )
                    }
                </div>
            )}
        </div>
    );
};

export default UserBooksSection;