import Axios from "../ServiceCaller";

let getUsers = async () => {
    const {data} = await Axios.get("/admin-users");
    return data.Users;
};

let deleteUser = async (user_id) => {
    try {
        const response = await Axios.delete(`/admin-users`, {
            data: {id: user_id},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting User: ", error);
        throw error;
    }
};

export const adminUserService = {
    getUsers,
    deleteUser,
};