import React, {useEffect, useState} from "react";
import {adminPricingPlanService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";

const PricingPlansFeaturesForm = (props) => {
    const [isCreatingMode, setIsCreatingMode] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [params, setParamsValues] = useState({
        image: "",
        title_en: "",
        title_fr: "",
        description_en: "",
        description_fr: "",
    });

    const [image, setImage] = useState(null);

    useEffect(() => {
        if (props.editMode) {
            setIsCreatingMode(false);
            setParamsValues({
                title_en: props.pricingPlansFeature.title_en,
                title_fr: props.pricingPlansFeature.title_fr,
                description_en: props.pricingPlansFeature.description_en,
                description_fr: props.pricingPlansFeature.description_fr,
                image: props.pricingPlansFeature.image
            });
        } else {
            setIsCreatingMode(true);
            setParamsValues({
                image: "",
                title_en: "",
                title_fr: "",
                description_en: "",
                description_fr: "",
            });
        }
    }, [props.editMode, props.pricingPlansFeature]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("title_en", params.title_en);
            formData.append("title_fr", params.title_fr);
            formData.append("description_en", params.description_en);
            formData.append("description_fr", params.description_fr);
            formData.append("image", image);

            if (isCreatingMode) {
                await adminPricingPlanService.createPricingPlansFeature(formData);
            } else {
                formData.append("id", props.pricingPlansFeature.pricingPlansFeature_id);
                await adminPricingPlanService.updatePricingPlansFeature(formData);
            }

            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
            setParamsValues({
                image: "",
                title_en: "",
                title_fr: "",
                description_en: "",
                description_fr: "",
            });
            setImage(null);
        } catch (error) {
            console.error("Error Creating/Updating Pricing Plans Feature:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const setFormHidden = () => {
        setParamsValues({
            image: "",
            title_en: "",
            title_fr: "",
            description_en: "",
            description_fr: "",
        });
        props.sendHiddenToParent(!props.hidden);
        setImage(null);
    }

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="main-form-holder add-pricingPlansFeature-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="small fw-bold-500 text-center mb-4">Add / Update a Pricing Plans Feature</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-group text-center mx-auto" style={{width: '150px'}}>
                                <label className="mb-1 text-black-50 small" htmlFor="selectedImage">
                                    Selected Image:
                                    <span className="text-black-50 text-real-small"> (Square Icon)</span>
                                </label>
                                <div className="selected-image-holder border rounded-2 rounded-bottom-0">
                                    <div className="selected-image mx-auto rounded-2 rounded-bottom-0">
                                        {image ? (
                                            <img
                                                height="150"
                                                
                                                src={URL.createObjectURL(image)}
                                                alt="Selected Image For Pricing Plans Feature"
                                                className="w-100 rounded-2 rounded-bottom-0"
                                            />
                                        ) : params.image ? (
                                            <img
                                                height="150"
                                                
                                                src={`${baseURL}thedss/images/pricingPlansFeatures/${params.image}`}
                                                alt="Current Image For Pricing Plans Feature"
                                                className="w-100 rounded-2 rounded-bottom-0"
                                            />
                                        ) : (
                                            <img
                                                height="150"
                                                
                                                src={defaultImage}
                                                alt="Default Image For Pricing Plans Feature"
                                                className="w-100 rounded-2 rounded-bottom-0"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="upload-btn-holder">
                                    <label className="mb-1 w-100" htmlFor="image">
                                        <div
                                            className="link-as-button px-3 py-2 rounded-top-0 rounded-2 mb-1 w-100 small">Upload
                                            Image
                                        </div>
                                    </label>
                                    <div className="image-holder d-flex align-items-center">
                                        <div className="image-input flex-grow-1">
                                            <input
                                                type="file"
                                                className="form-control py-2 d-none"
                                                onChange={onImageChange}
                                                name="image"
                                                id="image"
                                                accept="image/*"
                                                required={isCreatingMode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="title_en">
                                    Title (English):
                                </label>
                                <div className="pricing-plans-feature-en-holder d-flex align-items-center">
                                    <div className="pricing-plans-feature-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Pricing Plans Feature Title in English"
                                            value={params.title_en}
                                            onChange={onChange}
                                            name="title_en"
                                            id="title_en"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="mb-1 small fw-bold-500" htmlFor="title_fr">
                                    Title (French):
                                </label>
                                <div className="pricing-plans-feature-en-holder d-flex align-items-center">
                                    <div className="pricing-plans-feature-en-input flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm py-2"
                                            placeholder="Pricing Plans Feature Title in French"
                                            value={params.title_fr}
                                            onChange={onChange}
                                            name="title_fr"
                                            id="title_fr"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="description_en">
                                    Description (English):
                                </label>
                                <div className="description-en-holder d-flex align-items-center">
                                    <div className="description-en-input flex-grow-1">
                                        <textarea
                                            className="form-control form-control-sm py-2"
                                            placeholder="Description text in English"
                                            value={params.description_en}
                                            onChange={onChange}
                                            name="description_en"
                                            id="description_en"
                                            required
                                            rows={3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label className="mb-1 small fw-bold-500" htmlFor="description_fr">
                                    Description (French):
                                </label>
                                <div className="description-en-holder d-flex align-items-center">
                                    <div className="description-en-input flex-grow-1">
                                        <textarea
                                            className="form-control form-control-sm py-2"
                                            placeholder="Description text in French"
                                            value={params.description_fr}
                                            onChange={onChange}
                                            name="description_fr"
                                            id="description_fr"
                                            required
                                            rows={3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PricingPlansFeaturesForm;