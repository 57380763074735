import {Footer, Header, QuizCategories} from "./Public";
import {useTranslation} from "react-i18next";
import bookshelfBg from "../../assets/images/global/bookshelf_bg.jpg";
import defaultUserImgSquare from "../../assets/images/global/default_user_portrait.png"
import defaultUserImg from "../../assets/images/global/default_user.png"
import {FcRating, FcRatings} from "react-icons/fc";
import {useEffect, useState} from "react";
import {userAccountService, userQuizService} from "../../_services";
import {useNavigate} from "react-router-dom";

const QuizHome = () => {
    let navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [userData, setUserData] = useState('');
    const [loading, setLoading] = useState(true);
    let [loginStatus, setLoginStatus] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const data = await userQuizService.getUserRankData();
                setUserData(data);
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoading(false);
            }
        };
        if (userAccountService.isLogged()) {
            setLoginStatus(true)
            fetchUserData();
        } else {
            setLoading(false);
        }
    }, []);

    function getRankSuffix(rank) {
        const remainder = rank % 10;
        const tens = Math.floor((rank % 100) / 10);

        if (tens === 1) return `${rank}th`;

        switch (remainder) {
            case 1:
                return `${rank}st`;
            case 2:
                return `${rank}nd`;
            case 3:
                return `${rank}rd`;
            default:
                return `${rank}th`;
        }
    }

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="all-bible-stories-page">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                {loading ? (
                    <div></div>
                ) : (
                    <>
                        <div className="user-ranking-holder">
                            <div className="p-2">
                                <div className="background-image-center pt-3 rounded-4 position-relative"
                                     style={{backgroundImage: `url(${bookshelfBg})`, paddingBottom: "250px"}}>
                                    <div>
                                        <div className="text-center text-white fs-3 fw-bold-600">
                                            Bible Quizzes
                                        </div>
                                    </div>
                                    <div className="position-absolute top-100 start-0 translate-middle-y w-100 px-3">
                                        <div className="rounded-3 bg-white light-shadow d-flex text-center">
                                            <div
                                                className="w-50 fw-bold-500 text-white main-light-background-color py-3 rounded-start-3">
                                                Rankings
                                            </div>
                                            <div className="w-50 fw-bold-500 black-text-color py-3 rounded-end-3">
                                                Leaderboard
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative" style={{marginTop: "-260px"}}>
                                <div className="px-4 py-3">
                                    <div
                                        className="p-1 rounded-3 one-line-limit bg-white mx-auto light-shadow position-relative"
                                        style={{width: "fit-content"}}>
                                        <img src={defaultUserImgSquare} alt="" width="70" height="70"
                                             className="rounded-3"/>
                                    </div>
                                    <div
                                        className="swiper-specials-holder rounded-4 bg-white rounded-4 light-shadow px-3 pb-3"
                                        style={{marginTop: "-39px", paddingTop: "39px"}}>
                                        <div
                                            className="name-holder fw-bold-600 text-center mt-2 main-text-dark-color mb-3">
                                            {loginStatus ? (
                                                userData.full_name
                                            ) : (
                                                'Guest User'
                                            )}
                                        </div>
                                        <div className="ranking-info d-flex align-items-center">
                                            <div
                                                className="d-flex align-items-center px-1 pe-4 me-2 border-end border-light-subtle">
                                                <div className="icon-holder me-1">
                                                    <FcRatings size="35"/>
                                                </div>
                                                <div className="points-info">
                                                    <div className="fw-bold-600 small main-text-dark-color">
                                                        {loginStatus ? (
                                                            userData.total_points > 0 ? (
                                                                `${getRankSuffix(userData.rank)} Rank`
                                                            ) : (
                                                                'Not ranked'
                                                            )
                                                        ) : (
                                                            'Not ranked'
                                                        )}
                                                    </div>
                                                    <div className="gray-text-color text-real-small">
                                                        My Ranking
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center px-1">
                                                <div className="icon-holder me-1">
                                                    <FcRating size="35"/>
                                                </div>
                                                <div className="points-info">
                                                    <div className="fw-bold-600 small main-text-dark-color">
                                                        {loginStatus ? (
                                                            `${userData.total_points} Points`
                                                        ) : (
                                                            '0 Points'
                                                        )}
                                                    </div>
                                                    <div className="gray-text-color text-real-small">
                                                        My Reward Points
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-padding px-3" style={{marginTop: "70px"}}>
                            <div className="fw-bold-600 pt-2 main-text-dark-color">
                                My Ranking
                            </div>
                            <div
                                className="user-rank mt-2 mb-3 d-flex align-items-center main-background-color text-white py-2 px-3 rounded-3">
                                <div className="rank small me-3">
                                    {userData.total_points > 0 ? (
                                        getRankSuffix(userData.rank)
                                    ) : (
                                        '0'
                                    )}
                                </div>
                                <div className="img-holder me-2">
                                    <img src={defaultUserImg} width="40" height="40"
                                         alt=""/>
                                </div>
                                <div className="fw-bold-500 small">
                                    {loginStatus ? (
                                        userData.full_name
                                    ) : (
                                        'Guest User'
                                    )}
                                </div>
                                <div className="lh-sm ms-auto text-end">
                                    <div>
                                        {loginStatus ? (
                                            `${userData.quizzes_taken}`
                                        ) : (
                                            '0'
                                        )}
                                    </div>
                                    <div className="text-real-small">
                                        Quizzes
                                    </div>
                                </div>
                            </div>
                            <div className="fw-bold-600 pt-2 main-text-dark-color">
                                Quiz Categories
                            </div>
                            <div className="quiz-categories-content mt-2">
                                <QuizCategories/>
                            </div>
                        </div>
                    </>
                )}
                <Footer/>
            </div>
        </div>
    );
};

export default QuizHome;