import {Routes, Route} from "react-router-dom";
import {
    PLayout,
    Error,
    Dashboard,
    Messages,
    MessageCategories,
    BibleStories,
    BibleStoryCategories,
    BibleStoryEpisodes,
    Challenges,
    ChallengeDays,
    Products,
    ProductCategories,
    Orders,
    Settings,
    PricingPlans,
    PricingPlansFeatures,
    Ads,
    QuizCategories,
    Quizzes,
    QuizQuestions,
    Users
} from "./Private";

const PrivateRouter = () => {
    return (
        <Routes>
            <Route element={<PLayout/>}>
                <Route index element={<Dashboard/>}/>
                <Route index path="/" element={<Dashboard/>}/>
                <Route index path="/dashboard" element={<Dashboard/>}/>
                <Route index path="/messages" element={<Messages/>}/>
                <Route index path="/messages/categories" element={<MessageCategories/>}/>
                <Route index path="/bible-stories" element={<BibleStories/>}/>
                <Route index path="/bible-stories/categories" element={<BibleStoryCategories/>}/>
                <Route index path="/bible-stories/episodes/:bsid" element={<BibleStoryEpisodes/>}/>
                <Route index path="/challenges" element={<Challenges/>}/>
                <Route index path="/challenges/:chid/days" element={<ChallengeDays/>}/>
                <Route index path="/products" element={<Products/>}/>
                <Route index path="/products/categories" element={<ProductCategories/>}/>
                <Route index path="/orders" element={<Orders/>}/>
                <Route index path="/settings" element={<Settings/>}/>
                <Route index path="/pricing-plans" element={<PricingPlans/>}/>
                <Route index path="/pricing-plans/features" element={<PricingPlansFeatures/>}/>
                <Route index path="/ads" element={<Ads/>}/>
                <Route index path="/quiz-categories" element={<QuizCategories/>}/>
                <Route index path="/quiz-categories/:qcid/quizzes" element={<Quizzes/>}/>
                <Route index path="/quiz-categories/quizzes/:qid/questions" element={<QuizQuestions/>}/>
                <Route index path="/users" element={<Users/>}/>
                <Route path="*" element={<Error/>}/>
            </Route>
        </Routes>
    );
}

export default PrivateRouter;