import {Footer, Header, UserNavSection} from "./Public";
import {useEffect, useState} from "react";
import {userAccountService} from "../../_services";
import defaultUser from "../../assets/images/global/default_user.png"
import {IoIosArrowForward} from "react-icons/io";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {GoSignOut} from "react-icons/go";
import {MdOutlineAmpStories, MdOutlineAutoStories, MdOutlineQuiz, MdOutlineWebStories} from "react-icons/md";
import {t} from "i18next";
import {BiCategoryAlt} from "react-icons/bi";
import {LuContact, LuStore} from "react-icons/lu";

const MorePageContent = () => {
    let location = useLocation();
    let [loginStatus, setLoginStatus] = useState(false);
    useEffect(() => {
        if (userAccountService.isLogged()) {
            setLoginStatus(true)
        }
    }, []);

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="all-bible-stories-page">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                {loginStatus ? (
                    <>
                        <UserNavSection/>
                        <div className="more-links-holder pt-3 bottom-padding px-3">
                            <div className="links-holder row m-0 text-center">
                                <div className="col-4 mb-3 px-2">
                                    <Link to="/bible-stories/all"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <MdOutlineAutoStories size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            {t('bibleStories')}
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-4 mb-3 px-2">
                                    <Link to="/bible-quizzes"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <MdOutlineQuiz size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            Bible Quizzes
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-4 mb-3 px-2">
                                    <Link to="/home"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <MdOutlineWebStories size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            Bible Verses
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-4 mb-3 px-2">
                                    <Link to="/home#challenges"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <BiCategoryAlt size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            Challenge Yourself
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-4 mb-3 px-2">
                                    <Link to="/bookshelf"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <LuStore size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            {t('bookShelfItem')}
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-4 mb-3 px-2">
                                    <Link to="#"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <LuContact size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            Contact
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="login-signup-holder">
                            <div className="d-flex align-items-center p-3">
                                <div className="profile-img-holder rounded-circle"
                                     style={{width: "60px", height: "60px"}}>
                                    <img src={defaultUser} className="w-100 h-100 rounded-circle" alt=""/>
                                </div>
                                <div className="auth-links-holder ms-3">
                                    <Link state={{from: location.pathname}}
                                          to="/auth/login"
                                          className="d-flex align-items-center small fw-bold-600 black-text-color">
                                        Sign in
                                        <span className="px-1">/</span>
                                        Register
                                        <button type="button"
                                                className="bg-transparent fw-bold-600 border-0 p-0 ms-1">
                                            <IoIosArrowForward size="16"/>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="horizontal-line mx-auto"/>
                        </div>
                        <div className="more-links-holder pt-3 bottom-padding px-3">
                            <div className="links-holder row m-0 text-center">
                                <div className="col-4 mb-3 px-2">
                                    <Link to="/bible-stories/all"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <MdOutlineAutoStories size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            {t('bibleStories')}
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-4 mb-3 px-2">
                                    <Link to="/bible-quizzes"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <MdOutlineQuiz size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            Bible Quizzes
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-4 mb-3 px-2">
                                    <Link to="/home"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <MdOutlineWebStories size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            Bible Verses
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-4 mb-3 px-2">
                                    <Link to="/home#challenges"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <BiCategoryAlt size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            Challenge Yourself
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-4 mb-3 px-2">
                                    <Link to="/bookshelf"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <LuStore size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            {t('bookShelfItem')}
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-4 mb-3 px-2">
                                    <Link to="#"
                                          className="box-content py-3 light-shadow main-text-dark-color rounded-1 d-block">
                                        <div className="icon-holder mb-1">
                                            <LuContact size="40"/>
                                        </div>
                                        <div className="title-holder small fw-bold-600" style={{minHeight: "42px"}}>
                                            Contact
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <Footer/>
            </div>
        </div>
    );
};

export default MorePageContent;