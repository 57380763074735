import Axios from "../ServiceCaller";

let createOrder = (orderData) => {
    return Axios.post('/user-orders', orderData);
};

let getOrderById = async (id) => {
    const { data } = await Axios.get(`/user-orders/${id}`);
    return data.Order;
};

let PayOrderWithTranzak = (data) => {
    return Axios.post('/payments/tranzak/create-tranzak-order', data);
};

let verifyTransaction = async (transactionId) => {
    const {data} = await Axios.get(`/payments/tranzak/verify-transaction/${transactionId}`);
    return data.Response;
};

export const userOrderService = {
    createOrder,
    getOrderById,
    PayOrderWithTranzak,
    verifyTransaction,
};