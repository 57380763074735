import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {publicBibleStoryService} from "../../../_services";
import defaultImage from "../../../assets/images/global/default_image.jpg";
import {Link} from "react-router-dom";

const BibleStoryCategories = ({exception}) => {
    const {t, i18n} = useTranslation();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const data = await publicBibleStoryService.getBibleStoryCategories();
                setCategories(data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const getCategoryName = (category) => {
        return i18n.language.startsWith('fr') ? category.name_fr : category.name_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <section className="bible-story-categories-section p-3">
            <div className="d-flex align-items-center justify-content-between mb-3 px-1 small">
                <div className="title fw-bold-600">
                    {exception ? (
                        t('otherCategories')
                    ) : (
                        t('categories')
                    )}
                </div>
                <Link to="/bible-stories/categories" className="link-text-holder fw-bold-600 main-text-color">
                    {t('seeAll')}
                </Link>
            </div>
            <div className="bible-story-categories-holder d-flex align-items-center flex-wrap">
                {loading ? (
                    <div className="w-100 overflow-x-scroll text-nowrap" style={{scrollbarWidth: "none"}}>
                        <div style={{height: "125px", width: "125px"}}
                             className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                        <div style={{height: "125px", width: "125px"}}
                             className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                        <div style={{height: "125px", width: "125px"}}
                             className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                        <div style={{height: "125px", width: "125px"}}
                             className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                        <div style={{height: "125px", width: "125px"}}
                             className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                        <div style={{height: "125px", width: "125px"}}
                             className="rounded-3 me-2 main-loading-effect d-inline-block"/>
                    </div>
                ) : (
                    <div className="w-100 overflow-x-scroll text-nowrap" style={{scrollbarWidth: "none"}}>
                        {categories.map((category) => (
                            <Link to={`/bible-stories/category/${category.id}`} key={category.id} style={{height: '160px'}}
                                  className={`small me-2 text-wrap overflow-hidden ${(exception && parseInt(exception) === category.id) ? 'd-none' : 'd-inline-block'}`}>
                                <div className="img-holder rounded-4">
                                    {category.image ? (
                                        <img
                                            src={`${baseURL}thedss/images/bible-story-categories/${category.image}`}
                                            className="border rounded-4"
                                            width="125"
                                            height="125"
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={defaultImage}
                                            className="border rounded-4"
                                            width="125"
                                            height="125"
                                            alt=""
                                        />
                                    )}
                                </div>
                                <div className="name-holder text-real-small fw-bold-500 mt-1 text-center lh-sm black-text-color"
                                     style={{width: '125px'}}>
                                    {getCategoryName(category)}
                                </div>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default BibleStoryCategories;