import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {publicQuizService} from "../../../_services";
import {Link} from "react-router-dom";
import defaultImage from "../../../assets/images/global/default_image.jpg";
import {FaArrowRightLong} from "react-icons/fa6";

const QuizCategories = () => {
    useEffect(() => {
        document.title = "Holylane - Bible Quizzes";
    }, []);

    const {t, i18n} = useTranslation();
    const [quizCategoriesData, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getCategoryName = (category) => {
        return i18n.language.startsWith('fr') ? category.name_fr : category.name_en;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicQuizService.getQuizCategories();
                setData(data);
            } catch (error) {
                console.error("Error fetching Quiz Categories data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    let baseURL = 'https://api.holylane.io/';

    return (
        <div>
            {loading ? (
                <>
                    <div style={{height: "75px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "75px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "75px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                </>
            ) : (
                quizCategoriesData.map((quizCategory) => (
                    <Link to={`/bible-quizzes/${quizCategory.id}/quizzes`}
                          className="quiz-category p-3 d-flex align-items-center rounded-3 mb-3" key={quizCategory.id}
                          style={{backgroundColor: `${quizCategory.color}`}}>
                        <div className="img-icon-holder me-2 p-1">
                            {quizCategory.image ? (
                                <img
                                    src={`${baseURL}thedss/images/quiz-categories/${quizCategory.image}`}
                                    className="rounded-circle"
                                    width="40" height="40"
                                    alt=""
                                />
                            ) : (
                                <img
                                    src={defaultImage}
                                    className="rounded-circle"
                                    width="40" height="40"
                                    alt=""
                                />
                            )}
                        </div>
                        <div>
                            <div
                                className="title fw-bold one-line-limit text-dark">{getCategoryName(quizCategory)}</div>
                            <div className="quizez-number small gray-text-color">
                                {`${quizCategory.quizzes_number} Quiz${quizCategory.quizzes_number > 1 ? 'zes' : ''}`}
                            </div>
                        </div>
                        <div className="ms-auto">
                            <FaArrowRightLong size="25" className="gray-text-color"/>
                        </div>
                    </Link>
                ))
            )}
        </div>
    );
};

export default QuizCategories;