import {
    Footer,
    Header,
    UserBooksSection,
    UserChallengesSection,
    UserContactSection,
    UserDetailsSection,
    UserHeaderSection
} from "./Public";
import React, {useEffect, useRef, useState} from "react";
import {userAccountService} from "../../_services";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TbListDetails} from "react-icons/tb";
import {BiAbacus} from "react-icons/bi";
import {PiBooks} from "react-icons/pi";
import {RiContactsLine} from "react-icons/ri";

const MyProfile = () => {
    const {t, i18n} = useTranslation();
    let navigate = useNavigate();
    const [section, setSection] = useState(0);
    const profileSectionsData = [
        {id: 0, name: "Details", icon: <TbListDetails size="30"/>},
        {id: 1, name: "My Challenges", icon: <BiAbacus size="30"/>},
        {id: 2, name: "My Books", icon: <PiBooks size="30"/>},
        {id: 3, name: "Contact", icon: <RiContactsLine size="30"/>}
    ]
    const sectionRefs = useRef({});

    useEffect(() => {
        if (!userAccountService.isLogged()) {
            navigate('/auth/login');
        }
    }, []);

    const handleSectionClick = (id) => {
        setSection(id);
        if (sectionRefs.current[id]) {
            sectionRefs.current[id].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        }
    };

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="all-bible-stories-page">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                <UserHeaderSection/>
                <div className="user-all-sections-holder bottom-padding px-3 mt-4">
                    <div className="w-100 overflow-x-scroll text-nowrap" style={{scrollbarWidth: "none"}}>
                        {profileSectionsData.map((item) => (
                            <div
                                key={item.id}
                                ref={(el) => sectionRefs.current[item.id] = el}
                                className={`section-item py-4 px-3 border border-light-subtle rounded-3 small me-2 d-inline-block text-wrap overflow-hidden text-center the-main-transition-03 ${item.id === section ? 'main-light-background-color text-white' : 'black-text-color'}`}
                                onClick={() => handleSectionClick(item.id)}
                            >
                                <div className="mb-2">
                                    {item.icon}
                                </div>
                                <div>
                                    {item.name}
                                </div>
                            </div>
                        ))}
                    </div>
                    {section === 0 ? (
                        <UserDetailsSection/>
                    ) : section === 1 ? (
                        <UserChallengesSection/>
                    ) : section === 2 ? (
                        <UserBooksSection/>
                    ) : section === 3 ? (
                        <UserContactSection/>
                    ) : null}
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default MyProfile;