import {
    Header,
    FacebookPixelKeysSettings,
    GoogleAnalyticsKeysSettings,
    PaypalKeysSettings,
    TranzakKeysSettings,
    StripeKeysSettings,
} from "./Private";

const Settings = () => {
    return (
        <>
            <Header title="Settings"/>
            <div className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="bg-white mb-3 p-2 p-lg-3 rounded-3 row m-0">
                    <div className="google-analytics-keys-setting mb-3">
                        <GoogleAnalyticsKeysSettings/>
                    </div>
                    <div className="facebook-keys-setting mb-3">
                        <FacebookPixelKeysSettings/>
                    </div>
                    <div className="paypal-keys-setting mb-3">
                        <PaypalKeysSettings/>
                    </div>
                    <div className="tranzak-keys-setting mb-3">
                        <TranzakKeysSettings/>
                    </div>
                    <div className="stripe-keys-setting mb-3">
                        <StripeKeysSettings/>
                    </div>
                </div>
                {/*End Page Content*/}
            </div>
        </>
    );
};

export default Settings;