import Axios from "../ServiceCaller";

let getBibleStories = async (category) => {
    const { data } = await Axios.get(`/admin-bible-stories/cat/${category}`);
    return data.BibleStories;
};
let createBibleStory = async (bibleStoryData) => {
    const { data } = await Axios.post("/admin-bible-stories", bibleStoryData);
    return data;
};
let getBibleStoryById = async (id) => {
    const { data } = await Axios.get(`/admin-bible-stories/${id}`);
    return data.BibleStory;
};
let getBibleStoryStatus = async (id) => {
    const { data } = await Axios.get(`/admin-bible-stories/status/${id}`);
    return data.BibleStoryStatus;
};
let updateBibleStory = async (bibleStoryData) => {
    const { data } = await Axios.put("/admin-bible-stories", bibleStoryData);
    return data;
};
let deleteBibleStory = async (bibleStoryId) => {
    try {
        const response = await Axios.delete(`/admin-bible-stories`, {
            data: { id: bibleStoryId },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Bible Story: ", error);
        throw error;
    }
};

let getBibleStoryCategories = async () => {
    const { data } = await Axios.get("/admin-bible-stories/categories");
    return data.BibleStoryCategories;
};
let createBibleStoryCategory = async (bibleStoryCategoryData) => {
    const { data } = await Axios.post("/admin-bible-stories/categories", bibleStoryCategoryData);
    return data;
};
let getBibleStoryCategoryById = async (id) => {
    const { data } = await Axios.get(`/admin-bible-stories/categories/${id}`);
    return data.BibleStoryCategory;
};
let updateBibleStoryCategory = async (bibleStoryCategoryData) => {
    const { data } = await Axios.put("/admin-bible-stories/categories", bibleStoryCategoryData);
    return data;
};
let deleteBibleStoryCategory = async (bibleStoryCategoryId) => {
    try {
        const response = await Axios.delete(`/admin-bible-stories/categories`, {
            data: { id: bibleStoryCategoryId },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Bible Story Category:", error);
        throw error;
    }
};

let getBibleStoryEpisodes = async (id) => {
    const { data } = await Axios.get(`/admin-bible-stories/episodes/bible-story/${id}`);
    return data.BibleStoryEpisodes;
};
let createBibleStoryEpisode = async (bibleStoryEpisodeData) => {
    const { data } = await Axios.post("/admin-bible-stories/episodes", bibleStoryEpisodeData);
    return data;
};
let getBibleStoryEpisodeById = async (id) => {
    const { data } = await Axios.get(`/admin-bible-stories/episodes/${id}`);
    return data.BibleStoryEpisode;
};
let updateBibleStoryEpisode = async (bibleStoryEpisodeData) => {
    const { data } = await Axios.put("/admin-bible-stories/episodes", bibleStoryEpisodeData);
    return data;
};
let deleteBibleStoryEpisode = async (bibleStoryEpisodeId) => {
    try {
        const response = await Axios.delete(`/admin-bible-stories/episodes`, {
            data: { id: bibleStoryEpisodeId },
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Bible Story Episode:", error);
        throw error;
    }
};

export const adminBibleStoryService = {
    getBibleStories,
    createBibleStory,
    getBibleStoryById,
    getBibleStoryStatus,
    updateBibleStory,
    deleteBibleStory,
    getBibleStoryCategories,
    createBibleStoryCategory,
    getBibleStoryCategoryById,
    updateBibleStoryCategory,
    deleteBibleStoryCategory,
    getBibleStoryEpisodes,
    createBibleStoryEpisode,
    updateBibleStoryEpisode,
    deleteBibleStoryEpisode,
    getBibleStoryEpisodeById
};