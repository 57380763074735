import {Header} from "./Private";

const Dashboard = () => {
    return (
        <>
            <Header title="Dashboard"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="main-dashboard-page bg-white mb-3 p-2 p-lg-3 rounded-3 row m-0">

                </div>
                {/*End Page Content*/}
            </div>
        </>
    );
};

export default Dashboard;