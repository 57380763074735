import React, {useEffect, useState} from 'react';
import {ImportQuestionsExcelForm, QuizQuestionsForm, QuizQuestionsRow} from "../../../pages/private/Private";
import {adminQuizService} from "../../../_services";

const QuizQuestionsFiltered = (props) => {
    const {quiz, searchQuery} = props;
    const [quizQuestionData, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [quizQuestionToEdit, setQuizQuestionToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);
    const [importHidden, setImportHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminQuizService.getQuizQuestions(quiz);
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching Quiz Question data:", error);
            }
        };

        fetchData();
    }, [refresh, quiz]);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(quizQuestionData);
            return;
        }

        const filtered = quizQuestionData.filter((quizQuestion) =>
            Object.values(quizQuestion).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, quizQuestionData]);

    const handleEditQuizQuestion = (quizQuestion) => {
        setEditMode(true);
        setQuizQuestionToEdit(quizQuestion);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    const setImportFormHidden = () => {
        setImportHidden(!importHidden);
    }

    return (
        <div className="quiz-questions-page bg-white py-3 px-1 px-sm-3 rounded-3">
            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                    Quiz Questions List
                </div>
                <div className="d-flex">
                    <div className="add-btn-holder">
                        <button
                            className={`main-button-color border-0 small rounded-2 py-1 px-3 ${hidden ? "d-block" : "d-none"}`}
                            type="button" disabled={!importHidden}
                            onClick={setFormHiddenAdd}>
                            Add New
                        </button>
                    </div>
                    <div className="import-btn-holder ms-3">
                        <button
                            className={`main-button-color border-0 small rounded-2 py-1 px-3 ${importHidden ? "d-block" : "d-none"}`}
                            type="button" disabled={!hidden}
                            onClick={setImportFormHidden}>
                            Import File
                        </button>
                    </div>
                </div>
            </div>
            <div className={hidden ? "d-none" : "d-block"}>
                <QuizQuestionsForm
                    sendRefreshToParent={setRefresh}
                    refresh={refresh}
                    editMode={editMode}
                    quizQuestion={quizQuestionToEdit}
                    quiz={quiz}
                    sendHiddenToParent={setHidden}
                    hidden={hidden}
                />
            </div>
            <div className={importHidden ? "d-none" : "d-block"}>
                <ImportQuestionsExcelForm
                    sendRefreshToParent={setRefresh}
                    refresh={refresh}
                    sendImportHiddenToParent={setImportHidden}
                    importHidden={importHidden}
                    quiz={quiz}
                />
            </div>
            <div
                className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${(!hidden || !importHidden) ? "d-none" : "d-block"}`}>
                <table className="table table-sm">
                    <thead>
                    <tr className="text-nowrap small">
                        <td className="text-black-50">Question (En)</td>
                        <td className="text-black-50">Correct Answer (En)</td>
                        <td className="text-black-50">Wrong Answer 1 (En)</td>
                        <td className="text-black-50">Wrong Answer 2 (En)</td>
                        <td className="text-black-50">Wrong Answer 3 (En)</td>
                        <td className="text-black-50 text-end">Created</td>
                        <td className="text-black-50 text-center">...</td>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((quizQuestion) => (
                            <QuizQuestionsRow
                                key={quizQuestion.id}
                                quiz_id={quizQuestion.quiz_id}
                                quizQuestion_id={quizQuestion.id}
                                question_text_en={quizQuestion.question_text_en}
                                question_text_fr={quizQuestion.question_text_fr}
                                correct_answer_en={quizQuestion.correct_answer_en}
                                correct_answer_fr={quizQuestion.correct_answer_fr}
                                wrong_answer_1_en={quizQuestion.wrong_answer_1_en}
                                wrong_answer_1_fr={quizQuestion.wrong_answer_1_fr}
                                wrong_answer_2_en={quizQuestion.wrong_answer_2_en}
                                wrong_answer_2_fr={quizQuestion.wrong_answer_2_fr}
                                wrong_answer_3_en={quizQuestion.wrong_answer_3_en}
                                wrong_answer_3_fr={quizQuestion.wrong_answer_3_fr}
                                created_date={quizQuestion.created_date}
                                sendRefreshToParent={setRefresh}
                                refresh={refresh}
                                handleEditQuizQuestion={handleEditQuizQuestion}
                                sendHiddenToParent={setHidden}
                                hidden={hidden}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default QuizQuestionsFiltered;