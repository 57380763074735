import {useParams} from "react-router-dom";
import {Footer, Header, QuizzesListByCategory} from "./Public";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {publicQuizService, userAccountService} from "../../_services";
import defaultImage from "../../assets/images/global/default_image.jpg";

const QuizzesByCategory = () => {
    let {qcid} = useParams();
    const {t, i18n} = useTranslation();
    const [quizCategoryData, setData] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicQuizService.getQuizCategoryById(qcid);
                setData(data);
            } catch (error) {
                console.error("Error fetching Quiz Category data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const getCategoryName = (category) => {
        return i18n.language.startsWith('fr') ? category.name_fr : category.name_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="quizzes-by-category-page">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                {loading ? (
                    <div className="main-loading-effect w-75 mx-auto mt-3 rounded-2" style={{height: "20px"}}></div>
                ) : (
                    quizCategoryData && (
                        <div className="p-3 bottom-padding">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="img-icon-holder me-2 p-1">
                                    {quizCategoryData.image ? (
                                        <img
                                            src={`${baseURL}thedss/images/quiz-categories/${quizCategoryData.image}`}
                                            className="rounded-circle"
                                            width="40" height="40"
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            src={defaultImage}
                                            className="rounded-circle"
                                            width="40" height="40"
                                            alt=""
                                        />
                                    )}
                                </div>
                                <div className="quiz-category-title text-center fw-bold-600 main-text-dark-color">
                                    {getCategoryName(quizCategoryData)} <span className="fw-normal">Quizzes</span>
                                </div>
                            </div>
                            <QuizzesListByCategory category={qcid}/>
                        </div>
                    )
                )}
                <Footer/>
            </div>
        </div>
    );
};

export default QuizzesByCategory;