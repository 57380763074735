import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {publicProductService} from "../../../_services";
import {Link} from "react-router-dom";
import defaultImage from "../../../assets/images/global/default_image.jpg";

import {Swiper, SwiperSlide} from 'swiper/react';

import {EffectCoverflow, Pagination, Navigation} from 'swiper/modules';

const SpecialProducts = () => {
    const {t, i18n} = useTranslation();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true);
                const data = await publicProductService.getSpecialProducts();
                setProducts(data);
            } catch (error) {
                console.error("Error fetching products:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const getColumnValue = (product, column) => {
        return i18n.language.startsWith('fr') ? product[`${column}_fr`] : product[`${column}_en`];
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="px-4 py-3">
            <div className="text-white fw-bold-600 px-2 one-line-limit mb-2">
                {t('topEbook')}
            </div>
            {loading ? (
                <div className="swiper-holder-loading">
                    <Swiper
                        pagination={{
                            type: 'fraction',
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="main-loading-effect rounded-4" style={{height: "250px", width: "100%"}}/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="main-loading-effect rounded-4" style={{height: "250px", width: "100%"}}/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="main-loading-effect rounded-4" style={{height: "250px", width: "100%"}}/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="main-loading-effect rounded-4" style={{height: "250px", width: "100%"}}/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="main-loading-effect rounded-4" style={{height: "250px", width: "100%"}}/>
                        </SwiperSlide>
                    </Swiper>
                </div>
            ) : (
                <div className="swiper-specials-holder rounded-4 bg-white rounded-4 p-2 light-shadow">
                    <Swiper
                        pagination={{
                            type: 'fraction',
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {products.length > 0 && (
                            products.map((product) => (
                                <SwiperSlide key={product.id}>
                                    <div className="featured-box rounded-4 position-relative px-2">
                                        <div className="three-lines-limit small gray-text-color mb-3" style={{height: "63px"}}>
                                            {getColumnValue(product, 'description')}
                                        </div>
                                        <div className="d-flex">
                                            <div className="image-holder" style={{width: '40%', height: '180px'}}>
                                                {product.image ? (
                                                    <img
                                                        src={`${baseURL}thedss/images/products/${product.image}`}
                                                        className="rounded-3 border w-100 h-100"
                                                        alt=""
                                                    />
                                                ) : (
                                                    <img
                                                        src={defaultImage}
                                                        className="rounded-3 border w-100 h-100"
                                                        alt=""
                                                    />
                                                )}
                                            </div>
                                            <div className="px-3 d-flex flex-column"
                                                 style={{width: '60%', height: '180px'}}>
                                                <div className="black-text-color fw-bold-600 two-lines-limit mb-2">
                                                    {getColumnValue(product, 'title')}
                                                </div>
                                                <div className="one-line-limit mb-2 gray-text-color small">
                                                    {getColumnValue(product, 'name')}
                                                </div>
                                                <div className="fw-bold-600 main-text-dark-color">
                                                    ${parseFloat(product.price).toFixed(2)}
                                                </div>
                                                <div className="mt-auto">
                                                    <Link to={`/bookshelf/book/${product.id}`}
                                                          className="text-white main-hover-background-color px-4 py-2 d-inline-block rounded-3">
                                                        {t('details')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        )}
                    </Swiper>
                </div>
            )}
        </div>
    );
};

export default SpecialProducts;