import {BiEdit, BiTrash} from "react-icons/bi";
import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {adminBibleStoryService} from "../../../_services";
import defaultImage from"../../../assets/images/global/default_image.jpg";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    let yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
};

const BibleStoryCategoriesRow = (props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleDeleteBibleStoryCategories = async () => {
        setIsDeleting(true);

        try {
            await adminBibleStoryService.deleteBibleStoryCategory(props.bibleStoryCategory_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting Bible Story category:", error);
        } finally {
            setIsDeleting(false);
            setShowConfirmModal(false);
        }
    };

    const handleEditBibleStoryCategories = () => {
        props.handleEditBibleStoryCategories(props);
        props.sendHiddenToParent(!props.hidden);
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <>
            {props.bibleStoryCategory_id > 0 ? (
                <tr className="text-nowrap small" style={{lineHeight: "50px"}}>
                    <td>
                        <span>
                            {props.image ? (
                                <img
                                    height="50"
                                    width="50"
                                    
                                    src={`${baseURL}thedss/images/bible-story-categories/${props.image}`}
                                    alt="Current Image For Bible Story"
                                    className="rounded-2"
                                />
                            ) : (
                                <img
                                    height="50"
                                    width="50"
                                    
                                    src={defaultImage}
                                    alt="Default Image For Bible Story"
                                    className="rounded-2"
                                />
                            )}
                        </span>
                    </td>
                    <td>{props.name_en}</td>
                    <td>{props.name_fr}</td>
                    <td className="text-end">
                        <span className="text-black-50">
                          {props.created_date ? getDate(props.created_date) : ""}
                        </span>
                    </td>
                    <td className="text-center">
                        <span className="text-center">
                          <button
                              className="border-0 bg-transparent"
                              onClick={handleEditBibleStoryCategories}
                              disabled={!props.hidden}
                          >
                            <BiEdit size="22" className="edit-icon"/>
                          </button>
                          <button
                              className="border-0 bg-transparent"
                              onClick={() => setShowConfirmModal(true)}
                          >
                            <BiTrash size="22" className="remove-icon mx-auto"/>
                          </button>
                        </span>
                    </td>
                </tr>
            ) : (
                <tr>
                    <td colSpan="5" className="text-center">
                        There is no bible story category yet
                    </td>
                </tr>
            )}

            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="black-text-color">
                        Are you sure you want to delete this Bible Story category?<br/>
                        <span className="small fw-bold-500">All Bible Stories with this category will be deleted too!</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmModal(false)}
                        disabled={isDeleting}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleDeleteBibleStoryCategories}
                        disabled={isDeleting}
                    >
                        {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BibleStoryCategoriesRow;