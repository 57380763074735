import React, {useEffect, useRef, useState} from 'react';
import {publicPricingPlanService} from "../../../_services";
import {useTranslation} from "react-i18next";

const PricingPlansItems = ({setPricingPlan, setPricingPlanTitle, setPricingPlanPrice}) => {
    const {t, i18n} = useTranslation();
    const [pricingPlans, setPricingPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const pricingPlanRefs = useRef({});

    useEffect(() => {
        const fetchPricingPlans = async () => {
            try {
                setLoading(true);
                const data = await publicPricingPlanService.getPricingPlans();
                setPricingPlans(data);

                if (data.length > 0) {
                    setSelectedPlan(data[0].id);
                    setPricingPlan(data[0].id);
                    setPricingPlanTitle(data[0].title_en);
                    setPricingPlanPrice(data[0].price);
                }
            } catch (error) {
                console.error("Error fetching pricing plans:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPricingPlans();
    }, [setPricingPlan]);

    const getTitle = (pricingPlan) => {
        return i18n.language.startsWith('fr') ? pricingPlan.title_fr : pricingPlan.title_en;
    };

    const handlePricingPlanClick = (id) => {
        const selectedPricingPlan = pricingPlans.find(plan => plan.id === id);
        setSelectedPlan(id);
        setPricingPlan(id);
        setPricingPlanTitle(getTitle(selectedPricingPlan));
        setPricingPlanPrice(selectedPricingPlan.price);
        if (pricingPlanRefs.current[id]) {
            pricingPlanRefs.current[id].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        }
    };

    return (
        <div className="main-pricing-plans-items">
            {loading ? (
                <div className="pricing-plans-holder w-100 overflow-x-scroll text-nowrap my-4 pt-3 pb-1"
                     style={{scrollbarWidth: "none"}}>

                </div>
            ) : (
                <div className="pricing-plans-holder w-100 overflow-x-scroll text-nowrap my-4 pt-3 pb-1"
                     style={{scrollbarWidth: "none"}}>
                    {pricingPlans.map((pricingPlan) => (
                        <div key={pricingPlan.id}
                             ref={(el) => pricingPlanRefs.current[pricingPlan.id] = el}
                             className={`plan-item light-shadow py-5 px-4 border border-light-subtle rounded-2 small me-2 d-inline-block text-wrap overflow-hidden 
                             ${selectedPlan === pricingPlan.id ? 'active' : ''}`}
                             onClick={() => handlePricingPlanClick(pricingPlan.id)}
                        >
                            <div className="plan-title fw-bold fs-1">
                                {getTitle(pricingPlan)}
                            </div>
                            <div className="plan-duration small fw-bold-500">
                                {pricingPlan.period} {pricingPlan.period_type.charAt(0).toUpperCase() + pricingPlan.period_type.slice(1)}{`${pricingPlan.period > 1 ? 's' : ''}`}
                            </div>
                            <div className="plan-price small fw-bold-600">
                                ${(pricingPlan.price / pricingPlan.period).toFixed(2)} per {pricingPlan.period_type.charAt(0).toUpperCase() + pricingPlan.period_type.slice(1)}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PricingPlansItems;
