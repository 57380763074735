import React from "react";

const UserContactSection = () => {
    return (
        <div className="my-details-section pt-3 px-3">
            <div className="title fw-bold-600 mb-3">
                My Contact
            </div>
        </div>
    );
};

export default UserContactSection;