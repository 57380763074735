import {Footer, Header} from "./Public";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {publicBibleStoryService} from "../../_services";
import defaultImage from "../../assets/images/global/default_image.jpg";
import {Link} from "react-router-dom";

const AllBibleStoryCategories = () => {
    useEffect(() => {
        document.title = "Holylane - Bible Stories";
    }, []);

    const {t, i18n} = useTranslation();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const data = await publicBibleStoryService.getBibleStoryCategories();
                setCategories(data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const getCategoryName = (category) => {
        return i18n.language.startsWith('fr') ? category.name_fr : category.name_en;
    };

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="all-bible-story-categories-page home-container">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                <div className="bible-story-categories-holder bottom-padding">
                    {loading ? (
                        <div className="w-100 row mx-0 mt-3">
                            <div>
                                <div style={{height: "20px", width: "205px"}}
                                     className="rounded-2 main-loading-effect mb-2 px-3"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                            <div className="col-4 mb-3">
                                <div style={{height: "125px"}}
                                     className="rounded-3 main-loading-effect"/>
                            </div>
                        </div>
                    ) : (
                        <div className="w-100 row mx-0">
                            <div>
                                <div className="title small fw-bold-600 mt-3 mb-2 ps-1">
                                    {t('bibleStoryCategories')}
                                </div>
                            </div>
                            {categories.map((category) => (
                                <div key={category.id} className="col-4 mb-3">
                                    <div>
                                        <Link to={`/bible-stories/category/${category.id}`} className="small" style={{height: '160px'}}>
                                            <div className="img-holder rounded-4">
                                                {category.image ? (
                                                    <img
                                                        src={`${baseURL}thedss/images/bible-story-categories/${category.image}`}
                                                        className="border rounded-4 w-100"
                                                        height="125"
                                                        alt=""
                                                    />
                                                ) : (
                                                    <img
                                                        src={defaultImage}
                                                        className="border rounded-4 w-100"
                                                        height="125"
                                                        alt=""
                                                    />
                                                )}
                                            </div>
                                            <div className="name-holderm text-real-small fw-bold-500 mt-1 text-center lh-sm black-text-color w-100">
                                                {getCategoryName(category)}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default AllBibleStoryCategories;