import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {publicQuizService} from "../../../_services";
import {Link} from "react-router-dom";
import defaultImage from "../../../assets/images/global/default_image.jpg";
import {FaArrowRightLong} from "react-icons/fa6";

const QuizzesListByCategory = ({category}) => {
    const {t, i18n} = useTranslation();
    const [quizzesData, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicQuizService.getQuizzes(category);
                setData(data);
            } catch (error) {
                console.error("Error fetching Quizzes data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getTitle = (category) => {
        return i18n.language.startsWith('fr') ? category.title_fr : category.title_en;
    };

    return (
        <div className="mt-4">
            {loading ? (
                <>
                    <div style={{height: "85px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "85px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "85px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                </>
            ) : (
                quizzesData.map((quiz) => (
                    <Link to={`/bible-quizzes/quiz/${quiz.id}/questions`}
                          className="quiz-category p-3 mb-3 d-block border border-light-subtle rounded-4 light-shadow" key={quiz.id}>
                        <div className="fw-bold two-lines-limit text-dark">
                            {getTitle(quiz)}
                        </div>
                        <div className="d-flex align-items-center justify-content-between px-3 mt-1 black-text-color">
                            <div className="small">
                                {quiz.level === 1 ? 'Easy' : quiz.level === 2 ? 'Medium' : 'Hard'}
                            </div>
                            <div className="small">
                                {`${quiz.questions_number} Question${quiz.questions_number > 1 ? 's' : ''}`}
                            </div>
                        </div>
                    </Link>
                ))
            )}
        </div>
    );
};

export default QuizzesListByCategory;