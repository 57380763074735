import React, {useEffect, useState} from 'react';
import {ProductsForm, ProductsRow} from "../../../pages/private/Private";
import {adminProductService} from "../../../_services";
import {Link} from "react-router-dom";
import {TbCategory2} from "react-icons/tb";

const ProductsFiltered = (props) => {
    const {category, searchQuery} = props;
    const [productData, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [productToEdit, setProductToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminProductService.getProducts(category);
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching Product data:", error);
            }
        };

        fetchData();
    }, [refresh, category]);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(productData);
            return;
        }

        const filtered = productData.filter((product) =>
            Object.values(product).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, productData]);

    const handleEditProduct = (product) => {
        setEditMode(true);
        setProductToEdit(product);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <div className="products-page bg-white py-3 px-1 px-sm-3 rounded-3">
            <div className="categories-holder mb-3 text-center">
                <Link to="/admin/products/categories"
                      className="link-as-button small rounded-1 py-2 px-3">
                    <TbCategory2 size="20"/>
                    <span className="ms-2">Manage Categories</span>
                </Link>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                    Products List
                </div>
                <div className="d-flex">
                    <div className="add-btn-holder">
                        <button
                            className={`main-button-color border-0 small rounded-2 py-1 px-3 ${hidden ? "d-block" : "d-none"}`}
                            type="button" disabled={!hidden}
                            onClick={setFormHiddenAdd}>
                            Add New
                        </button>
                    </div>
                </div>
            </div>
            <div className={hidden ? "d-none" : "d-block"}>
                <ProductsForm
                    sendRefreshToParent={setRefresh}
                    refresh={refresh}
                    editMode={editMode}
                    product={productToEdit}
                    sendHiddenToParent={setHidden}
                    hidden={hidden}
                />
            </div>
            <div
                className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${hidden ? "d-block" : "d-none"}`}>
                <table className="table table-sm">
                    <thead>
                    <tr className="text-nowrap small">
                        <td className="text-black-50">Image</td>
                        <td className="text-black-50">Title (En)</td>
                        <td className="text-black-50">Title (Fr)</td>
                        <td className="text-black-50">Description (En)</td>
                        <td className="text-black-50">Description (Fr)</td>
                        <td className="text-black-50 text-center">Category</td>
                        <td className="text-black-50 text-center">Language</td>
                        <td className="text-black-50 text-center">Pages</td>
                        <td className="text-black-50 text-center">Status</td>
                        <td className="text-black-50 text-center">Price</td>
                        <td className="text-black-50 text-end">Created</td>
                        <td className="text-black-50 text-center">...</td>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((product) => (
                            <ProductsRow
                                key={product.id}
                                product_id={product.id}
                                image={product.image}
                                pdf_file={product.pdf_file}
                                title_en={product.title_en}
                                title_fr={product.title_fr}
                                description_en={product.description_en}
                                description_fr={product.description_fr}
                                pages_number={product.pages_number}
                                is_special={product.is_special}
                                category_id={product.category_id}
                                category_name_en={product.name_en}
                                language={product.language}
                                price={product.price}
                                created_date={product.created_date}
                                sendRefreshToParent={setRefresh}
                                refresh={refresh}
                                handleEditProduct={handleEditProduct}
                                sendHiddenToParent={setHidden}
                                hidden={hidden}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProductsFiltered;