import React, {useEffect, useState} from 'react';
import {adminUserService} from "../../../_services";
import UsersRow from "./UsersRow";

const UsersFiltered = (props) => {
    const {searchQuery} = props;
    const [usersData, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminUserService.getUsers();
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching Users data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(usersData);
            return;
        }

        const filtered = usersData.filter((user) =>
            Object.values(user).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, usersData]);


    return (
        <div className="users-page bg-white py-3 px-1 px-sm-3 rounded-3">
            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                    Users List
                </div>
            </div>
            <div className="bg-white p-sm-2 p-lg-3 rounded-4 mb-3 table-responsive">
                <table className="table table-sm">
                    <thead>
                    <tr className="text-nowrap small">
                        <td className="text-black-50">Full Name</td>
                        <td className="text-black-50">Email</td>
                        <td className="text-black-50">Status</td>
                        <td className="text-black-50">Subscription</td>
                        <td className="text-black-50">Subscription Start</td>
                        <td className="text-black-50">Subscription End</td>
                        <td className="text-black-50 text-end">Created</td>
                        <td className="text-black-50 text-center">...</td>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((user) => (
                            <UsersRow
                                key={user.id}
                                user_id={user.id}
                                full_name={user.full_name}
                                email={user.email}
                                status={user.access_type}
                                subscription={user.title_en}
                                subscription_start={user.subscription_start}
                                subscription_end={user.subscription_end}
                                created_date={user.created_date}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UsersFiltered;