const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    let yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
};

const OrdersRow = ({order_id, product, customer, email, amount, payment, status, created_date}) => {
    return (
        <>
            {order_id > 0 ? (
                <tr className="text-nowrap small">
                    <td>
                        {`${product.substring(0, 25)}${
                            product.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${customer.substring(0, 25)}${
                            customer.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        {`${email.substring(0, 25)}${
                            email.length > 25 ? "..." : ""
                        }`}
                    </td>
                    <td>
                        ${parseFloat(amount).toFixed(2)}
                    </td>
                    <td>
                        {payment.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}
                    </td>
                    <td>
                        {status.replace(/^\w/, c => c.toUpperCase())}
                    </td>
                    <td className="text-end">
                        <span className="text-black-50">
                          {created_date ? getDate(created_date) : ""}
                        </span>
                    </td>
                </tr>
            ) : (
                <tr>
                    <td colSpan="7" className="text-center">
                        There is no order yet
                    </td>
                </tr>
            )}
        </>
    );
};

export default OrdersRow;
