import React, {useEffect, useState} from 'react';
import AdsForm from "./AdsForm";
import AdsRow from "./AdsRow";
import {adminAdService} from "../../../_services";

const AdsFiltered = (props) => {
    const {searchQuery} = props;
    const [adsData, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [adToEdit, setAdToEdit] = useState(null);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminAdService.getAds();
                setData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching Ads data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    useEffect(() => {
        if (!searchQuery) {
            setFilteredData(adsData);
            return;
        }

        const filtered = adsData.filter((ad) =>
            Object.values(ad).some((value) =>
                String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, adsData]);

    const handleEditAd = (ad) => {
        setEditMode(true);
        setAdToEdit(ad);
    };

    const setFormHiddenAdd = () => {
        setEditMode(false);
        setHidden(!hidden);
    }

    return (
        <div className="ads-page bg-white py-3 px-1 px-sm-3 rounded-3">
            <div className="d-flex flex-column flex-md-row align-items-center mb-2 mb-lg-3">
                <div className="black-text-color fw-bold-600 me-md-3 mb-2 mb-md-0">
                    Ads List
                </div>
                <div className="d-flex">
                    <div className="add-btn-holder">
                        <button
                            className={`main-button-color border-0 small rounded-2 py-1 px-3 ${hidden ? "d-block" : "d-none"}`}
                            type="button" disabled={!hidden}
                            onClick={setFormHiddenAdd}>
                            Add New
                        </button>
                    </div>
                </div>
            </div>
            <div className={hidden ? "d-none" : "d-block"}>
                <AdsForm
                    sendRefreshToParent={setRefresh}
                    refresh={refresh}
                    editMode={editMode}
                    ad={adToEdit}
                    sendHiddenToParent={setHidden}
                    hidden={hidden}
                />
            </div>
            <div
                className={`bg-white p-sm-2 p-lg-3 p-xl-4 rounded-4 mb-3 table-responsive ${hidden ? "d-block" : "d-none"}`}>
                <table className="table table-sm">
                    <thead>
                    <tr className="text-nowrap small">
                        <td className="text-black-50">Image</td>
                        <td className="text-black-50">Title</td>
                        <td className="text-black-50">Type</td>
                        <td className="text-black-50">Link</td>
                        <td className="text-black-50 text-end">Created</td>
                        <td className="text-black-50 text-center">...</td>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((ad) => (
                            <AdsRow
                                key={ad.id}
                                ad_id={ad.id}
                                image={ad.image}
                                title={ad.title}
                                type={ad.type}
                                link={ad.link}
                                created_date={ad.created_date}
                                sendRefreshToParent={setRefresh}
                                refresh={refresh}
                                handleEditAd={handleEditAd}
                                sendHiddenToParent={setHidden}
                                hidden={hidden}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdsFiltered;