import StripeKeysSettingsForm from "./StripeKeysSettingsForm";
import stripeIcon from "../../../assets/images/settings/stripe_logo.png";
import {BiEdit} from "react-icons/bi";
import {useEffect, useState} from "react";
import {adminSettingService} from "../../../_services";

const StripeKeysSettings = () => {
    const [stripeKeysSettingsData, setStripeKeysSettingsData] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [hidden, setHidden] = useState(true);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await adminSettingService.getStripeKeysSettings();
                setStripeKeysSettingsData(data);
            } catch (error) {
                console.error("Error fetching stripe keys data:", error);
            }
        };

        fetchData();
    }, [refresh]);
    
    const setFormHidden = () => {
        setHidden(!hidden);
    }
    
    if (stripeKeysSettingsData) {
        return (
            <>
                <div className="fw-bold-600 small mb-2">Stripe Keys Settings</div>
                <div className={hidden ? "d-none" : "d-block"}>
                    <StripeKeysSettingsForm
                        settings_id={stripeKeysSettingsData.id}
                        promise_key={stripeKeysSettingsData.promise_key}
                        secret_key={stripeKeysSettingsData.secret_key}
                        sendRefreshToParent={setRefresh}
                        refresh={refresh}
                        sendHiddenToParent={setHidden}
                        hidden={hidden}
                    />
                </div>
                <div className={`row m-0 justify-content-center ${hidden ? "d-block" : "d-none"}`}>
                    <div className="light-shadow bg-white p-2 p-lg-3 p-xl-4 rounded-2 d-flex flex-column flex-lg-row align-items-center justify-content-between small">
                        <div className="icon-holder text-center mb-2 mb-lg-0">
                            <img src={stripeIcon}
                                 width="64"
                                 height="auto"

                                 alt="stripe payment"/>
                        </div>
                        <div className="d-flex flex-column flex-lg-row flex-grow-1 justify-content-evenly">
                            <div className="setting-holder text-center mb-2 mb-lg-0">
                                <div
                                    className="text-black-50">Promise Key
                                </div>
                                <div className="setting-value">
                                    {`${stripeKeysSettingsData.promise_key.substring(0, 10)}...`}
                                </div>
                            </div>
                            <div className="setting-holder text-center mb-2 mb-lg-0">
                                <div
                                    className="text-black-50">Secret Key
                                </div>
                                <div className="setting-value">
                                    {`${stripeKeysSettingsData.secret_key.substring(0, 10)}...`}
                                </div>
                            </div>
                        </div>
                        <div onClick={setFormHidden}
                             className="edit-holder c-pointer d-flex align-items-center align-self-end mt-2 mt-lg-0 me-2 edit-icon">
                            <div className="icon-holder me-2"><BiEdit className="icon" size="25"/></div>
                            <div className="edit-text">Edit</div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <></>
        )
    }
}

export default StripeKeysSettings;