import {useState} from "react";
import {Header, QuizQuestionsFiltered} from "./Private";
import {useParams} from "react-router-dom";

const QuizQuestions = () => {
    let {qid} = useParams();
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <Header title="Quiz Questions"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 px-lg-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="bg-white mb-3 p-2 p-lg-3 rounded-3 row m-0">
                    <div className="col-6 col-xl-4 col-xxl-3">
                        <div className="form-group">
                            <label className="mb-1 text-real-small fw-bold-500" htmlFor="search">
                                Search
                            </label>
                            <input
                                type="text"
                                className="form-control form-select-sm text-real-small"
                                value={searchQuery}
                                id="search"
                                onChange={handleSearchInputChange}
                                placeholder="questions, answers..."
                            />
                        </div>
                    </div>
                </div>
                <QuizQuestionsFiltered
                    quiz={qid}
                    searchQuery={searchQuery}
                />
                {/*End Page Content*/}
            </div>
        </>
    );
}

export default QuizQuestions;